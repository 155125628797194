import { z } from "zod";

import type { EventIds } from "../app-events/app-events.types";
import {
  APIEventSessions,
  type ResolvedSessions,
} from "../sessions/session.types";

export type ResolvedInteraction<T> = T &
  EventIds & {
    resolved: ResolvedSessions;
  };

// Discriminated events definition

export const ResourceRatedLiteral = z.literal("com.withpillar.resource-rated");
export type ResourceRatedLiteral = "com.withpillar.resource-rated";
export const APIResourceRated = z.object({
  type: ResourceRatedLiteral,
  rating: z.number(),
  sessions: APIEventSessions,
});
export type APIResourceRated = z.infer<typeof APIResourceRated>;
export type ResourceRated = ResolvedInteraction<
  APIResourceRated & {
    feedbackId: string;
  }
>;

// #region print
export const ResourcePrintedEventKey = "com.withpillar.resource-printed";
export const ResourcePrintedLiteral = z.literal(ResourcePrintedEventKey);
export type ResourcePrintedLiteral = typeof ResourcePrintedEventKey;

export const APIResourcePrinted = z.object({
  type: ResourcePrintedLiteral,
  printed: z.boolean(),
  sessions: APIEventSessions,
});
export type APIResourcePrinted = z.infer<typeof APIResourcePrinted>;
export type ResourcePrinted = ResolvedInteraction<APIResourcePrinted>;

// #endregion

// #region comments
export const ResourceCommentedLiteral = z.literal(
  "com.withpillar.resource-commented"
);
export type ResourceCommentedLiteral = "com.withpillar.resource-commented";
export const APIResourceCommented = z.object({
  type: ResourceCommentedLiteral,
  comment: z.string(),
  sessions: APIEventSessions,
});

export type APIResourceCommented = z.infer<typeof APIResourceCommented>;
export type ResourceCommented = ResolvedInteraction<
  APIResourceCommented & {
    feedbackId: string;
  }
>;

// #endregion
export type FormSubmitted = ResolvedInteraction<{
  type: "com.withpillar.form-submitted";
  formId: string;
  formSubmissionInstanceId: string;
  sessions: APIEventSessions;
}>;

export const APIDocumentExternalVisited = z.object({
  type: z.literal("com.withpillar.resource_visited"),
  sessions: APIEventSessions,
});

export type APIDocumentExternalVisited = z.infer<
  typeof APIDocumentExternalVisited
>;

export type DocumentExternalVisited = ResolvedInteraction<{
  type: "com.withpillar.resource_visited";
  sessions: APIEventSessions;
}>;

// view time
export const APIViewTimeCheckpoint = z.object({
  type: z.literal("com.withpillar.view-time-checkpoint"),
  duration: z.number(),
  sessions: APIEventSessions,
});

export type APIViewTimeCheckpoint = z.infer<typeof APIViewTimeCheckpoint>;
export type ViewTimeCheckpoint = ResolvedInteraction<APIViewTimeCheckpoint>;

// explorer search
export const APIExplorerSearch = z.object({
  type: z.literal("com.withpillar.explorer-filtered-by-query"),
  sessions: APIEventSessions,
  query: z.string(),
});
export type APIExplorerSearch = z.infer<typeof APIExplorerSearch>;
export type ExplorerSearch = ResolvedInteraction<APIExplorerSearch>;

// explorer filters
export const APIExplorerFilters = z.object({
  type: z.literal("com.withpillar.explorer-filtered-by-tags"),
  sessions: APIEventSessions,
  filters: z.array(z.string()),
});

export type APIExplorerFilters = z.infer<typeof APIExplorerFilters>;
export type ExplorerFilters = ResolvedInteraction<APIExplorerFilters>;

export const APIInteractions = z.union([
  APIViewTimeCheckpoint,
  APIResourceRated,
  APIResourceCommented,
  APIDocumentExternalVisited,
  APIResourcePrinted,
]);

export type APIInteractions = z.infer<typeof APIInteractions>;

export type Interactions =
  | ExplorerSearch
  | ExplorerFilters
  | ResourcePrinted
  | ResourceRated
  | ResourceCommented
  | FormSubmitted
  | ViewTimeCheckpoint
  | DocumentExternalVisited;
// | Legacy_ViewTimeCheckpoint;

export const interactionType = <T extends Interactions["type"]>(type: T) =>
  type;
