import { proxy } from "valtio";
import { derive } from "valtio/utils";
import {
  DEFAULT_THEME,
  MantineColorsTuple,
  createTheme,
  mergeMantineTheme,
} from "@mantine/core";

import { makeAsync } from "@pillar/client/async";
import { useStore } from "@pillar/client/store";
import { hubApi } from "@pillar/hub/api/client";
import { CurrentLinkStore } from "@pillar/links/hub/client/InferResourceLinkFromEnv";
import { renderTemplate, validateTemplate } from "@pillar/std/strings";
import {
  applyThemeTokens,
  defaultObsidianVars,
} from "@pillar/ui/obsidian/skins";
import { remToMantineRadius } from "@pillar/ui/obsidian/radius";
import {
  colorGradientGenerator,
  verifyHslFormat,
} from "@pillar/ui/obsidian/colors";
import { HubConfig } from "@pillar/hub/types";

import { HubTranslationKeys } from "../../translations";
import { exponentialBackoffRetry } from "@pillar/v3/client/client-operations.service";
import def from "ajv/dist/vocabularies/discriminator";
import { bannerFixture, titleFixture } from "@pillar/v3/config/config.fixtures";
/**
 * Asynchronous function that retrieves the public configuration for a given organization ID.
 * @param organizationId The ID of the organization to retrieve the public configuration for.
 * @returns A Promise that resolves with the public configuration for the given organization ID.
 */
export const getHubConfigByPid = makeAsync((pid: string) => {
  return exponentialBackoffRetry({
    fn: () =>
      hubApi.v1.config.getPublicConfigForPid.query({
        pid,
        type: "unknown",
      }),
  });
});

export const ops = {
  getHubConfigByPid: getHubConfigByPid,
};

export const state = proxy({
  ready: false,
  mantineTheme: DEFAULT_THEME,
  error: false,
});

export const derived = derive({
  config: (get) => get(getHubConfigByPid.derived).dataOrNull!,
  banner: (get) => {
    const config = get(getHubConfigByPid.derived)?.dataOrNull;

    return Object.fromEntries(
      Object.entries(config?.banner ?? bannerFixture).map(([key, value]) => {
        const validatedTemplate =
          value !== undefined && value !== null ? validateTemplate(value) : "";
        return [
          key,
          validatedTemplate
            ? renderTemplate(validatedTemplate, {
                hub: { ...config },
              })
            : "",
        ];
      }),
    );
  },
});

export const actions = {
  setMantineTheme(config: HubConfig) {
    const defaultPrimaryColor =
      config.theme["--primary"] ?? defaultObsidianVars["--primary"];
    const { baseColorIndex, colors } = colorGradientGenerator(
      verifyHslFormat(defaultPrimaryColor),
    );

    const defaultRadius = remToMantineRadius(
      config.theme["--radius"] ?? defaultObsidianVars["--radius"]
    );

    const customTheme = createTheme({
      other: {
        defaultRadius: defaultRadius,
        logoWidth:
          config.theme["--logo-size-width"] ??
          defaultObsidianVars["--logo-size-width"],
      },
      components: {
        Button: {
          defaultProps: {
            radius: defaultRadius,
          },
        },
        Modal: {
          defaultProps: {
            radius: defaultRadius,
          },
        },
        Input: {
          defaultProps: {
            radius: defaultRadius,
          },
        },
        Select: {
          defaultProps: {
            radius: defaultRadius,
          },
        },
      },
      colors: {
        primary: colors,
      },
      headings: {
        sizes: {
          h2: { fontWeight: "600" },
          h3: { fontWeight: "600" },
          h4: { fontWeight: "500" },
        },
      },
      primaryColor: "primary",
      primaryShade: baseColorIndex,
      autoContrast: true,
    });

    console.log(customTheme);
    state.mantineTheme = mergeMantineTheme(DEFAULT_THEME, customTheme);
  },
};

export const sagas = {
  loadConfig: async (organizationId: string) => {
    if (ops.getHubConfigByPid.derived.dataOrNull) {
      state.ready = true;
      return;
    }
    try {
      state.error = false;
      const config = await ops.getHubConfigByPid.run(organizationId);
      actions.setMantineTheme(config);
      applyThemeTokens(config.theme);
      document.title = renderTemplate(config.title, { hub: { ...config } });

      if (!document.querySelector("#shortcut")) {
        document.head.insertAdjacentHTML(
          "beforeend",
          `<link id="shortcut" rel="shortcut icon" type="image/x-icon" href="${config.favicon}" />`,
        );
      }
      state.ready = true;
    } catch (e) {
      state.error = true;
    }
  },

  t(key: HubTranslationKeys) {
    const config = ops.getHubConfigByPid.derived.dataOrNull;
    if (!config) return "";

    // Get the resource type from the parsed link

    // FIXME: @unify-auth
    const parsedLinkType = CurrentLinkStore.state.rootResourceLink?.type;
    const resourceType = parsedLinkType
      ? parsedLinkType === "asset"
        ? "resource"
        : parsedLinkType === "pathway"
        ? "pathway"
        : parsedLinkType === "service"
        ? "service"
        : parsedLinkType === "explorer"
        ? "repository"
        : "resource"
      : "resource";

    const template = config.localization.en.components[key];
    const validatedTemplate =
      template !== undefined ? validateTemplate(template) : "";
    return renderTemplate(validatedTemplate, {
      hub: { ...config, resource: { type: resourceType } },
    });
  },
};

export const HubConfigStore = {
  ops,
  state,
  derived,
  sagas,
};

export const useHubConfigStore = () => useStore(HubConfigStore);
