import type { FC, PropsWithChildren, ReactNode } from "react";

import type { StaticResourceAggregate } from "@pillar/assets/hub/types";
import { ResourceTypeTag } from "@pillar/resources/types.legacy";
import { PublicResourceLayout } from "@pillar/resources/hub/client/PublicResourceLayout";
import { ServiceRendererOrNull } from "@pillar/services/hub/client/PreviewServiceRenderer";
import { AgnosticFileRenderer } from "@pillar/singleResource/hub/client/FileRenderer";

import { useHubURLParamsStore } from "@pillar/v3/client/urls/params.hub.store";
import { PublicResourceEmbedLayout } from "@pillar/v3/embed/hub/Layout";

export const ShareableAnyResourceRendererWithPageLayout: FC<{
  resource: StaticResourceAggregate;
  actions: ReactNode;
  goBack?: () => void;
}> = (p) => {
  const {
    derived: { isEmbed },
  } = useHubURLParamsStore();
  const Layout: FC<PropsWithChildren> = ({ children }) => {
    return isEmbed && !p.goBack ? (
      <PublicResourceEmbedLayout
        withBackground={!p.resource.types.includes(ResourceTypeTag.service)}
        goBack={p.goBack}
        actions={p.actions}
      >
        {children}
      </PublicResourceEmbedLayout>
    ) : (
      <PublicResourceLayout
        resource={p.resource}
        goBack={p.goBack}
        // actions={p.actions}
      >
        {children}
      </PublicResourceLayout>
    );
  };

  const props = {
    resource: p.resource,
    actions: p.actions,
  };

  return (
    <Layout>
      <AgnosticFileRenderer {...props} />
      <ServiceRendererOrNull {...props} />
    </Layout>
  );
};
