import { useEffect } from "react";
import { $LinkType } from "../../invite-links.types";
import { useCurrentRootPid } from "./useCurrentLink";
import { useNavigate, useSearchParams } from "react-router-dom";

export const useShouldRedirectTo = () => {
  const pid = useCurrentRootPid();

  const searchParams = `${window.location.search}`;

  if (pid?.startsWith($LinkType.form)) {
    console.info("Redirecting to form", pid);
    return `/form/${pid}${searchParams}`;
  }
  if (pid?.startsWith($LinkType.repo)) {
    return `/explorer/${pid}${searchParams}`;
  }

  if (pid?.startsWith($LinkType.pathway)) {
    return `/pathway/${pid}${searchParams}`;
  }

  if (
    pid?.startsWith($LinkType.file) ||
    pid?.startsWith(`${$LinkType.deprecated_file}-`)
  ) {
    return `/asset/${pid}${searchParams}`;
  }

  if (pid?.startsWith($LinkType.file)) {
    // TODO: replace with /file
    return `/file/${pid}${searchParams}`;
  }

  // deprecated links
  if (pid?.startsWith("psvc")) {
    // TODO: replace with /doc
    return `/doc/${pid}${searchParams}`;
  }
};

export const RedirectToRenderer = () => {
  const redirect = useNavigate();
  const redirectTo = useShouldRedirectTo();

  useEffect(() => {
    if (redirectTo) {
      redirect(redirectTo);
    }
  }, [redirectTo, redirect]);

  return null;
};
