import { ResourceTypeTag } from "@pillar/resources/types.legacy";
import { HubRoutesV2 } from "@pillar/hub/navigation";
import {
  MobileResourceFeedbackBar,
  ResourceFeedbackBar,
} from "@pillar/v3/feedbacks/hub/client/MantineResourceFeedback";
import { MobilePublicResourceRenderer } from "@pillar/resources/hub/client/MobilePublicResourceRenderer";
import { ResourceAggregateWithInteractions } from "@pillar/resources/types";
import { ShareableAnyResourceRendererWithPageLayout } from "@pillar/singleResource/hub/client/ShareableResourceRenderer";
import { MobileHubResourceTranslationsDropdown } from "@pillar/v3/accessibility/translation/hub/client/ResourceTranslations";
import { HubResourceTranslationsProvider } from "@pillar/v3/accessibility/translation/hub/client/hooks";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Group } from "@mantine/core";
import { useHubURLParamsStore } from "@pillar/v3/client/urls/params.hub.store";

export const PublicResourceRendererMaster: FC<{
  isMobile: boolean;
  explorerPid?: string;
  data: NonNullable<ResourceAggregateWithInteractions>;
}> = ({ isMobile, explorerPid, data }) => {
  const router = useNavigate();

  const {
    derived: { allParamsToURLString },
  } = useHubURLParamsStore();

  const label = `Rate ${
    data.types.includes(ResourceTypeTag.service)
      ? "Service"
      : data.types.includes(ResourceTypeTag.video)
        ? "Video"
        : data.types.includes(ResourceTypeTag.document)
          ? "Document"
          : "Content"
  }`;

  return (
    <HubResourceTranslationsProvider
      initialState={{
        vernacular: {
          id: data.id,
          uri: data.url,
        },
      }}
    >
      {isMobile ? (
        <MobilePublicResourceRenderer
          resource={data}
          actions={
            <>
              <MobileHubResourceTranslationsDropdown floatingPosition="top-start" />
              <MobileResourceFeedbackBar
                className="only:mx-auto"
                buttonText={label}
                buttonVariant={
                  data.types.includes(ResourceTypeTag.service)
                    ? "outline"
                    : "filled"
                }
              />
            </>
          }
          goBack={
            explorerPid
              ? () => router(HubRoutesV2.explorer(explorerPid))
              : undefined
          }
        />
      ) : (
        <ShareableAnyResourceRendererWithPageLayout
          resource={data}
          actions={
            <Group>
              <ResourceFeedbackBar
                buttonText={label}
                compact={!data.types.includes(ResourceTypeTag.service)}
                buttonVariant={
                  data.types.includes(ResourceTypeTag.service)
                    ? "outline"
                    : "filled"
                }
              />
            </Group>
          }
          goBack={
            explorerPid
              ? () =>
                  router({
                    pathname: HubRoutesV2.explorer(explorerPid),
                    search: allParamsToURLString,
                  })
              : undefined
          }
        />
      )}
    </HubResourceTranslationsProvider>
  );
};
