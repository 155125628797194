import { MantineRadius } from "@mantine/core";

/**
 * Transforms 'x.xrem' radius to a Mantine Radius (eg xs, sm...)
 * @param remRadius A radius in format 'xx.xxrem'.
 * @returns A mantine radius: xs, sm, md, lg, or xl. Defaults to md.
 */
export function remToMantineRadius(remRadius: string): MantineRadius {
  const rem = parseFloat(remRadius.split("rem")?.[0]);
  if (rem <= 0.25) return "xs";
  if (rem > 0.25 && rem <= 0.375) return "sm";
  if (rem > 0.375 && rem <= 0.5) return "md";
  if (rem > 0.5 && rem <= 1) return "lg";
  if (rem > 1) return "xl";
  return "sm";
}
