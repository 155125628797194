import { HubConfig } from "@pillar/hub/types";
import { Duration, differenceInSeconds } from "date-fns";
import {
  MiniSession,
  getMiniSession,
  sessionConfigStore,
  setMiniSession,
} from "./autologin.store";
import { useSnapshot } from "valtio";

const durationToSeconds = (
  duration: Pick<Duration, "hours" | "minutes" | "seconds">
) =>
  (duration.seconds ?? 0) +
  (duration.minutes ?? 0) * 60 +
  (duration.hours ?? 0) * 3600;

export const useHubMiniSession = (p: HubConfig["session"]) => {
  const previousSession: MiniSession = getMiniSession();
  const config = useSnapshot(sessionConfigStore);

  const isValid = () => {
    return (
      previousSession &&
      differenceInSeconds(new Date(), previousSession.at) <=
        durationToSeconds(p.duration)
    );
  };

  const setAutoLogin = (shouldAutologin: boolean) => {
    sessionConfigStore.shouldAutologing = shouldAutologin;
  };

  return {
    valid: isValid(),
    session: previousSession,
    config,
    setMiniSession,
    setAutoLogin,
  };
};
