import { Alert, Center, Stack } from "@mantine/core";
import { Info } from "lucide-react";
import { FC } from "react";

export const FormErrorCard: FC = () => {
  return (
    <Center w="100%">
      <Alert variant="light" color="orange" title="Alert title" icon={<Info />}>
        <Stack>
          There was an error sending your submission. Please contact your
          provider.
        </Stack>
      </Alert>
    </Center>
  );
};
