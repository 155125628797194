import { FC, useEffect } from "react";
import { ActionIcon, Center, Group, Loader } from "@mantine/core";
import { ChevronLeft, ChevronRight } from "lucide-react";

import { HubConfig } from "@pillar/config/hub/client/HubConfig";
import { HubAuth } from "@pillar/auth/hub/client/HubAuth";
import { NotFound } from "@pillar/hub/client/NotFound";
import { useHubParams } from "@pillar/hub/navigation";
import { InferCurrentLinkFromEnv } from "@pillar/links/hub/client/InferResourceLinkFromEnv";

import { MobileAppPage } from "@pillar/ui/obsidian/layout";
import { useIsMobile } from "@pillar/ui/obsidian/layout/hooks";

import { usePathwayStore } from "./PathwayStore";
import { MobilePathwayHomeScreen } from "./MobilePathwayHomeScreen";
import { MobilePathwayResourceScreen } from "./MobilePathwayResourceScreen";

import { PathwayHomeScreen } from "./PathwayHomeScreen";
import { PathwayResourceScreen } from "./PathwayResourceScreen";
import { InteractionRecorder } from "@pillar/v3/interactions/hub/client/InteractionsRecorder";
import { HubURLParams } from "@pillar/v3/client/urls/HubURLParams";

export const DesktopPublicPathwayFlow: FC<{
  goBack?: () => void;
}> = (props: { goBack?: () => void }) => {
  const store = usePathwayStore();

  return (
    <>
      {store.derived.screen === "home" && (
        <PathwayHomeScreen goBack={props.goBack} />
      )}
      {store.derived.screen === "resource" &&
        store.state.currentResource !== null && (
          <PathwayResourceScreen resourceId={store.state.currentResource.id} />
        )}
    </>
  );
};

export const MobilePathwayNavigation: FC<{
  onNext: () => Promise<void>;
  onPrevious: () => Promise<void>;
}> = (p) => {
  return (
    <Group gap={"0.25rem"} wrap="nowrap">
      <ActionIcon
        variant="outline"
        onClick={p.onPrevious}
        w={"4rem"}
        aria-label="Previous resource"
      >
        <ChevronLeft />
      </ActionIcon>

      <ActionIcon
        variant="outline"
        onClick={p.onNext}
        w={"4rem"}
        aria-label="Next resource"
      >
        <ChevronRight />
      </ActionIcon>
    </Group>
  );
};
export const MobilePublicPathwayFlow: FC<{
  goBack?: () => void;
}> = (props) => {
  const store = usePathwayStore();

  if (!store.derived.pathway)
    return (
      <Center w="100%" h="100%">
        <Loader size="xl" />
      </Center>
    );

  return (
    <MobileAppPage>
      {store.derived.screen === "home" && (
        <MobilePathwayHomeScreen goBack={props.goBack} />
      )}
      {store.derived.screen === "resource" &&
        store.state.currentResource !== null && (
          <MobilePathwayResourceScreen
            resourceId={store.state.currentResource.id}
          />
        )}
    </MobileAppPage>
  );
};

export const PathwayRendererFlow: FC<{
  pid: string;
  repositoryId?: string;
  goBack?: () => void;
}> = (p: { pid: string; repositoryId?: string; goBack?: () => void }) => {
  const { loading, isMobile } = useIsMobile();
  const store = usePathwayStore();

  useEffect(() => {
    store.sagas.init(p.pid);
    return () => {
      store.sagas.dispose();
    };
  }, []);

  if (loading) {
    return (
      <Center w="100%" h="100%">
        <Loader size="xl" />
      </Center>
    );
  }

  return isMobile ? (
    <MobilePublicPathwayFlow goBack={p.goBack} />
  ) : (
    <DesktopPublicPathwayFlow goBack={p.goBack} />
  );
};

export const PathwayRendererPage: FC = () => {
  const params = useHubParams();
  const pid = params.pid;

  if (!pid) {
    return <NotFound />;
  }

  return (
    <InferCurrentLinkFromEnv>
      <HubConfig pid={pid}>
        <HubURLParams>
          <HubAuth pid={pid}>
            <InteractionRecorder
              command={{
                type: "load-link",
                session: {
                  sessionType: "pathway",
                  pathwayPid: pid,
                  sessionId: "",
                },
              }}
            >
              <PathwayRendererFlow pid={pid} />
            </InteractionRecorder>
          </HubAuth>
        </HubURLParams>
      </HubConfig>
    </InferCurrentLinkFromEnv>
  );
};
