export const defaultObsidianVars = {
  "--logo-size-width": "175px",
  "--mobile-logo-size-width": "100px",

  "--logo-size-scale": "1",
  "--mobile-logo-size-scale": "1",

  "--explorer-top-bar-padding": ".5em",
  "--mobile-explorer-top-bar-padding": ".1em",

  "--background": "0 0% 100%",
  "--foreground": "222.2 47.4% 11.2%",

  "--muted": "210 40% 96.1%",
  "--muted-foreground": "215.4 16.3% 46.9%",

  "--card": "0 0% 100%",
  "--card-foreground": "222.2 47.4% 11.2%",

  "--popover": "0 0% 100%",
  "--popover-foreground": "222.2 47.4% 11.2%",

  "--border": "214.3 31.8% 91.4%",

  "--input": "214.3 31.8% 91.4%",

  /* warning - success */
  "--warning": "30 100% 50%",
  "--warning-foreground": "210 40% 98%",

  "--success": "153 97% 30%",
  "--success-foreground": "210 40% 98%",

  /* button */
  "--primary": "218, 28%, 17%",
  "--primary-foreground": "210 40% 98%",

  "--secondary": "210 40% 96.1%",
  "--secondary-foreground": "222.2 47.4% 11.2%",

  "--accent": "210 40% 96.1%",
  "--accent-foreground": "222.2 47.4% 11.2%",

  "--ring": "215 20.2% 65.1%",

  "--radius": "0.5rem",
  "--radius-inputs": "0.5rem",
  "--radius-buttons": "0.5rem",
};

export type ObsidianTheme = Partial<typeof defaultObsidianVars>;

export const overrideDefaultTheme = (vars: Partial<ObsidianTheme>) => {
  const newVars = { ...defaultObsidianVars, ...vars };
  return newVars;
};

export function applyThemeTokens(
  vars: Partial<ObsidianTheme>,
  element = document.querySelector<HTMLElement>(":root")
) {
  const withOverride = overrideDefaultTheme(vars);
  if (!element) return;
  for (const [key, value] of Object.entries(withOverride)) {
    element.style.setProperty(key, value);
  }
}

export const uninitializedClassName = "uninitialized";
export const initializedClassName = "initialized";

export const applyThemeThenShowTheApp = (
  vars: Record<string, string> = defaultObsidianVars
) => {
  applyThemeTokens(vars);
  document.body.classList.add(initializedClassName);
  document.body.classList.remove(uninitializedClassName);
};
