import { Box, Overlay, Transition } from "@mantine/core";
import {
  CSSProperties,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";

export const FadeInContent = (
  p: PropsWithChildren<{
    isLoading: boolean;
    style?: CSSProperties;
  }>
) => {
  return (
    <Transition
      mounted={!p.isLoading}
      transition="fade"
      exitDuration={500}
      duration={700}
      timingFunction="ease-out"
    >
      {(styles) => (
        <div
          className="fade-in-content"
          style={{ ...styles, ...(p.style ?? {}) }}
        >
          {p.children}
        </div>
      )}
    </Transition>
  );
};

export const DeferLoadingState = (
  p: PropsWithChildren<{
    isLoading: boolean;
    waitFor: number;
    fallback: ReactNode;
  }>
) => {
  const [shouldDisplayLoad, setShouldDisplayLoad] = useState(false);
  const timer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!p.isLoading) {
      if (timer.current) clearTimeout(timer.current);
      setShouldDisplayLoad(false);
      return () => {};
    }

    timer.current = setTimeout(() => {
      console.info("should show spinner now!");
      setShouldDisplayLoad(true);
    }, p.waitFor);
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [p.isLoading, p.waitFor]);

  if (shouldDisplayLoad) return p.fallback;
  if (p.isLoading) {
    return null;
  }

  return p.children;
};
