import { FC, useState } from "react";
import { Button, Center, Group, Loader, Text } from "@mantine/core";

import { MobileNavigation } from "@pillar/ui/obsidian/navigation";
import {
  FOOTER_HEIGHT,
  HEADER_HEIGHT,
  MobileAppPage,
} from "@pillar/ui/obsidian/layout";

import { usePathwayStore } from "./PathwayStore";
import { MobileResourceMetadataOrActualContentRenderer } from "../../../singleResource/hub/client/MobileResourceRenderer";
import { MobilePathwayResourceFeedback } from "./PathwayResourceFeedback";
import { MobilePathwayCompletedModal } from "./MantinePathwayCompletedScreen";
import { ResourceTypeTag } from "@pillar/resources/types.legacy";
import { MobileServiceInPathwayOrNull } from "./ServiceInPathwayRenderer";
import { HubResourceTranslationsProvider } from "@pillar/v3/accessibility/translation/hub/client/hooks";
import { MobileHubResourceTranslationsDropdown } from "@pillar/v3/accessibility/translation/hub/client/ResourceTranslations";
import { FadeInContent } from "@pillar/v3/async/client/suspense.shim";
import { InteractionRecorder } from "@pillar/v3/interactions/hub/client/InteractionsRecorder";

export const MobilePathwayResourceScreen: FC<{ resourceId: string }> = (p) => {
  const store = usePathwayStore();

  store.sagas.useOnResourceChange(p.resourceId);
  const fullResource =
    store.ops.fetchAssetInPathway.derived.dataOrNull ??
    store.ops.fetchAssetInPathwayInExplorer.derived.dataOrNull;
  const loading =
    store.ops.fetchAssetInPathway.derived.isLoading ||
    store.ops.fetchAssetInPathwayInExplorer.derived.isLoading;

  // TODO: refactor and move down to the PDF component itself.
  const [open, setOpen] = useState(false);

  if (!fullResource)
    return (
      <Center w="100%" h="100%">
        <Loader size="lg" />
      </Center>
    );

  return (
    <HubResourceTranslationsProvider
      initialState={{
        vernacular: {
          id: fullResource.id,
          uri: fullResource.url,
        },
      }}
    >
      <MobileAppPage.Header
        goBack={
          store.state.isResourceOpened
            ? store.actions.closeResource
            : store.actions.goToHome
        }
        actions={
          <MobileHubResourceTranslationsDropdown floatingPosition="bottom-end" />
        }
      >
        {store.state.currentResource?.name}
      </MobileAppPage.Header>

      <MobileAppPage.Main
        px={0}
        pt={HEADER_HEIGHT}
        pb={FOOTER_HEIGHT}
        styles={{
          main: {
            flex: 1,
          },
        }}
      >
        <FadeInContent
          isLoading={loading}
          style={{
            display: "flex",
            flex: 1,
          }}
        >
          <InteractionRecorder
            command={{
              type: "enter-res-in-pathway",
              parent: store.state.pathwayPid ?? "",
              session: {
                sessionType: "resource",
                anyId: fullResource.id,
                sessionId: "",
              },
            }}
            regenOn={fullResource.id}
          >
            <MobileResourceMetadataOrActualContentRenderer
              overrideServiceRenderer={
                <MobileServiceInPathwayOrNull resource={fullResource} />
              }
              onOpen={() => setOpen(!open)}
              opened={open}
              resource={fullResource}
            />
          </InteractionRecorder>
          <MobilePathwayCompletedModal
            opened={store.state.isSuccessScreenOpen}
            onClose={() => store.actions.closeSuccessModal()}
          />
        </FadeInContent>
      </MobileAppPage.Main>
      <MobileAppPage.Footer>
        <Group
          px={"1em"}
          h="100%"
          w="100%"
          justify={"space-between"}
          align={"center"}
          wrap="nowrap"
        >
          {store.state.currentResource !== null && (
            <MobilePathwayResourceActions
              onNext={store.actions.transitionToNextResource}
              onPrevious={store.actions.transitionToPreviousResource}
              resourceId={store.state.currentResource.id}
              currentResourceIndexInPathway={
                store.derived.currentResourceIndexInPathway
              }
              totalResources={store.derived.totalResources}
              resourceType={fullResource.types?.[0]}
            />
          )}
        </Group>
      </MobileAppPage.Footer>
    </HubResourceTranslationsProvider>
  );
};

export const MobilePathwayResourceActions: FC<{
  onNext: () => Promise<void>;
  onPrevious: () => Promise<void>;
  resourceId: string;
  currentResourceIndexInPathway?: number;
  totalResources?: number;
  resourceType?: ResourceTypeTag;
}> = (p) => {
  return (
    <>
      {p.resourceType !== ResourceTypeTag.form && (
        <MobilePathwayResourceFeedback
          resourceId={p.resourceId}
          resourceType={p.resourceType}
        />
      )}

      <MobileNavigation onNext={p.onNext} onPrevious={p.onPrevious}>
        {p.currentResourceIndexInPathway && p.totalResources && (
          <Text fw="bold" px={4}>
            {p.currentResourceIndexInPathway}/{p.totalResources}
          </Text>
        )}
      </MobileNavigation>
    </>
  );
};
