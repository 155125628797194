import { platformApi } from "@pillar/v3/rpc/client.proxy";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import type { TranslationFlow, TranslationParameters } from "./types";
import { useEffect, useState } from "react";
import {
  $SupportedInputLangs,
  $SupportedOutputLangs,
  type TranslationAggregate,
  type TranslationCompleted,
} from "../translation.types";
import type {
  ASingleResourceProps,
  ClientCompactResource,
} from "@pillar/v3/resources/resources.types";
import { resourceAsTranslation } from "./utils";
import { createContainer } from "@pillar/v3/client/client.container";
import { wait } from "@pillar/v3/stdlib/time";

const translationsPath = "resource/current/translations";

export const useManageTranslations = () => {
  const ctx = useQueryClient();
  const approveOperation = useMutation(
    ["translation/approve"],
    async (opts: { translationId: string; cb?: () => void }) => {
      await platformApi.v3.accessibility.translation.approve.mutate({
        resourceInstanceId: opts.translationId,
        approve: true,
      });
      ctx.invalidateQueries([translationsPath]);

      opts.cb?.();
    }
  );

  const discardOperation = useMutation(
    ["translation/discard"],
    async (opts: { translationId: string; cb?: () => void }) => {
      await platformApi.v3.accessibility.translation.approve.mutate({
        resourceInstanceId: opts.translationId,
        approve: false,
      });
      ctx.invalidateQueries([translationsPath]);
      opts.cb?.();
    }
  );

  return {
    _: {
      approveOperation,
      discardOperation,
    },

    approve: approveOperation.mutateAsync,
    discard: discardOperation.mutateAsync,
  };
};

export const useTranslationFlow = (p: ASingleResourceProps) => {
  const [screen, setScreen] = useState<TranslationFlow>("setup");

  const transition = (newState: TranslationFlow) => {
    setScreen(newState);
  };

  const [translationParameters, _setTranslationParameters] =
    useState<TranslationParameters>({
      targetLanguage: $SupportedOutputLangs.es,
      inputLanguage: $SupportedInputLangs.en,
    });

  const setTranslationParameters = (
    newParams: Partial<TranslationParameters>
  ) => {
    _setTranslationParameters((prev) => ({
      ...prev,
      ...newParams,
    }));
  };

  const [translation, setTranslation] = useState<TranslationCompleted | null>(
    null
  );

  const waitForTranslation = async (jobId: string) => {
    while (1) {
      await wait(5000);
      const jobState =
        await platformApi.v3.accessibility.translation.poll.mutate({
          jobId,
        });
      if (jobState.status === "SUCCESS") {
        return jobState;
      }
    }
  };

  const operations = {
    translate: async () => {
      transition("translating");
      try {
        const job =
          await platformApi.v3.accessibility.translation.requestAsync.mutate({
            resourceId: p.resource.id,
            targetLanguage: translationParameters.targetLanguage,
          });

        const translationResult = await waitForTranslation(job.jobId);

        if (!translationResult) {
          console.warn(
            "Unexpected error : loop have stopped without returning"
          );
          transition("error");
          return;
        }

        setTranslation(translationResult.translationId);
        transition("review");
      } catch (e) {
        console.error(e);
        transition("error");
      }
    },
    reset: () => {
      setTranslation(null);
      transition("setup");
    },
  };
  return {
    state: {
      screen: {
        current: screen,
        transition,
      },
      translation,
      translationParameters: {
        current: translationParameters,
        set: setTranslationParameters,
      },
    },
    operations,
  };
};

export const useResourceTranslationsHook = (props: ASingleResourceProps) => {
  const existingTranslationsOperation = useQuery(
    [translationsPath],
    () =>
      platformApi.v3.accessibility.translation.getAll.query({
        id: props.resource.id,
      }),
    { refetchOnWindowFocus: false } // this prevents the drawer to be closed on focus when a user could be in the middle of the flow
  );

  const translations = existingTranslationsOperation.data ?? [];

  const { vernacularAndTranslations, onSelectTranslation, currentTranslation } =
    useTranslationSelector({
      resource: props.resource,
      translations: translations,
    });

  const noTranslations =
    existingTranslationsOperation.isFetched &&
    (existingTranslationsOperation.data ?? []).length === 0;

  return {
    versions: vernacularAndTranslations,
    isLoading: existingTranslationsOperation.isLoading,
    existingTranslations: translations,
    noTranslations,
    selectedTranslation: {
      current: currentTranslation,
      select: onSelectTranslation,
    },
    _: {
      operation: existingTranslationsOperation,
    },
  };
};

/** This is used both in the HUB and SAAS */
export const useTranslationSelector = (props: {
  resource: ClientCompactResource;
  translations: TranslationAggregate[];
}): {
  vernacularAndTranslations: TranslationAggregate[];
  vernacular: TranslationAggregate;
  currentTranslation: TranslationAggregate;
  onSetDefaultTranslation: (defaultLang: $SupportedOutputLangs) => void;
  onSelectTranslation: (id: string) => void;
} => {
  const vernacular = resourceAsTranslation(props.resource);

  const [currentTranslation, setCurrentTranslation] =
    useState<TranslationAggregate>(vernacular);

  // update currentTranslation when the source object changes
  useEffect(() => {
    if (props.resource.id !== currentTranslation.id)
      setCurrentTranslation(vernacular);
  }, [props.resource.id, props.resource.id]);

  const vernacularAndTranslations = [vernacular, ...props.translations];

  const selectTranslation = (id: string) => {
    setCurrentTranslation(
      vernacularAndTranslations.find((t) => t.id === id) ?? vernacular
    );
  };

  const setDefaultTranslation = (lang: $SupportedOutputLangs) => {
    setCurrentTranslation(
      vernacularAndTranslations.find((t) => t.lang === lang) ?? vernacular
    );
  };

  return {
    vernacular,
    vernacularAndTranslations,
    currentTranslation,
    onSelectTranslation: selectTranslation,
    onSetDefaultTranslation: setDefaultTranslation,
  };
};

export const ResourceTranslationsContainer = createContainer(
  useResourceTranslationsHook
);

export const ResourceTranslationsProvider =
  ResourceTranslationsContainer.Provider;

export const useResourceTranslations =
  ResourceTranslationsContainer.useContainer;

export const useTranslationsQuery = (props: { id: string }) => {
  return {
    query: useQuery([translationsPath], () =>
      platformApi.v3.accessibility.translation.getAll.query({
        id: props.id,
      })
    ),
  };
};
