import { Flex, Group, Image, Stack, Text, Title } from "@mantine/core";
import { useSingleResourceStore } from "@pillar/assets/hub/client/SingleResource.store";
import { ResourceTypeTag } from "@pillar/resources/types.legacy";
import { AppEventDomain, AppEventType } from "@pillar/events/types";
import { Tags } from "@pillar/resources/hub/client/ResourceTags";
import { ResourceMetadata } from "@pillar/resources/types";
import { MobileAnyResourceRendererProps } from "@pillar/singleResource/hub/types";
import { FC, ReactNode } from "react";
import { ServiceCTA } from "./ServiceCTA";
import { validString } from "@pillar/std/strings";
import { serviceStore } from "./Service.store";

export type PreviewServiceRendererType = {
  id: string;
  title: string;
  description: string;
  poster: string;
  logo: string;
  tags: string[];
  onOutboundClick: () => void;
  actions?: ReactNode;
  showLogo?: boolean;
  meta: ResourceMetadata;
};

export const ServiceMetadata: React.FC<
  PreviewServiceRendererType & { compact?: boolean }
> = (p) => {
  return (
    <Stack
      p={p.compact ? undefined : 8}
      gap={p.compact ? "md" : "xl"}
      align="start"
    >
      <Stack gap={"xs"}>
        {p.showLogo && validString(p.logo) && (
          <Image
            src={p.logo}
            alt="Service Logo"
            w="auto"
            mah={54}
            fit="contain"
            style={{
              alignSelf: "start",
            }}
          />
        )}
        <Title order={p.compact ? 2 : 1}>{p.title}</Title>
        {/* {p.compact ? (
          <Spoiler showLabel={"Expand +"} hideLabel="Close -">
            <Text style={{ whiteSpace: "pre-wrap" }}>{p.description}</Text>
          </Spoiler>
        ) : ( */}
        <Text>{p.description}</Text>
        {/* )} */}
      </Stack>
      <Group>
        <ServiceCTA
          compact={p.compact ?? false}
          onClick={p.onOutboundClick}
          serviceMeta={p.meta}
        />
        {p.actions}
      </Group>
      <Tags
        type={ResourceTypeTag.service}
        tags={p.tags}
        wrap="wrap"
        maxTags={100}
      />
    </Stack>
  );
};

export const MobilePreviewServiceRenderer: React.FC<
  PreviewServiceRendererType
> = (p) => {
  const haveImage = validString(p.poster);
  return (
    <Stack h="100%" style={{ overflow: "auto" }}>
      {haveImage ? (
        <Image mih="60vh" src={p.poster} alt="Service image" fit="cover" />
      ) : null}
      <Flex flex={1} px={32} pb={32} pt={!haveImage ? "xl" : undefined}>
        <ServiceMetadata compact {...p} />
      </Flex>
    </Stack>
  );
};

export const MobileServiceRendererOrNull: FC<MobileAnyResourceRendererProps> = (
  p: MobileAnyResourceRendererProps
) => {
  return p.resource.types.includes(ResourceTypeTag.service) ? (
    <ConnectedMobilePreviewServiceRenderer
      id={p.resource.id}
      description={p.resource.description}
      poster={p.resource.thumbnail}
      // thumbnail={p.resource.thumbnail}
      logo={p.resource.logoUrl}
      tags={p.resource.tags}
      title={p.resource.name}
      actions={p.actions}
      meta={p.resource.meta}
    />
  ) : null;
};
export const ConnectedMobilePreviewServiceRenderer: React.FC<
  Omit<PreviewServiceRendererType, "onOutboundClick">
> = (p) => {
  const store = useSingleResourceStore();

  return (
    <MobilePreviewServiceRenderer
      onOutboundClick={() => {
        console.info("outbound click");
        serviceStore.recordClickEvent();
      }}
      {...p}
    />
  );
};
