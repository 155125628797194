import { proxy } from "valtio";
import { z } from "zod";

const LOCAL_STORAGE = "hub-mini-session";

const MiniSession = z
  .object({
    email: z.string(),
    at: z.date(),
  })
  .optional();

export type MiniSession = z.infer<typeof MiniSession>;

export const getMiniSession = () => {
  const from = MiniSession.safeParse(
    JSON.parse(localStorage.getItem(LOCAL_STORAGE) ?? "null", (key, value) => {
      if (key === "at") {
        return new Date(value);
      }
      return value;
    })
  );
  if (from.success) {
    return from.data;
  } else {
    console.log("Unable to read storage", LOCAL_STORAGE, from.error.flatten());
    return undefined;
  }
};

export const setMiniSession = (p: MiniSession) => {
  const to = MiniSession.safeParse(p);

  if (!to.success) {
    console.log("Unable to write storage", LOCAL_STORAGE, to.error.flatten());
    return;
  }

  localStorage.setItem(
    LOCAL_STORAGE,
    JSON.stringify(to.data, (key, value) => {
      if (!key) {
        return { ...value, at: (value as MiniSession)?.at.toISOString() };
      }
      return value;
    })
  );
};

export const sessionConfigStore = proxy({
  shouldAutologing: false,
});
