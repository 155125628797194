import { useEffect } from "react";
import { Grid, Stack } from "@mantine/core";
import { useExplorerSearch } from "./ExplorerSearch.store";
import { ExplorerFiltersSidebar } from "./DesktopExplorerFilters";
import { ExplorerResultSection } from "./DesktopExplorerResultSection";
import { HubExplorerSearchAndLayout } from "./HubExplorerSearchAndLayout";
import { NoResults } from "./NoResults";
import { useNavigate } from "react-router-dom";
import { useHubURLParamsStore } from "@pillar/v3/client/urls/params.hub.store";

export const HubExplorerScreen: React.FC<{
  repositoryPid: string;
}> = (p: { repositoryPid: string }) => {
  const { ops, state, sagas } = useExplorerSearch();
  const router = useNavigate();

  const {
    derived: { allParamsToURLString },
  } = useHubURLParamsStore();

  const onNavigate = (path) =>
    router({
      pathname: path,
      search: allParamsToURLString,
    });

  useEffect(() => {
    sagas.init(p.repositoryPid);
  }, [sagas.init, p.repositoryPid]);

  const store = useExplorerSearch();

  return (
    <Stack gap="sm">
      <HubExplorerSearchAndLayout />
      <Grid>
        <Grid.Col span={3}>
          <ExplorerFiltersSidebar />
        </Grid.Col>
        <Grid.Col span={9}>
          {state.noResults && <NoResults />}
          <Stack gap="xl">
            <ExplorerResultSection
              onSelectResource={store.ops.navigateToService(
                p.repositoryPid,
                onNavigate
              )}
              title="Services"
              getResults={store.selectors.onlyServices}
            />
            <ExplorerResultSection
              title="Content"
              getResults={store.selectors.onlyContent}
              onSelectResource={store.ops.navigateToFile(
                p.repositoryPid,
                onNavigate
              )}
            />
            <ExplorerResultSection
              title="Pathways"
              getResults={store.selectors.onlyPathways}
              onSelectResource={store.ops.navigateToPathway(
                p.repositoryPid,
                onNavigate
              )}
            />
          </Stack>
        </Grid.Col>
      </Grid>
    </Stack>
  );
};
