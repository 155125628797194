import { FC } from "react";

import {
  ResourceFeedbackBar,
  MobileResourceFeedbackBar,
} from "@pillar/v3/feedbacks/hub/client/MantineResourceFeedback";

import { ResourceTypeTag } from "@pillar/resources/types.legacy";

export const PathwayResourceFeedback: FC<{
  resourceId: string;
  resourceType?: ResourceTypeTag;
}> = (p) => {
  return (
    <ResourceFeedbackBar
      buttonText={`Rate ${p.resourceType ?? "Content"}`}
      buttonVariant={
        p.resourceType === ResourceTypeTag.service ? "outline" : "filled"
      }
      compact={p.resourceType !== ResourceTypeTag.service}
    />
  );
};

export const MobilePathwayResourceFeedback: FC<{
  resourceId: string;
  resourceType?: ResourceTypeTag;
}> = (p) => {
  return (
    p.resourceType !== ResourceTypeTag.form && (
      <MobileResourceFeedbackBar
        buttonText={`Rate ${p.resourceType ?? "Content"}`}
        buttonVariant={
          p.resourceType === ResourceTypeTag.service ? "outline" : "filled"
        }
      />
    )
  );
};
