/**
 * we allow partners to override the default theme
 */

import { MantineProvider } from "@mantine/core";
import { PageNotFound } from "@pillar/hub/client/NotFound";
import { LoadingFullPage } from "@pillar/ui/obsidian/loading";
import { FC, PropsWithChildren, useEffect } from "react";
import { useHubConfigStore } from "./index.store";

/** @deprecated */
export const HubConfig: FC<PropsWithChildren<{ pid: string }>> = (props) => {
  const store = useHubConfigStore();
  useEffect(() => {
    store.sagas.loadConfig(props.pid);
  }, []);

  if (store.state.error) return <PageNotFound />;
  if (!store.state.ready)
    return (
      <MantineProvider
        defaultColorScheme={"light"}
        theme={store.state.mantineTheme}
      >
        <LoadingFullPage />
      </MantineProvider>
    );

  if (!store.state.mantineTheme) return null;

  return (
    <MantineProvider
      defaultColorScheme={"light"}
      theme={store.state.mantineTheme}
    >
      {props.children}
    </MantineProvider>
  );
};
