import { TranslationAggregate } from "../../translation.types";

import { ReactNode } from "react";
import { Box, FloatingPosition, Select, SelectProps } from "@mantine/core";

import { Globe } from "lucide-react";
import { useHubResourceTranslations } from "./hooks";

export const HubResourceTranslationsDropdown = (p: SelectProps) => {
  const { state, derived } = useHubResourceTranslations();

  if (!derived.hasTranslations) return null;

  return (
    <Box
      style={{
        width: "8em",
      }}
    >
      <Select
        value={state.currentTranslation.val.id}
        data={derived.translationsAsOptions}
        onChange={(value) => {
          if (value) {
            state.currentTranslation.set(value);
          }
        }}
        leftSection={
          <Globe size="1.2em" className="text-gray-700 hover:text-gray-900" />
        }
        styles={{
          root: {
            position: "relative",
            top: "1px",
          },
          input: {
            fontWeight: "bolder",
          },
        }}
        classNames={{
          input:
            "hover:text-gray-900 text-gray-600 hover:border-gray-900 border-1 border-gray-200",

          dropdown: "w-32",
        }}
      />
    </Box>
  );
};

export const MobileHubResourceTranslationsDropdown = (
  p: SelectProps & {
    floatingPosition?: FloatingPosition;
  }
) => {
  const { state, derived } = useHubResourceTranslations();

  if (!derived.hasTranslations) return null;

  return (
    <Box
      style={{
        width: "5em",
        flex: "none",
      }}
    >
      <Select
        size="md"
        value={state.currentTranslation.val.id}
        data={derived.translationsAsOptionsMobile}
        onChange={(value) => {
          if (value) {
            state.currentTranslation.set(value);
          }
        }}
        comboboxProps={{
          position: p.floatingPosition,
          middlewares: { flip: false, shift: false },
        }}
        styles={{
          input: {
            fontWeight: "bolder",
          },
        }}
        classNames={{
          input:
            "hover:text-gray-900 text-gray-600 hover:border-gray-900 border-1 border-gray-200",

          dropdown: "w-32",
        }}
      />
    </Box>
  );
};

export const CurrentResourceTranslation = (p: {
  children: (currentResource: TranslationAggregate) => ReactNode;
}) => {
  const { state } = useHubResourceTranslations();
  return p.children(state.currentTranslation.val);
};
