import { ClientStore, useStore } from "@pillar/client/store";
import { HubParams, useHubParams } from "@pillar/hub/navigation";
import { APIResolveFromPid, ResourceRendererType } from "@pillar/links/types";
import { PropsWithRequiredChildren } from "@pillar/std/types/react";
import { FC, useEffect } from "react";
import { proxy } from "valtio";
import { derive } from "valtio/utils";

const state = proxy({
  ready: false,
  rootResourceLink: null as APIResolveFromPid | null,
  nestedResourceLink: null as APIResolveFromPid | null,
  params: {} as Partial<Record<HubParams, string>>,
});
export const CurrentLinkStore = {
  state,
  derived: derive({
    targetPid: (get) => {
      const link = get(state).rootResourceLink;
      if (!link) return null;
      return link.type === "asset"
        ? link.pid
        : link.type === "assetInExplorer"
        ? link.resourcePid
        : link.type === "assetInPathway"
        ? link.resourceId
        : link.type === "pathway"
        ? link.pathwayPid
        : link.type === "service"
        ? link.servicePid
        : link.type === "explorer"
        ? link.repositoryPid
        : link.type === "pathwayInExplorer"
        ? link.pathwayPid
        : null;
    },
    pathwayPid: (get) => {
      return "pathwayPid" in get(state).params
        ? get(state).params.pathwayPid
        : null;
    },
  }),

  sagas: {
    computeRootResourceLinkFromEnv: (
      pathname: string,
      params: Partial<Record<HubParams, string>>
    ): APIResolveFromPid | null => {
      if (pathname.startsWith(`/${ResourceRendererType.asset}`) && params.pid) {
        return {
          type: ResourceRendererType.asset,
          pid: params.pid ?? "",
        };
      }

      if (pathname.startsWith(`/${ResourceRendererType.explorer}`)) {
        if (params.pid)
          return {
            type: ResourceRendererType.explorer,
            repositoryPid: params.pid ?? "",
          };

        return {
          type: ResourceRendererType.explorer,
          repositoryPid: params.explorerPid ?? "",
        };
      }

      if (pathname.startsWith(`/${ResourceRendererType.pathway}`)) {
        return {
          // @ts-expect-error
          type: "pathway",
          pathwayPid: params.pid ?? "",
          explorer: params.explorerPid ? params.explorerPid : undefined,
        };
      }

      if (pathname.startsWith(`/${ResourceRendererType.service}`)) {
        return {
          // @ts-expect-error
          type: "service",
          servicePid: params.pid ?? "",
        };
      }

      return null;
    },

    inferFromParams: (
      pathname: string,
      params: Partial<Record<HubParams, string>>
    ) => {
      let resourceLink = CurrentLinkStore.sagas.computeRootResourceLinkFromEnv(
        pathname,
        params
      );

      CurrentLinkStore.state.params = params;
      CurrentLinkStore.state.rootResourceLink = resourceLink;
      CurrentLinkStore.state.ready = true;
    },
  },
} satisfies ClientStore;

/** @deprecated */
export const useCurrentLinkStore = () => useStore(CurrentLinkStore);
export const InferCurrentLinkFromEnv: FC<PropsWithRequiredChildren> = (p) => {
  const params = useHubParams();
  const store = useCurrentLinkStore();
  useEffect(() => {
    store.sagas.inferFromParams(window.location.pathname, params);
  }, []);

  if (!store.state.ready) return <></>;
  return <>{p.children}</>;
};
