import { AnyResourceRendererProps } from "@pillar/singleResource/hub/types";
import { MobilePDFRenderer } from "./PDFRenderer";
import { ResourceMetadataCard } from "@pillar/singleResource/hub/client/ResourceRenderer";

import { Center, Flex, Loader } from "@mantine/core";
import { HubResourceTranslationsDropdown } from "@pillar/v3/accessibility/translation/hub/client/ResourceTranslations";
import { useHubResourceTranslations } from "@pillar/v3/accessibility/translation/hub/client/hooks";
import { FloatingBottomContainer } from "@pillar/v3/resources/hub/client/ResourceMetadata";

export function PDFRendererBody(p: AnyResourceRendererProps) {
  const translations = useHubResourceTranslations();
  const currentTranslation = translations.state.currentTranslation.val;

  if (translations.derived.isLoading)
    return (
      <Center w="100%" h="100%">
        <Loader size="xl" />
      </Center>
    );

  return (
    <Flex direction="column" pos="relative" w="100%" flex={1}>
      <MobilePDFRenderer
        className="flex-1 relative"
        href={currentTranslation.url}
      />
      <FloatingBottomContainer>
        <ResourceMetadataCard
          resource={p.resource}
          actions={p.actions}
          leftActions={<HubResourceTranslationsDropdown />}
        />
      </FloatingBottomContainer>
    </Flex>
  );
}
