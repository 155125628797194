import { proxy, ref, useSnapshot } from "valtio";
import { derive, useProxy } from "valtio/utils";

import { useStore } from "@pillar/client/store";
import { AppEventDomain, AppEventType } from "@pillar/events/types";

import { validString } from "@pillar/std/strings";
import {
  ClientInteractionsStore,
  HubInteractionStore,
} from "@pillar/v3/interactions/hub/client/interaction.store";
import { APIEventSessions } from "@pillar/v3/sessions/session.types";
import { APIAnyFeedbackInput } from "../../feedbacks.types";
import { APIInteractions } from "@pillar/v3/interactions/interaction-types";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { withHubToken } from "@pillar/v3/auth/hub/auth.hub.client";
import { v3HubApi } from "@pillar/v3/rpc/client.hub";

export type Rating = 0 | 1 | 2 | 3 | 4 | 5;
export type FeedbackScreens = "idle" | "rating" | "feedback" | "success";

type State = {
  lastRating: Rating;
  screen: FeedbackScreens;
};

type SelectSession = (
  interactionStore: ClientInteractionsStore
) => APIEventSessions;
// export class ResourceFeedbackStore {
//   state: State = {
//     lastRating: 0,
//     screen: "idle",
//   };

//   constructor(
//     protected interactions: ClientInteractionsStore,
//     protected selectSession: SelectSession
//   ) {
//     this.interactions = ref(interactions);
//     // biome-ignore lint/correctness/noConstructorReturn: <explanation>
//     return this;
//   }

//   get hasRating() {
//     return this.state.lastRating !== 0;
//   }

//   transitionScreen = (screen: FeedbackScreens) => {
//     this.state.screen = screen;
//   };

//   setRating = (rating: Rating) => {
//     this.state.lastRating = rating;
//   };
//   record = (input: APIAnyFeedbackInput) => {
//     const sessions = this.selectSession(this.interactions);
//     const feedback: APIInteractions =
//       input.type === "com.withpillar.resource-commented"
//         ? {
//             type: "com.withpillar.resource-commented",
//             comment: input.comment,
//             sessions,
//           }
//         : {
//             type: "com.withpillar.resource-rated",
//             rating: input.rating,
//             sessions,
//           };

//     return this.interactions.recordInteraction(feedback);
//   };

//   reset = () => {
//     this.state.lastRating = 0;
//     this.state.screen = "idle";
//   };
// }

export const makeResourceFeedbackStore =
  (interactions: ClientInteractionsStore, select: SelectSession) => () => {
    const [lastRating, setLastRating] = useState<Rating>(0);
    const [screen, setScreen] = useState<FeedbackScreens>("idle");

    const reset = () => {
      setLastRating(0);
      setScreen("idle");
    };

    const setRating = (rating: Rating) => {
      setLastRating(rating);
    };

    const transitionScreen = (screen: FeedbackScreens) => {
      setScreen(screen);
    };

    const hasRating = lastRating !== 0;

    const record = (input: APIAnyFeedbackInput) => {
      const sessions = select(interactions);
      const feedback: APIInteractions =
        input.type === "com.withpillar.resource-commented"
          ? {
              type: "com.withpillar.resource-commented",
              comment: input.comment,
              sessions,
            }
          : {
              type: "com.withpillar.resource-rated",
              rating: input.rating,
              sessions,
            };

      // hack
      const token = interactions.ac_getToken();
      const inputWithToken = withHubToken(token, feedback);

      return v3HubApi.v3.hub.feedbacks.record.mutate(inputWithToken);
    };

    return {
      lastRating,
      screen,
      reset,
      setRating,
      transitionScreen,
      hasRating,
      record,
    };
  };

export type ResourceFeedbackStore = ReturnType<
  ReturnType<typeof makeResourceFeedbackStore>
>;
export const useCurrentResourceFeedback = makeResourceFeedbackStore(
  HubInteractionStore,
  (interactions) =>
    interactions.selectSessions(
      (sessions) => sessions.session.sessionType === "resource"
    )
);

export const useCurrentPathwayFeedback = makeResourceFeedbackStore(
  HubInteractionStore,
  (interactions) =>
    interactions.selectSessions(
      (sessions) => sessions.session.sessionType === "pathway"
    )
);
