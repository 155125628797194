import { z } from "zod";
import { $HubTrackedContext } from "../auth/hub/auth.hub.types";
import { $DiscriminatedResourceUnion } from "../resources/resources.types";
import { $ContextWithInput } from "../rpc/context";
import { WithOrgId } from "../data/data.types";

export enum $LinkType {
  /** @deprecated */
  deprecated_service = "psvc",
  deprecated_file = "pr",
  file = "pubr",
  repo = "prr",
  pathway = "pptw",
  form = "frm",
  document = "doc",
}

export type $InviteLinkAggregate = {
  id: string;
  organizationId: string;
  pid: string;
  resourceId: string;
  repoId: string | null;
  href: string;
};

export type HubRepoResolvedResource = {
  type: "repo";
  resource: $DiscriminatedResourceUnion;
  refRepoId: string;
};

export type HubResolvedSingleResource = {
  type: "resource";
  resource: $DiscriminatedResourceUnion;
  inviteLink: $InviteLinkAggregate;
  refRepoId: string;
};

export type HubResolvedResource =
  | HubResolvedSingleResource
  | HubRepoResolvedResource;

export type $HubResolvedLinkContext<I> = $ContextWithInput<I> & {
  ctx: $HubTrackedContext & {
    resolved: HubResolvedResource;
  };
};

export const APISendResourceById = z.object({
  id: z.string(),
  repositoryId: z.string(),
  emails: z.array(z.string()),
});

export type APISendResourceById = z.infer<typeof APISendResourceById>;

export const APIGetOrCreateResourceLink = z.object({
  id: z.string(),
  repoId: z.string(),
});

export type APIGetOrCreateResourceLink = z.infer<
  typeof APIGetOrCreateResourceLink
>;

export type GetOrCreateResourceLinkInput =
  WithOrgId<APIGetOrCreateResourceLink>;
