import { ComponentType } from "react";
import { withTheme, FormProps } from "@rjsf/core";
import { generateTheme } from "./theme";
import { FormContextType, RJSFSchema, StrictRJSFSchema } from "@rjsf/utils";

export function generateForm<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>(): ComponentType<FormProps<T, S, F>> {
  return withTheme<T, S, F>(generateTheme<T, S, F>());
}

const defaultForm = generateForm();

export default defaultForm;

export const FormRenderer = defaultForm;
