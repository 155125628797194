import { AuthAppEvents } from "@pillar/auth/events";
import { CRMAppEvents } from "@pillar/crm/events";
import { OrganizationEvents } from "@pillar/organizations/events";
import {
  PathwayEvents,
  PathwayInteractionEvents,
} from "@pillar/pathways/events";

import { ResourceProviderEvents } from "@pillar/resources/events";
import { ResourceFeedbackEvents } from "@pillar/resources/feedback/events";
import { ResourceInteractionEvents } from "@pillar/resources/interactions/events";
import { ServiceEvents } from "@pillar/services/events";
import { UserAppEvents } from "@pillar/users/events";

import { z } from "zod";
import { TracingPayload } from "../observability/types";
import { MakeAppEvent } from "./base";

export enum AppEventDomain {
  resource = "resource",
  auth = "auth",
  api = "api",
  system = "system",
  workflows = "workflows",
  scheduling = "scheduling",
  crm = "crm",
  analytics = "analytics",
  booking = "booking",
  messaging = "messaging",
  client = "client",
  notifications = "notifications",
  users = "users",
  organization = "organization",
  pathways = "pathways",
  services = "services",
}

/**
 * All types of journal events
 */
/** @deprecated */
export enum AppEventType {
  /* auth */
  loginRequest = "loginRequest",
  loginSuccessful = "loginSuccessful",
  logout = "logout",
  /* provider */
  providerOnboarded = "providerOnboarded",
  // These events allow to filter out current active provider
  // 🟠 ❓Why we do not call this memberDeactivated ?
  providerDeactivated = "providerDeactivated",

  // member
  memberOnboarded = "memberOnboarded",
  memberUpdated = "memberUpdated",
  providerActivated = "providerActivated",
  memberNoteCreated = "memberNoteCreated",
  memberNoteUpdated = "memberNoteUpdated",
  memberNoteDeleted = "memberNoteDeleted",
  careRelationshipEventCreated = "careRelationshipEventCreated",
  careRelationshipEventUpdated = "careRelationshipEventUpdated",
  careRelationshipEventDeleted = "careRelationshipEventDeleted",

  //
  hubAuthentication = "hubAuthentication",

  // resource
  resourceTagFilterCreated = "resourceTagFilterCreated",
  resourceUploaded = "resourceUploaded",
  resourceUpdated = "resourceUpdated",
  resourcePublished = "resourcePublished",
  resourceUnpublished = "resourceUnpublished",

  resourceSaved = "resourceSaved",
  resourceUnsaved = "resourceUnsaved",
  resourceLiked = "resourceLiked",
  resourceUnliked = "resourceUnliked",
  resourceInviteLinkCreated = "resourceInviteLinkCreated",
  resourceShared = "resourceShared",
  resourceRated = "resourceRated",
  resourceCommented = "resourceCommented",
  resourceInviteJoined = "resourceInviteJoined",
  resourceEnrolled = "resourceEnrolled",
  resourceInviteOpened = "resourceInviteOpened",

  resourcePageLoaded = "resourcePageLoaded",

  resourceJitAuthed = "resourceJitAuthed",

  /** @deprecated */
  resourceViewed = "resourceViewed",
  /** @deprecated */
  resourceLoaded = "resourceLoaded",
  resourceReturnViews = "resourceReturnViews",

  resourcePageEntered = "resourcePageEntered",
  resourcePageExited = "resourcePageExited",
  resourcePageBlurred = "resourcePageBlurred",
  resourcePageFocused = "resourcePageFocused",
  resourcePageActive = "resourcePageActive",
  /** @deprecated */
  resourcePageInteraction = "resourcePageInteraction",
  /** @deprecated */
  resourcePaused = "resourcePaused",
  /** @deprecated */
  resourceStarted = "resourceStarted",
  /** @deprecated */
  resourceResumed = "resourceResumed",
  /** @deprecated */
  resourceScrubbed = "resourceScrubbed",
  /** @deprecated */
  resourceReset = "resourceReset",
  /** @deprecated */
  resourceFinished = "resourceFinished",
  resourcePageInteractionCheckpoint = "resourcePageInteractionCheckpoint",
  resourceClicked = "com.withpillar.resource_visited",

  trace = "trace",
  panic = "panic",

  // organizations
  organizationCreated = "organizationCreated",
  organizationUpdated = "organizationUpdated",

  // resourceGroup
  resourceGroupCreated = "resourceGroupCreated",
  resourceGroupUpdated = "resourceGroupUpdated",
  resourceGroupViewTime = "resourceGroupViewTime",

  // pathways
  pathwayCreated = "pathwayCreated",
  pathwayUpdated = "pathwayUpdated",
  pathwayCompleted = "pathwayCompleted",
  pathwayRated = "pathwayRated",
  pathwayFeedbackSubmitted = "pathwayFeedbackSubmitted",

  pathwayLoaded = "com.withpillar.pathway_loaded",
  pathwayInExplorerLoaded = "com.withpillar.pathway_explorer_loaded",
  explorerLoaded = "com.withpillar.explorer_loaded",
  explorerFiltered = "com.withpillar.explorer_filtered",
  explorerSearched = "com.withpillar.explorer_searched",

  // services
  serviceCreated = "com.withpillar.service_created",
  serviceUpdated = "com.withpillar.service_updated",

  serviceRated = "serviceRated",
  serviceFeedbackSubmitted = "serviceFeedbackSubmitted",
}

export type SystemAppEvents =
  | MakeAppEvent<
      AppEventType.trace,
      AppEventDomain.system,
      {
        membershipId: string;
        payload: TracingPayload;
      }
    >
  | MakeAppEvent<
      AppEventType.panic,
      AppEventDomain.system,
      {
        payload: TracingPayload;
        organizationId: string;
      }
    >;
export type AppEventsUnion =
  | SystemAppEvents
  | AuthAppEvents
  | ResourceProviderEvents
  | ResourceInteractionEvents
  | ResourceFeedbackEvents
  | PathwayEvents
  | PathwayInteractionEvents
  | ServiceEvents
  | CRMAppEvents
  | UserAppEvents
  | OrganizationEvents;

type AppEventsNames = AppEventsUnion["type"];
