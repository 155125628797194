import { withHubToken } from "@pillar/v3/auth/hub/auth.hub.client";
import { $useAuth } from "@pillar/v3/auth/hub/client/auth.hub.store";
import { v3HubApi } from "@pillar/v3/rpc/client.hub";

import { useEffect } from "react";

import { usePathwayStore } from "@pillar/pathways/hub/client/PathwayStore";
import { APIFormSubmissions } from "../../forms.types";

import { useCurrentRootPid } from "@pillar/v3/invite-links/hub/client/useCurrentLink";
import { xlog } from "@pillar/v3/stdlib/log";
import { HubInteractionStore } from "@pillar/v3/interactions/hub/client/interaction.store";
import { useQuery } from "@tanstack/react-query";

export const useHubForm = (p: { pid: string }) => {
  const { token, rootSessionId } = $useAuth({ pid: p.pid });

  // const initSession = useCallback(
  //   (store: ClientInteractionsStore) => {
  //     store.viewEntrypointResource(
  //       {
  //         sessionType: "resource",
  //         anyId: p.pid,
  //         sessionId: "",
  //       },
  //       rootSessionId
  //     );
  //   },
  //   [p.pid, rootSessionId]
  // );

  // const store = useInteractionStoreSingleton({
  //   token: token ?? "",
  //   onInit: initSession,
  // });

  const input = withHubToken(token ?? "", undefined);

  const query = useQuery(["get-form", p.pid], () =>
    v3HubApi.v3.hub.forms.get.query(input)
  );
  const submitMutation = async (submission: APIFormSubmissions) => {
    const input = withHubToken(token ?? "", { submission });
    const sessions = HubInteractionStore.currentSession?.sessions;
    if (!sessions) return;
    return v3HubApi.v3.hub.forms.submit.mutate({
      ...input,
      sessions,
    });
  };

  return {
    query,
    submitMutation,
  };
};

export const useHubFormInPathway = () => {
  const store = usePathwayStore();
  const formId = store.state.currentResource?.id ?? "";
  const pathwayPid = store.state.pathwayPid ?? "";

  const p = { formId, pathwayPid };

  const rootPid = useCurrentRootPid();

  const { token } = $useAuth({ pid: rootPid ?? "" });

  const query = useQuery(
    ["get-form-in-pathway", p.pathwayPid, p.formId],
    () => {
      const queryInput = withHubToken(token ?? "", {
        formId: p.formId,
      });
      return v3HubApi.v3.hub.forms.getByFormId.query(queryInput);
    }
  );

  const submitMutation = async (submission: APIFormSubmissions) => {
    const mutationInput = withHubToken(token ?? "", {
      formId: p.formId ?? "",
      submission,
    });

    xlog("submitting form", mutationInput);

    const sessions = HubInteractionStore.currentSession?.sessions;

    if (!sessions) return;

    const dat = {
      ...mutationInput,
      sessions,
    };
    return v3HubApi.v3.hub.forms.submitForFormId.mutate(dat);
  };

  return {
    query,
    submitMutation,
  };
};
