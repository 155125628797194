import { useQuery } from "@tanstack/react-query";
import { FC } from "react";
import { v3HubApi } from "@pillar/v3/rpc/client.hub";
import { $useAuth } from "@pillar/v3/auth/hub/client/auth.hub.store";
import { useIsMobile } from "@pillar/ui/obsidian/layout/hooks";
import { Center } from "@mantine/core";
import { InteractionRecorder } from "@pillar/v3/interactions/hub/client/InteractionsRecorder";
import { useHubParams } from "@pillar/hub/navigation";
import { InferCurrentLinkFromEnv } from "@pillar/links/hub/client/InferResourceLinkFromEnv";
import { HubConfig } from "@pillar/config/hub/client/HubConfig";
import { HubURLParams } from "@pillar/v3/client/urls/HubURLParams";
import { Autologin } from "@pillar/v3/auth/hub/client/AutoLogin";
import { SyncExplorerInteractionRecorder } from "@pillar/v3/repos/hub/client/SyncExplorerInteractionRecorder";

import { HubResourceRenderer_AntiCorruption } from "@pillar/v3/resources/hub/client/ResourceRenderer_antiCorruption";

type Ids = {
  explorerPid: string;
  resourcePid: string;
};

export const useFileInExplorer = (props: Ids) => {
  const { fromInput } = $useAuth({ pid: props.explorerPid });
  const query = useQuery(
    ["get-file-in-explorer", props.resourcePid, props.explorerPid],
    () => {
      return v3HubApi.v3.hub.files.getFileInExplorer.query(
        fromInput({
          pid: props.resourcePid,
        })
      );
    }
  );

  return query;
};

export const FileInExplorerContent = (props: Ids) => {
  const fileInExplorerQuery = useFileInExplorer(props);
  const { loading, isMobile } = useIsMobile();

  const data = fileInExplorerQuery.data;
  if (fileInExplorerQuery.isLoading)
    return (
      <Center w="100%" h="100%">
        {/* <Loader size="xl" /> */}
      </Center>
    );

  if (!data) return null;

  return (
    <InteractionRecorder
      command={{
        type: "enter-res-in-explorer",
        session: {
          sessionType: "resource",
          anyId: data.id,
          sessionId: "",
        },
      }}
    >
      <HubResourceRenderer_AntiCorruption
        isMobile={isMobile}
        explorerPid={props.explorerPid}
        resource={data}
      />
    </InteractionRecorder>
  );
};

export const FileInExplorerPage: FC = () => {
  const params = useHubParams();
  if (!params.explorerPid || !params.resourcePid) return <></>;

  return (
    <InferCurrentLinkFromEnv>
      <HubConfig pid={params.explorerPid}>
        <HubURLParams>
          <Autologin pid={params.explorerPid}>
            <SyncExplorerInteractionRecorder explorerPid={params.explorerPid}>
              <FileInExplorerContent
                explorerPid={params.explorerPid}
                resourcePid={params.resourcePid}
              />
            </SyncExplorerInteractionRecorder>
          </Autologin>
        </HubURLParams>
      </HubConfig>
    </InferCurrentLinkFromEnv>
  );
};
