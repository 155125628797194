import { FC, useEffect } from "react";

import { PublicResourceRendererMaster } from "@pillar/assets/hub/client/PublicResourceRendererMaster";
import { HubAuth } from "@pillar/auth/hub/client/HubAuth";
import { HubConfig } from "@pillar/config/hub/client/HubConfig";
import { PageNotFound } from "@pillar/hub/client/NotFound";
import { useHubParams } from "@pillar/hub/navigation";
import { InferCurrentLinkFromEnv } from "@pillar/links/hub/client/InferResourceLinkFromEnv";
import { useIsMobile } from "@pillar/ui/obsidian/layout/hooks";
import { useSingleResourceStore } from "@pillar/assets/hub/client/SingleResource.store";
import { InteractionRecorder } from "@pillar/v3/interactions/hub/client/InteractionsRecorder";
import { HubURLParams } from "@pillar/v3/client/urls/HubURLParams";

export const ServiceFlow: FC<{ pid: string }> = (props: { pid: string }) => {
  const store = useSingleResourceStore();
  useEffect(() => {
    store.sagas.init({
      pid: props.pid,
      explorerPid: undefined,
    });
    return () => {
      store.sagas.dispose();
    };
  }, []);

  const data = store.ops.fetchAsset.derived.dataOrNull;

  const { loading, isMobile } = useIsMobile();

  if (loading || !data) return <div>Loading...</div>;

  return <PublicResourceRendererMaster isMobile={isMobile} data={data} />;
};

export const ServiceFromLinkPage: FC = () => {
  const pid = useHubParams().pid;

  if (!pid) {
    return <PageNotFound />;
  }

  return (
    <InferCurrentLinkFromEnv>
      <HubConfig pid={pid}>
        <HubURLParams>
          <HubAuth pid={pid}>
            <InteractionRecorder
              command={{
                type: "load-link",
                session: {
                  sessionType: "resource",
                  anyId: pid,
                  sessionId: "",
                },
              }}
            >
              <ServiceFlow pid={pid} />
            </InteractionRecorder>
          </HubAuth>
        </HubURLParams>
      </HubConfig>
    </InferCurrentLinkFromEnv>
  );
};
