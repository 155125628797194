import { Star } from "lucide-react";
import { FC } from "react";

import { Group, Pill } from "@mantine/core";

import { FilterCategory } from "./ExplorerSearch.store";

/** @deprecated */
export const ActiveFilters: FC<{
  tags: string[];
  types: string[];
  ratings: number[];
  onRemove: (category: FilterCategory, filter: string | number) => void;
  size: "md" | "lg";
}> = (p) => {
  return (
    <Pill.Group
      px={"md"}
      w="100%"
      style={{ flexWrap: "nowrap", overflow: "auto" }}
    >
      {p.tags.map((tag) => {
        return (
          <Pill
            size={p.size}
            key={`active-filter-${tag}`}
            onRemove={() => p.onRemove("tags", tag)}
            withRemoveButton
          >
            {tag}
          </Pill>
        );
      })}
      {p.types.map((type) => {
        return (
          <Pill
            size={p.size}
            key={`active-filter-${type}`}
            onRemove={() => p.onRemove("types", type)}
            withRemoveButton
          >
            {type}
          </Pill>
        );
      })}
      {p.ratings.map((rating) => {
        return (
          <Pill
            size={p.size}
            key={`active-filter-${rating}`}
            onRemove={() => p.onRemove("rating", rating)}
            withRemoveButton
          >
            <Group wrap="nowrap" gap={2}>
              {rating}
              <Star size="0.8rem" />
            </Group>
          </Pill>
        );
      })}
    </Pill.Group>
  );
};
