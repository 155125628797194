import { FC, useEffect } from "react";
import { IterationCcw } from "lucide-react";

import { usePathwayStore } from "./PathwayStore";
import { Button, Modal, Stack, Title } from "@mantine/core";
import { BottomDrawer } from "@pillar/ui/obsidian/layout/drawer";

export const PathwayCompletedContent: FC = () => {
  const store = usePathwayStore();
  useEffect(() => {
    store.sagas.recordCompletionEvent();
  }, []);
  return (
    <Stack p={"md"} align="center" gap={"md"}>
      <Title order={3} ta="center">
        You have successfully completed this pathway!
      </Title>
      <Button
        onClick={store.actions.goBackToFirstResource}
        leftSection={<IterationCcw />}
        w="fit-content"
      >
        Go back to first resource
      </Button>
    </Stack>
  );
};

export type PathwayCompletedModalProps = {
  opened: boolean;
  onClose: () => void;
};

export const PathwayCompletedModal: FC<PathwayCompletedModalProps> = (p) => {
  return (
    <Modal
      closeButtonProps={{ "aria-label": "Close button" }}
      styles={{ header: { minHeight: 0, padding: 0 } }}
      opened={p.opened}
      onClose={p.onClose}
      centered
    >
      <PathwayCompletedContent />
    </Modal>
  );
};

export const MobilePathwayCompletedModal: FC<PathwayCompletedModalProps> = (
  p
) => {
  return (
    <BottomDrawer opened={p.opened} onClose={p.onClose} size={"260px"}>
      <PathwayCompletedContent />
    </BottomDrawer>
  );
};
