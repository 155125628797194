import { PropsWithChildren, useEffect, useRef, useState } from "react";
import {
  ClientInteractionsStore,
  HubInteractionStore,
} from "./interaction.store";
import { $useAuth } from "@pillar/v3/auth/hub/client/auth.hub.store";
import { useCurrentRootPid } from "@pillar/v3/invite-links/hub/client/useCurrentLink";
import {
  APIAnyResourceSession,
  APIEventSession,
  APIEventSessions,
  APIResourceSession,
} from "@pillar/v3/sessions/session.types";
import { set } from "date-fns";
import { onlyValid } from "@pillar/v3/stdlib/arrays";

type Command =
  | {
      type: "load-link";
      session: APIEventSessions["session"];
    }
  | {
      type: "enter-res-in-explorer";
      session: APIAnyResourceSession;
    }
  | {
      type: "enter-res-in-pathway";
      session: APIResourceSession;
      parent: string;
    };

type Props = {
  command: Command;
  regenOn?: any;
};
export const useInteractionsRecorder = ({ command, regenOn }: Props) => {
  const rootPid = useCurrentRootPid() ?? "";
  const token = $useAuth({
    pid: rootPid ?? "",
  });

  const instance = useRef(HubInteractionStore);

  const [ready, setReady] = useState(false);

  useEffect(() => {
    instance.current.withToken(token.token);
    if (command.type === "load-link") {
      instance.current.loadLink(command.session, token.rootSessionId ?? "");
    } else if (command.type === "enter-res-in-explorer") {
      instance.current.loadResInRepo(rootPid ?? "", command.session);
    } else if (command.type === "enter-res-in-pathway") {
      instance.current.loadResInPathway(command.parent, command.session);
    }
    setReady(true);
    return () => {
      if (command.type === "enter-res-in-explorer")
        instance.current.unloadAllBut([rootPid]);
      else if (command.type === "enter-res-in-pathway")
        instance.current.unloadAllBut([rootPid, command.parent]);
    };
  }, [regenOn].filter(onlyValid));

  return ready;
};

export const InteractionRecorder = (p: PropsWithChildren<Props>) => {
  const hook = useInteractionsRecorder(p);

  if (!hook) return null;
  return <>{p.children}</>;
};
