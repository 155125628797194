import type { FC } from "react";
import { Card, CloseButton, Group, Input, Stack } from "@mantine/core";
import { ActiveFilters } from "./ActiveFilters";

import { type FilterCategory, useExplorerSearch } from "./ExplorerSearch.store";
import { Search } from "lucide-react";
import { LayoutSelector, LayoutType } from "./HubExplorerLayout";

export const SearchBarAndLayoutButton: FC<{
  onSearch: (s: string) => void;
  searchValue: string;
  onToggleLayout: (isGrid: boolean) => void;
  isGrid: boolean;
}> = (p) => {
  return (
    <Group gap={"sm"} preventGrowOverflow={false}>
      <Input
        leftSection={<Search />}
        onChange={(e) => {
          p.onSearch(e.target.value);
        }}
        value={p.searchValue}
        flex="true"
        size="md"
        style={{ flexGrow: 1 }}
        rightSectionPointerEvents="all"
        rightSection={
          <CloseButton
            aria-label="Clear input"
            variant="transparent"
            onClick={() => p.onSearch("")}
            style={{ display: p.searchValue !== "" ? "block" : "none" }}
          />
        }
      />
      <LayoutSelector
        onChange={(type: LayoutType) => {
          p.onToggleLayout(type === LayoutType.grid);
        }}
        currentType={p.isGrid ? LayoutType.grid : LayoutType.list}
      />
    </Group>
  );
};
export const HubExplorerSearchAndLayout: FC = () => {
  const store = useExplorerSearch();

  return (
    <Card p={0} pb={4}>
      <Stack gap="xs">
        <SearchBarAndLayoutButton
          searchValue={store.state.query.search ?? ""}
          onSearch={(s: string) => {
            store.sagas.onQueryChange({ type: "search", value: s });
          }}
          onToggleLayout={(isGrid: boolean) => {
            store.actions.setIsGrid(isGrid);
          }}
          isGrid={store.state.isGrid}
        />

        <ActiveFilters
          size="md"
          tags={store.state.query.tags ?? []}
          types={store.state.query.types ?? []}
          ratings={store.state.query.rating ?? []}
          onRemove={(category: FilterCategory, filter: string) => {
            return store.sagas.onQueryChange({
              type: "filter",
              category,
              isChecked: false,
              value: filter,
            });
          }}
        />
      </Stack>
    </Card>
  );
};
