/**
 * get utility - an alternative to lodash.get
 * @param {Object} object
 * @param {String|Array} path
 * @param {*} defaultVal
 */
export function get<O extends {} | undefined, P extends string, D>(
  object: O,
  path: P | P[],
  defaultVal: D | undefined = undefined
) {
  const _path = Array.isArray(path)
    ? path
    : path.split(".").filter((i) => i.length);

  if (!_path.length) {
    return object === undefined ? defaultVal : object;
  }

  if (object === undefined) return defaultVal;

  return get(object[_path.shift()], _path, defaultVal);
}
