import {
  $SupportedInputLangs,
  $SupportedOutputLangs,
} from "./translation.types";

export const langEnumToReadable = (
  lang: $SupportedInputLangs | $SupportedOutputLangs
) => {
  if (lang === $SupportedInputLangs.en) {
    return "English";
  }
  if (lang === $SupportedOutputLangs.en) {
    return "English";
  }
  if (lang === $SupportedOutputLangs.es) {
    return "Spanish";
  }
  if (lang === $SupportedOutputLangs.zh) {
    return "Chinese";
  }
  return "Unknown";
};

export const langEnumToReadableUser = (
  lang: $SupportedInputLangs | $SupportedOutputLangs
) => {
  if (lang === $SupportedInputLangs.en) {
    return "English";
  }
  if (lang === $SupportedOutputLangs.en) {
    return "English";
  }
  if (lang === $SupportedOutputLangs.es) {
    return "Español";
  }
  if (lang === $SupportedOutputLangs.zh) {
    return "中文";
  }
  return "Unknown";
};

export const langEnumToReadableUserAbbreviation = (
  lang: $SupportedInputLangs | $SupportedOutputLangs
) => {
  if (lang === $SupportedInputLangs.en) {
    return "EN";
  }
  if (lang === $SupportedOutputLangs.en) {
    return "EN";
  }
  if (lang === $SupportedOutputLangs.es) {
    return "ES";
  }
  if (lang === $SupportedOutputLangs.zh) {
    return "中文";
  }
  return "Unknown";
};
