import { Box, Group, Text, Stack } from "@mantine/core";
import { FileQuestion } from "lucide-react";

export const NoResults = () => {
  return (
    <Stack gap="md" align="center" className="p-8 items-center justify-center">
      <FileQuestion size={"3em"} />
      <Stack gap="0" align="center">
        <Text>We could not find a matching resource.</Text>
        <Text>Try different filters or search terms.</Text>
      </Stack>
    </Stack>
  );
};
