import { FC, PropsWithChildren, useEffect } from "react";
import { Box, Center, Loader } from "@mantine/core";

import { useHubConfigStore } from "@pillar/config/hub/client/HubConfig/index.store";

import { AppPage, AppPageMain } from "@pillar/ui/obsidian/layout";

import { HubExplorerScreen } from "./DesktopHubExplorerScreen";
import { HubExplorerBanner } from "./HubExplorerBanner";
import { DesktopExplorerPageHeader } from "./ExplorerHomePageHeader";
import { useHubURLParamsStore } from "@pillar/v3/client/urls/params.hub.store";

const Layout = ({
  children,
  isEmbed,
}: PropsWithChildren<{ isEmbed: boolean }>) =>
  isEmbed ? (
    <AppPage padding={0}>
      <AppPageMain pt={0}>
        <Box p={"sm"}>{children}</Box>
      </AppPageMain>
    </AppPage>
  ) : (
    <AppPage padding={0}>
      <DesktopExplorerPageHeader />
      <AppPageMain>
        <HubExplorerBanner />
        <Box p={"xl"}>{children}</Box>
      </AppPageMain>
    </AppPage>
  );

// TODO CHLOE rename me after everything has been merged
/**
 * Hub Explorer page Component
 * @param props: { repositoryPid: string }
 * @returns
 */
export const PublicRepositoryFlow: FC<{ repositoryPid: string }> = (p) => {
  const hubStore = useHubConfigStore();
  const {
    derived: { isEmbed },
  } = useHubURLParamsStore();

  if (!hubStore.derived.config?.organization)
    return (
      <Center w="100%" h="100%">
        <Loader size="xl" />
      </Center>
    );

  return (
    <Layout isEmbed={isEmbed ?? false}>
      <HubExplorerScreen repositoryPid={p.repositoryPid} />
    </Layout>
  );
};
