import { FC, PropsWithChildren, useEffect } from "react";

import { useHubURLParamsStore } from "@pillar/v3/client/urls/params.hub.store";
import { useSearchParams } from "react-router-dom";

export const HubURLParams: FC<PropsWithChildren> = (props) => {
  const [searchParams, _] = useSearchParams();
  const urlParams = useHubURLParamsStore();

  useEffect(() => {
    urlParams.ops.loadParams(searchParams);
  }, [searchParams]);
  return <>{props.children}</>;
};
