import { z } from "zod";

import { WithOrgId, WithOrgIdAndRepoId } from "../data/data.types";
import { $Nullable } from "../stdlib/types/nullable";
import { ResourceMetadata } from "./hub/client/ResourceMetadataItems";

/** @deprecated */
export enum Legacy_ResourceType {
  document = "document",
  video = "video",
  pathway = "pathway",
  form = "form",
  service = "service",
  repo = "repo",
}

export const $ResourceTypeSchema = z.nativeEnum(Legacy_ResourceType);

export enum $ResourceMimeType {
  pdf = "application/pdf",
  html = "text/html",
  png = "image/png",
  ico = "image/x-icon",
  gif = "image/gif",
  jpg = "image/jpeg",
  jpeg = "image/jpeg",
  mp4 = "video/mp4",
  mov = "video/quicktime",
  audio = "audio/mp3",
  group = "application/x-group",
  pathway = "application/x-pathway",
  form = "application/x-form",
  form_item = "application/x-form-item",
  form_submission = "application/x-form-submission",

  // a generic pillar document
  doc = "application/x-pillar-document",
  goal = "application/x-goal",
  embed = "application/x-embed",
  repo = "application/x-repo",
  translation = "application/x-translation",

  // a specialized document with a specific call to action
  landingPage = "application/x-landing-page",
  enrollmentPage = "application/x-enrollment-page",

  symlink = "application/x-symlink",
}

export const $ResourceMimeTypeSchema = z.nativeEnum($ResourceMimeType);
export enum $ResourceKind {
  file = "file",
  pathway = "pathway",
  form = "form",
  document = "pillar-document",
  repo = "repo",
}

export const $ResourceKindSchema = z.nativeEnum($ResourceKind);

export type $BaseResourceIds = {
  id: string;
  pid: string | null;
  repoId: string;
  organizationId: string;
};
export type $BaseResourcePresentation = {
  name: string;
  description: string;
  thumbnail: $Nullable<string>;
  url: string;
};

export type $BaseResourceAiFields = {
  transcript: $Nullable<string>;
};

export type $BaseResourceTags = {
  tags: string[];
};

export type $BaseResourceName = {
  name: string;
  description: string;
};

export type $BaseResourceThumbnail = {
  thumbnail: string;
};

export type $BaseResourceMeta = {
  meta: ResourceMetadata;
};

export type $BaseResourceTaxonomy = {
  tags: string[];
  type: Legacy_ResourceType;
  mimeType: $ResourceMimeType;
  kind: $ResourceKind;
};

export type $DiscriminatedRepoResource = {
  kind: $ResourceKind.repo;
  mimeType: $ResourceMimeType.repo;
} & $BaseResourceTags &
  $BaseResourceIds &
  $BaseResourcePresentation;

export type $DiscriminatedFormResource = {
  kind: $ResourceKind.form;
  mimeType: $ResourceMimeType.form | $ResourceMimeType.form_submission;
} & $BaseResourceIds &
  $BaseResourceTags &
  $BaseResourceName;

export type $DiscriminatedFileResource = {
  kind: $ResourceKind.file;
  mimeType:
    | $ResourceMimeType.pdf
    | $ResourceMimeType.mp4
    | $ResourceMimeType.mov
    | $ResourceMimeType.audio
    | $ResourceMimeType.png
    | $ResourceMimeType.jpeg
    | $ResourceMimeType.jpg;
} & $BaseResourceIds &
  $BaseResourceTags &
  $BaseResourceName &
  $BaseResourcePresentation &
  $BaseResourceAiFields;

export type $DiscriminatedPathwayResource = {
  kind: $ResourceKind.pathway;
  mimeType: $ResourceMimeType.pathway;
} & $BaseResourceIds &
  $BaseResourceTags &
  $BaseResourceName &
  $BaseResourcePresentation;

export type $DiscriminatedLandingPageResource = {
  kind: $ResourceKind.document;
  mimeType: $ResourceMimeType.landingPage;
  renderer: "externalLinkLandingPage";
  logoUrl: string;
} & $BaseResourceIds &
  $BaseResourceTags &
  $BaseResourceName &
  $BaseResourcePresentation;

export type $DiscriminatedEnrollmentPageResource = {
  kind: $ResourceKind.document;
  mimeType: $ResourceMimeType.enrollmentPage;
  renderer: "enrollmentLandingPage";
  logoUrl: string;
} & $BaseResourceIds &
  $BaseResourceTags &
  $BaseResourceName &
  $BaseResourcePresentation;

export type $DiscriminatedResourceUnion =
  | $DiscriminatedRepoResource
  | $DiscriminatedFileResource
  | $DiscriminatedFormResource
  | $DiscriminatedPathwayResource
  | $DiscriminatedLandingPageResource
  | $DiscriminatedEnrollmentPageResource;

export type $SingleResourceUnion =
  | $DiscriminatedFileResource
  | $DiscriminatedFormResource
  | $DiscriminatedLandingPageResource
  | $DiscriminatedEnrollmentPageResource;

export const $APIGetResourceById = z.object({
  id: z.string(),
});

export type $APIGetResourceById = z.infer<typeof $APIGetResourceById>;

export type $GetResourceById = WithOrgIdAndRepoId<
  z.infer<typeof $APIGetResourceById>
>;

export type ClientCompactResource = {
  id: string;
  uri: string;
};

export type ASingleResourceProps = {
  resource: ClientCompactResource;
};

export type SingleResourceAnalytics = {
  totalViews: number;
  uniqueViews: number;

  totalViewTime: number;
  avgViewTime: number;

  totalRatings: $Nullable<number>;
  totalRatingsSum: $Nullable<number>;
  avgRating: $Nullable<number>;
};

export const APILinkResourcesToRepositories = z.object({
  resourceIds: z.array(z.string()),
  repositoryIds: z.array(z.string()),
});

export type APILinkResourcesToRepositories = z.infer<
  typeof APILinkResourcesToRepositories
>;

export const APICreateResource = z.object({
  name: z.string(),
  description: z.string(),
  thumbnail: z.string().nullable(),
  transcript: z.string().nullable(),
  tags: z.array(z.string()),
  embeddings: z.array(z.number()).nullable(),
  url: z.string(),
  kind: $ResourceKindSchema,
  mimeType: $ResourceMimeTypeSchema,
});

export type APICreateResource = z.infer<typeof APICreateResource>;

export const APICreateManyResources = z.object({
  repositoryId: z.string(),
  organizationId: z.string().optional(),
  resources: z.array(APICreateResource),
});

export type APICreateManyResources = z.infer<typeof APICreateManyResources>;

export const CreateManyResources = APICreateManyResources.extend({
  uploadedById: z.string(),
  organizationId: z.string(),
});

export type CreateManyResources = z.infer<typeof CreateManyResources>;

export const $APIGetResourcePerformance = z.object({
  id: z.string(),
  repoId: z.string(),
});

export type $APIGetResourcePerformance = z.infer<
  typeof $APIGetResourcePerformance
>;

export const APIPublishResource = z.object({
  id: z.string(),
  repoId: z.string(),
});

export type APIPublishResource = z.infer<typeof APIPublishResource>;

export type PublishResource = WithOrgIdAndRepoId<APIPublishResource>;
