import { InteractionRecorder } from "@pillar/v3/interactions/hub/client/InteractionsRecorder";
import { PropsWithChildren } from "react";

export const SyncExplorerInteractionRecorder = (
  p: PropsWithChildren<{
    explorerPid: string;
  }>
) => {
  // remove this when we will have proper shared layouts
  // for now if we load a deep page, we'll need to load the explorer link event first
  return (
    <InteractionRecorder
      command={{
        type: "load-link",
        session: {
          sessionType: "repo",
          repoPid: p.explorerPid,
          sessionId: "",
        },
      }}
    >
      {p.children}
    </InteractionRecorder>
  );
};
