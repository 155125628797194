import { FC, ReactNode } from "react";

import { Form } from "./Form";
import { useHubFormInPathway } from "./hub.hooks";

export const FormInPathway: FC<{
  successActions?: ReactNode;
}> = (p) => {
  const { query, submitMutation } = useHubFormInPathway();

  return (
    // <FadeInContent isLoading={query.isLoading}>
    query.data && (
      <Form
        form={query.data}
        onSubmit={submitMutation}
        successActions={p.successActions}
      />
    )
    // </FadeInContent>
  );
};
