import { Center, Loader } from "@mantine/core";

export const Loading = () => <Loader color="gray" />;

export const LoadingBlock = () => (
  <Center p={10} w="100%">
    <Loading />
  </Center>
);

export const LoadingFullPage = () => (
  <Center p={10} w="100%" h="100%">
    <Loading />
  </Center>
);
