import { hasEmoji } from "./string";

const inYourFaceEmoji = ["🤬", "🔥", "💥", "🔴", "😵‍💫"];

const randomPrefix = () => {
  const randomIndex = Math.floor(Math.random() * inYourFaceEmoji.length);
  return inYourFaceEmoji[randomIndex];
};
export const xlog = (...args: unknown[]) => {
  const prefix = hasEmoji(args[0] as string) ? "" : randomPrefix();
  console.debug(new Date().toLocaleTimeString(), prefix, ...args);
};
