import { makeAsync } from "@pillar/client/async";
import { ClientStore, useStore } from "@pillar/client/store";
import { hubApi } from "@pillar/hub/api/client";

import { proxy } from "valtio";
import { derive } from "valtio/utils";
import { HubLoginMethod, NextHubAuthState } from "../types";

type State = {
  nextState: NextHubAuthState["type"] | "idle";
  rootUserSessionId: string | null;
  token: string | null;
  method: HubLoginMethod | null;
};
export const state = proxy<State>({
  token: null,
  rootUserSessionId: null,
  nextState: "idle",
  method: null,
});

export const actions = {
  setToken(token: string) {
    state.token = token;
  },
  setState(newState: NextHubAuthState["type"]) {
    state.nextState = newState;
  },

  setRootUserSessionId(id: string) {
    state.rootUserSessionId = id;
  },

  async runAndSetTokenFromResult({
    token,
    userSessionId,
  }: {
    token: string;
    userSessionId: string;
  }) {
    actions.setToken(token);
    actions.setState("render");
    actions.setRootUserSessionId(userSessionId);
  },
};
export const ops = {
  checkAuth: makeAsync(async (pid: string) => {
    return hubApi.v1.auth.checkAuth.mutate({
      token: state.token ?? undefined,
      resource: {
        type: "unknown",
        pid,
      },
    });
  }),
  trackLogin: makeAsync(async (opts: { pid: string; email: string }) => {
    return actions.runAndSetTokenFromResult(
      await hubApi.v1.auth.login.track.mutate({
        email: opts.email,
        resource: {
          type: "unknown",
          pid: opts.pid,
        },
      })
    );
  }),
};

export const derived = derive({
  authenticated: (get) => get(state).token !== null,
  authStateLoadingBlocking: (get) => get(ops.checkAuth.derived).isLoading,
});

export const sagas = {
  async checkNextAuthAction(pid: string) {
    const nextState = await ops.checkAuth.run(pid);

    if (nextState.type === "render") {
      state.nextState = "render";
      actions.setToken(nextState.token);
      return;
    }
    if (nextState.type === "login") {
      state.nextState = "login";
      state.method = nextState.method;
      return;
    }
    state.nextState = nextState;
  },
};
export const HubAuthStore = {
  state,
  derived,
  ops,
  sagas,
} satisfies ClientStore;

export const useHubAuthStore = () => {
  return useStore(HubAuthStore);
};
