import { ResourceTypeTag } from "@pillar/resources/types.legacy";
import { AppEventDomain, AppEventType } from "@pillar/events/types";
import {
  MobilePreviewServiceRenderer,
  PreviewServiceRendererType,
} from "@pillar/services/hub/client/MobilePreviewServiceRenderer";
import { PreviewServiceRenderer } from "@pillar/services/hub/client/PreviewServiceRenderer";
import { AnyResourceRendererProps } from "@pillar/singleResource/hub/types";
import { FC } from "react";
import { usePathwayStore } from "./PathwayStore";
import { serviceStore } from "@pillar/services/hub/client/Service.store";

export const ServiceInPathwayRenderer: React.FC<
  Omit<PreviewServiceRendererType, "onOutboundClick">
> = (p) => {
  const pathwayStore = usePathwayStore();

  return (
    <PreviewServiceRenderer
      onOutboundClick={() => {
        serviceStore.recordClickEvent();
      }}
      {...p}
    />
  );
};

export const ServiceInPathwayOrNull: FC<AnyResourceRendererProps> = (p) => {
  return p.resource.types.includes(ResourceTypeTag.service) ? (
    <ServiceInPathwayRenderer
      href={p.resource.url}
      title={p.resource.name}
      description={p.resource.description}
      poster={p.resource.thumbnail}
      logo={p.resource.logoUrl}
      tags={p.resource.tags}
      actions={p.actions}
      showLogo={true}
      meta={p.resource.meta}
    />
  ) : null;
};

export const MobileServiceInPathwayRenderer: React.FC<
  Omit<PreviewServiceRendererType, "onOutboundClick">
> = (p) => {
  const pathwayStore = usePathwayStore();

  return (
    <MobilePreviewServiceRenderer
      onOutboundClick={
        () => serviceStore.recordClickEvent()
        // pathwayStore.sagas.recordResourceInteractionEvent({
        //   type: AppEventType.resourceClicked,
        //   domain: AppEventDomain.resource,
        // })
      }
      {...p}
    />
  );
};

export const MobileServiceInPathwayOrNull: FC<AnyResourceRendererProps> = (
  p
) => {
  return p.resource.types.includes(ResourceTypeTag.service) ? (
    <MobileServiceInPathwayRenderer
      href={p.resource.url}
      title={p.resource.name}
      description={p.resource.description}
      poster={p.resource.thumbnail}
      logo={p.resource.logoUrl}
      tags={p.resource.tags}
      actions={p.actions}
      meta={p.resource.meta}
    />
  ) : null;
};
