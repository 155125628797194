import { makeAsync } from "@pillar/client/async";
import { hubApi } from "@pillar/hub/api/client";

import { HubAuthStore } from "@pillar/auth/hub/client/HubAuth.store";
import { useStore } from "@pillar/client/store";
import { APIHubGetResourceInExplorer } from "@pillar/explorer/types";
import { ClientInteractionsStore } from "@pillar/v3/interactions/hub/client/interaction.store";
import { proxy } from "valtio";
import { derive } from "valtio/utils";
import { APIHubGetStaticResource } from "../types";

export const state = proxy({
  pid: null as string | null,
  explorerPid: null as string | null,
});

export const derived = derive({
  props: (get) => {},
});

export const ops = {
  recordInviteLinkOpened: makeAsync((props: { pid: string }) => {
    return hubApi.v1.links.recordInviteLinkOpened.mutate({
      type: "unknown",
      pid: props.pid,
    });
  }),

  fetchAsset: makeAsync(async (props: APIHubGetStaticResource) => {
    return hubApi.v1.assets.getAsset.mutate({
      token: props.token,
      resource: props.resource,
    });
  }),
};

export const services = {
  events: new ClientInteractionsStore(),
};

export const actions = {};

export const sagas = {
  fetchAsset(pid: string) {
    const token = HubAuthStore.state.token;
    if (!token) throw new Error("No token");

    return ops.fetchAsset.run({
      token,
      resource: {
        type: "asset",
        pid,
      },
    });
  },

  async init(opts: { pid: string; explorerPid?: string }) {
    console.info("initialized with ....", opts);
    state.pid = opts.pid;

    if (opts.explorerPid) state.explorerPid = opts.explorerPid;

    await sagas.fetchAsset(opts.pid);

    if (!opts.explorerPid) {
      await ops.recordInviteLinkOpened.run({
        pid: opts.pid,
      });
    }

    window.scrollTo(0, 0);
  },
};

export const SingleResourceStore = {
  state,
  actions,
  ops,
  sagas,
  derived,
};

export const useSingleResourceStore = () => {
  const store = useStore(SingleResourceStore);
  return store;
};
