import { z } from "zod";
import {
  $DiscriminatedPathwayResource,
  $DiscriminatedRepoResource,
  $DiscriminatedResourceUnion,
  $ResourceKind,
  $ResourceKindSchema,
  $ResourceMimeType,
  $ResourceMimeTypeSchema,
} from "../resources/resources.types";
import { WithOrgId } from "../data/data.types";
import { MembershipCompact } from "../users/users.types";
import { APIDateRangeFilter } from "../analytics/analytics.types";
import { FormSubmissionAggregate } from "../forms/forms.types";

export type LowLevelSessionEntity = {
  id: string;
  organization_id: string;
  membership_id: string;
  // satellite links
  repository_id: string | null;
  // legacy
  pathway_id: string | null;
  // TODO:
  pathway_resource_id: string | null;
  invite_link_id: string | null;

  parent_kind: $ResourceKind;
  root_kind: $ResourceMimeType;

  target_resource_id: string | null;
  target_kind: $ResourceKind;
  target_mime_type: $ResourceMimeType;

  // analytics
  total_view_time: number;

  last_interaction_at: string | null;
  last_rated_at: string | null;
  last_rated_value: number | null;
  last_rated_feedback_id: string | null;

  last_commented_at: string | null;
  last_commented_value: string | null;
  last_commented_feedback_id: string | null;

  last_clicked_at: string | null;
  last_printed_at: string | null;
  last_submitted_at: string | null;

  parent_session_id: string | null;
  root_session_id: string | null;

  resource_instance_id: string | null;

  created_at: string | null;

  pathway_name: string | null;
  pathway_res_name: string | null;
  target_res_name: string | null;
  target_res_thumbnail: string | null;

  pathway_to_res_id: string | null;

  membership_email: string;
  accessed_through: string | null;
};

export type SessionEntity = Omit<
  LowLevelSessionEntity,
  | "last_interaction_at"
  | "last_rated_at"
  | "last_commented_at"
  | "last_clicked_at"
  | "last_printed_at"
  | "last_submitted_at"
  | "created_at"
> & {
  last_interaction_at: Date | null;
  last_rated_at: Date | null;
  last_commented_at: Date | null;
  last_clicked_at: Date | null;
  last_printed_at: Date | null;
  last_submitted_at: Date | null;
  created_at: Date;
};

export type ResolvedSession = SessionEntity & {
  pathway: SessionViewModel["pathway"];
  target: SessionViewModel["targetResource"];
  membership: MembershipCompact;
};
export const APIEventPathwaySession = z.object({
  sessionId: z.string(),
  pathwayPid: z.string(),
  sessionType: z.literal("pathway"),
});

export type APIEventPathwaySession = z.infer<typeof APIEventPathwaySession>;

export const APIEventRepoSession = z.object({
  sessionId: z.string(),
  repoPid: z.string(),
  sessionType: z.literal("repo"),
});

export type APIEventRepoSession = z.infer<typeof APIEventRepoSession>;

export const APIResourceSession = z.object({
  sessionId: z.string(),
  anyId: z.string(),
  sessionType: z.literal("resource"),
});

export type APIResourceSession = z.infer<typeof APIResourceSession>;

export const APIEventSession = z.union([
  APIEventPathwaySession,
  APIEventRepoSession,
  APIResourceSession,
]);

export type APIAnyResourceSession = APIEventPathwaySession | APIResourceSession;

export type APIEventSession = z.infer<typeof APIEventSession>;

// #endregion

// #endregion

// #region event sessions

export const APIEventSessions = z.object({
  session: APIEventSession,
  root: APIEventSession.nullable(),
  parent: APIEventSession.nullable(),
});

export type APIEventSessions = z.infer<typeof APIEventSessions>;

export type ResolvedRepoSession = APIEventRepoSession & {
  inviteLinkId: string | null;
  inviteLinkRepoId: string | null;
  resource: $DiscriminatedRepoResource;
};
export type ResolvedPathwaySession = APIEventPathwaySession & {
  inviteLinkId: string | null;
  inviteLinkRepoId: string | null;
  resource: $DiscriminatedPathwayResource;
};
export type ResolvedResourceSession = APIResourceSession & {
  inviteLinkId: string | null;
  inviteLinkRepoId: string | null;
  resource: $DiscriminatedResourceUnion;
};

export type AnyResolvedSession =
  | ResolvedRepoSession
  | ResolvedPathwaySession
  | ResolvedResourceSession;

export type ResolvedSessions = {
  root: AnyResolvedSession | null;
  parent: AnyResolvedSession | null;
  session: AnyResolvedSession;
};

export type SessionAccessedThrough =
  | {
      type: "link";
      name?: string;
    }
  | {
      type: "pathway";
      name: string;
    }
  | {
      type: "repo";
      name?: string;
    };

type SessionViewModel = {
  pathway: {
    resourceId: string;
    name: string;
  } | null;
  targetResource: {
    resourceId: string;
    name: string;
    tags?: string[];
    thumbnail?: string;
  } | null;
  accessedThrough: SessionAccessedThrough[];
  membership: MembershipCompact;
  lastFeedback: {
    lastFeedbackAt: Date;
    lastFeedbackValue: string;
  } | null;

  lastRating: {
    lastRatingAt: Date;
    lastRatingValue: number;
  } | null;
  lastPrinted: Date | null;

  repo: {
    name: string;
  };
};

// TODO: add variant for FormSessionAggregate with lastSubmissionAt and lastClicked
export type SessionAggregate = SessionEntity & SessionViewModel;

export type SessionAggregateWithFormSubmission = SessionAggregate & {
  submission: FormSubmissionAggregate;
};

export const SessionsFilterForRepo = z.object({
  type: z.literal("for-repo"),
  repoId: z.string(),
  range: APIDateRangeFilter.nullable(),
  mimeTypes: z.array($ResourceMimeTypeSchema).nullable().default([]),
  accessedThrough: z
    .union([z.literal("explorer"), z.literal("link")])
    .optional(),
  limit: z.number().optional(),
});

export type SessionsFilterForRepo = z.infer<typeof SessionsFilterForRepo>;

export const SessionsFilterForResource = z.object({
  type: z.literal("for-resource"),
  resourceId: z.string(),
  kind: $ResourceKindSchema,
  range: APIDateRangeFilter.nullable(),
  repoId: z.string(),
});

export type SessionsFilterForResource = z.infer<
  typeof SessionsFilterForResource
>;

export const SessionsFilterForResourceAndMembership = z.object({
  type: z.literal("for-resource-and-membership"),
  resourceId: z.string(),
  membershipId: z.string(),
  range: APIDateRangeFilter.nullable(),
  repoId: z.string(),
});

export type SessionsFilterForResourceAndMembership = z.infer<
  typeof SessionsFilterForResource
>;

export const SessionsFiltersForFormSubmissions = z.object({
  type: z.literal("for-form-submissions"),
  resourceId: z.string(),
  range: APIDateRangeFilter.nullable(),
  repoId: z.string(),
});

export type SessionsFiltersForFormSubmissions = z.infer<
  typeof SessionsFiltersForFormSubmissions
>;
export const APIFindManySessions = z.union([
  z.object({
    type: z.literal("for-membership"),
    membershipId: z.string(),
    range: APIDateRangeFilter.nullable(),
    repoId: z.string(),
  }),
  SessionsFilterForRepo,
  SessionsFilterForResource,
  SessionsFilterForResourceAndMembership,
  z.object({
    type: z.literal("for-resource-type"),
    resourceId: z.string(),
    kind: $ResourceKindSchema.optional(),
    range: APIDateRangeFilter.nullable(),
    repoId: z.string(),
  }),
  z.object({
    type: z.literal("for-pathway-resources"),
    pathwayResourceId: z.string(),
    membershipId: z.string().nullable().default(null),
    range: APIDateRangeFilter.nullable(),
    repoId: z.string(),
  }),
  SessionsFiltersForFormSubmissions,
]);

export type APIFindManySessionsFilter = z.infer<typeof APIFindManySessions>;

export type FindManySessionsFilter = WithOrgId<
  z.infer<typeof APIFindManySessions>
>;
