import { proxy } from "valtio";
import { derive } from "valtio/utils";

import { makeAsync } from "@pillar/client/async";
import { ClientStore, DeriveGet, useStore } from "@pillar/client/store";
import { hubApi } from "@pillar/hub/api/client";

import {
  ResolvedPathwayModule,
  ResolvedPathwaySpecification,
  ResolvedPathwayStep,
  CompactResourceAggregateWithInteractions,
  PathwayMilestoneStep,
} from "@pillar/pathways/types";

import {
  APIHubCreateResourceInPathwayInteractionEvent,
  APIHubGetAnyPathway,
  APIHubGetResourceInPathway,
  APIHubGetResourceInPathwayInExplorer,
} from "../types";
import {
  EnrollmentState,
  PathwayEnrollmentState,
} from "@pillar/enrollments/contrib";
import { HubAuthStore } from "@pillar/auth/hub/client/HubAuth.store";

import { CurrentLinkStore } from "@pillar/links/hub/client/InferResourceLinkFromEnv";
import {
  APIResolvePathway,
  APIResolvePathwayInExplorer,
} from "@pillar/links/types";
import { APIResolveAssetInPathway } from "@pillar/links/types";
import { useEffect, useRef } from "react";
import { AppEventType } from "@pillar/events/types";
import {
  addTime,
  computeSecondToNearestDuration,
  humanizeLeftTime,
} from "@pillar/std/time";
import { createId } from "@paralleldrive/cuid2";

const getSpecificationWithViewed = (
  moduleId: string,
  enrollmentState: PathwayEnrollmentState,
  resources: CompactResourceAggregateWithInteractions[]
): CompactResourceAggregateWithInteractions[] => {
  return resources.map((r) => {
    return {
      ...r,
      viewed: enrollmentState.completed?.[moduleId]?.[r.id] === true,
    };
  });
};
export const getSpecificationWithReleasedModules = (
  steps: ResolvedPathwayStep[],
  enrollmentState: PathwayEnrollmentState,
  referenceTime: Date | undefined = undefined,
  clockTime: Date = new Date()
): ResolvedPathwayModule[] => {
  let lastTimeReference: Date | undefined = referenceTime;
  let lastMilestone: PathwayMilestoneStep | undefined = undefined;
  let lastModule: ResolvedPathwayModule | undefined = undefined;

  const now = clockTime.valueOf();

  const stepsWithState: ResolvedPathwayStep[] = steps.map((mod, index) => {
    // case milestone
    if (mod.type === "milestone") {
      let unlocked = mod.unlock.type === "always";
      let lockMessage: string | undefined;
      if (mod.unlock.type === "afterCompletion") {
        unlocked =
          lastModule !== undefined ? lastModule.completed === true : false;
        lockMessage = "Access by viewing all resources in the prior section.";
      } else if (mod.unlock.type === "afterTime") {
        unlocked = (() => {
          let _unlocked = false; // locked by default

          // process back duration, only timeFromLast is important
          const durationFromLast = computeSecondToNearestDuration(
            mod.unlock.timeFromLast
          );
          mod.unlock.duration = durationFromLast.duration;
          mod.unlock.timeUnit = durationFromLast.timeUnit;

          if (lastTimeReference) {
            const untilDate = addTime(
              lastTimeReference,
              mod.unlock.duration,
              mod.unlock.timeUnit
            );

            const unlocksInLessThan5Mins =
              untilDate.valueOf() - now <= 5 * 60 * 1000;

            const isPreviousMilestoneUnlocked =
              lastMilestone === undefined || lastMilestone.unlocked === true;

            // for now, if exist, last milestone MUST be unlocked to unlock this one
            _unlocked = unlocksInLessThan5Mins && isPreviousMilestoneUnlocked;
            lastTimeReference = new Date(untilDate.valueOf());
          }

          lockMessage = _unlocked
            ? undefined
            : `Access this section ${
                referenceTime
                  ? "in " +
                    humanizeLeftTime({
                      referenceDate: referenceTime,
                      duration: mod.unlock.duration,
                      timeUnit: mod.unlock.timeUnit,
                    })
                  : "later"
              }`;

          return _unlocked;
        })();
      } else {
        // case not yet supported (so stay locked)
      }
      return (lastMilestone = { ...mod, unlocked, lockMessage });
    }

    const resources = getSpecificationWithViewed(
      mod.id,
      enrollmentState,
      mod.resources
    );

    // case there were milestone before
    if (lastMilestone !== null) {
      const previousModuleIsCompleted = lastModule?.completed === true;
      const previousModuleIsUnlocked = lastModule?.unlocked === true;
      const previousMilestoneIsUnlocked =
        lastMilestone === undefined ? true : lastMilestone.unlocked === true;

      if (
        previousModuleIsCompleted &&
        previousModuleIsUnlocked &&
        previousMilestoneIsUnlocked
      ) {
        const unlocked = true;
        const completed = mod.resources.every((res) => {
          return enrollmentState.completed?.[mod.id]?.[res.id] === true;
        });

        return (lastModule = {
          ...mod,
          resources,
          completed,
          unlocked,
        });
      }

      const completed =
        mod.resources.every((res) => {
          return enrollmentState.completed?.[mod.id]?.[res.id] === true;
        }) && previousMilestoneIsUnlocked;
      return (lastModule = {
        ...mod,
        resources,
        unlocked: previousMilestoneIsUnlocked,
        completed,
        lockMessage: lastMilestone?.lockMessage,
      });
    }

    // case there is no milestone yet
    const unlocked = true;
    const completed = mod.resources.every((res) => {
      return enrollmentState.completed?.[mod.id]?.[res.id] === true;
    });

    return (lastModule = { ...mod, resources, completed, unlocked });
  });
  const modulesWithState: ResolvedPathwayModule[] = stepsWithState.filter(
    selectors.isResolvedPathwayModule
  );

  return modulesWithState;
};

const state = proxy({
  firstLoad: true,

  pathwayPid: null as null | string,
  explorerPid: null as null | string,
  enrollmentStartDate: new Date(),
  // explorer ID or NULL
  explorerRepositoryPid: null as null | string,
  interactionSessionBase: createId(),

  enrollmentState: proxy({
    completed: {},
    type: "pathway",
    specVersion: "v1",
  }) as EnrollmentState,

  currentModule: null as null | ResolvedPathwayStep,
  currentResource: null as null | CompactResourceAggregateWithInteractions,
  modules: [] as ResolvedPathwayModule[],

  isGrid: false,
  isSuccessScreenOpen: false,
  hasSuccessScreenAlreadyBeenOpened: false,
  isResourceOpened: false,
});

const ops = {
  fetchPathway: makeAsync(async (props: APIHubGetAnyPathway) => {
    return hubApi.v1.pathways.getPathway.mutate(props);
  }),

  fetchEnrollmentState: makeAsync(async (props: APIHubGetAnyPathway) => {
    return hubApi.v1.pathways.getPathwayEnrollmentState.mutate(props);
  }),

  fetchAssetInPathway: makeAsync(
    async (opts: { resource: APIResolveAssetInPathway; token: string }) => {
      return hubApi.v1.pathways.getResource.mutate({
        resource: opts.resource,
        token: opts.token,
      });
    }
  ),

  fetchAssetInPathwayInExplorer: makeAsync(
    async (opts: APIHubGetResourceInPathwayInExplorer) => {
      return hubApi.v1.pathways.getResourceInPathwayInExplorer.mutate({
        resource: opts.resource,
        token: opts.token,
      });
    }
  ),
};

export const selectors = {
  makeInlineInteractionSessionId: () => {
    return `${state.interactionSessionBase}-${state.currentResource?.id}`;
  },

  allResourcesCompleted: (hints: EnrollmentState) => (get: DeriveGet) => {
    const allModules = get(state).modules;
    return allModules.every((module) => {
      return module.resources.every((resource) => resource.viewed);
    });
  },

  getFirstResourceInFirstModule: (
    specification: ResolvedPathwaySpecification
  ): {
    module: ResolvedPathwayStep;
    resource: CompactResourceAggregateWithInteractions;
  } | null => {
    if (specification.type === "sequential") {
      const firstModule = specification.modules.find(
        (mod): mod is ResolvedPathwayModule => mod.type === "module"
      );
      if (firstModule) {
        const firstResource = firstModule.resources[0];
        if (firstResource) {
          return {
            module: firstModule,
            resource: firstResource,
          };
        }
      }
    }
    return null;
  },
  getPathway: (get: DeriveGet) => {
    return get(ops.fetchPathway.derived).dataOrNull;
  },
  enrollment: (get: DeriveGet) =>
    get(ops.fetchEnrollmentState.derived).dataOrNull,

  isResolvedPathwayModule: (
    step: ResolvedPathwayStep
  ): step is ResolvedPathwayModule => {
    return step.type === "module";
  },

  allSequentialModulesInOrder: (get: DeriveGet): ResolvedPathwayModule[] => {
    const pathway = selectors.getPathway(get);
    if (!pathway) return [];
    const specification = pathway.specification;

    if (specification.type === "stateMachine") {
      throw new Error("Not implemented");
    }
    const modules = selectors.allSteps(get);
    const enrollmentState = get(state).enrollmentState;
    const enrollmentStartDate = get(state).enrollmentStartDate;

    if (!enrollmentStartDate || !enrollmentState)
      return modules.filter(selectors.isResolvedPathwayModule);

    return getSpecificationWithReleasedModules(
      modules,
      enrollmentState,
      enrollmentStartDate
    );
  },

  allSteps: (get: DeriveGet): ResolvedPathwayStep[] => {
    const pathway = selectors.getPathway(get);
    if (!pathway) return [];
    const specification = pathway.specification;

    if (specification.type === "stateMachine") {
      throw new Error("Not implemented");
    }

    return specification.modules;
  },

  getCurrentModuleIdx: (get: DeriveGet) => {
    const currentModule = get(state).currentModule;
    if (!currentModule) return -1;
    if (currentModule.type === "milestone") return -1;
    const modulesInOrder = selectors.allSequentialModulesInOrder(get);
    return modulesInOrder
      .filter((mod): mod is ResolvedPathwayModule => mod.type === "module")
      .findIndex((mod) => mod.id === currentModule.id);
  },

  getCurrentResourceIdx: (get: DeriveGet) => {
    const currentModule = get(state).currentModule;
    if (!currentModule) return -1;
    if (currentModule.type === "milestone") return -1;

    const currentResource = get(state).currentResource;
    if (!currentResource) return -1;

    return currentModule.resources.findIndex(
      (resource) => resource.id === currentResource.id
    );
  },

  getCurrentResourceIdxInPathway: (get: DeriveGet) => {
    const currentResource = get(state).currentResource;
    if (!currentResource) return -1;
    const allModules = selectors.allSequentialModulesInOrder(get);
    const flatSpec = allModules.flatMap((mod) => {
      return mod.resources;
    });
    return flatSpec.findIndex((res) => res?.id === currentResource.id);
  },

  getTotalResources: (get: DeriveGet) => {
    const allModules = selectors.allSequentialModulesInOrder(get);
    let count = 0;
    allModules.forEach((module) => {
      count += module.resources.length;
    });
    return count;
  },

  getNextResource: (get: DeriveGet) => {
    const currentModule = get(state).currentModule;
    const currentModuleIdx = selectors.getCurrentModuleIdx(get);
    const currentResource = get(state).currentResource;
    const currentResourceIdx = selectors.getCurrentResourceIdx(get);
    const pathway = selectors.getPathway(get);

    if (!pathway) return { nextResource: null, module: null };
    const modulesInOrder = selectors.allSequentialModulesInOrder(get);

    const spec = pathway.specification;
    if (
      spec.type === "stateMachine" ||
      !currentModule ||
      !currentResource ||
      currentModule.type === "milestone"
    ) {
      return { nextResource: null, module: null };
    }

    const nextInModule =
      currentModule?.resources[(currentResourceIdx ?? -1) + 1] ?? null;
    if (nextInModule)
      return { nextResource: nextInModule, module: currentModule };

    const nextModule = modulesInOrder[currentModuleIdx + 1];
    if (!nextModule || !nextModule.unlocked)
      return { nextResource: null, module: null };

    const nextModuleFirstResource = nextModule.resources[0];
    if (nextModuleFirstResource)
      return { nextResource: nextModuleFirstResource, module: nextModule };
    throw new Error("Not implemented");
  },

  getPreviousResource: (get: DeriveGet) => {
    const currentModule = get(state).currentModule;
    const currentModuleIdx = selectors.getCurrentModuleIdx(get);
    const currentResource = get(state).currentResource;
    const currentResourceIdx = selectors.getCurrentResourceIdx(get);
    const pathway = selectors.getPathway(get);

    if (!pathway) return { previousResource: null, module: null };
    const modulesInOrder = selectors.allSequentialModulesInOrder(get);

    const spec = pathway.specification;
    if (
      spec.type === "stateMachine" ||
      !currentModule ||
      !currentResource ||
      currentModule.type === "milestone"
    ) {
      return { previousResource: null, module: null };
    }

    const previousInModule =
      currentModule?.resources[(currentResourceIdx ?? -1) - 1] ?? null;
    if (previousInModule) {
      return { previousResource: previousInModule, module: currentModule };
    }

    const previousModule = modulesInOrder[currentModuleIdx - 1];

    if (!previousModule) {
      return { previousResource: null, module: null };
    }
    const previousModuleLastResource =
      previousModule.resources[previousModule.resources.length - 1];
    if (previousModuleLastResource) {
      return {
        previousResource: previousModuleLastResource,
        module: previousModule,
      };
    }
    throw new Error("Not implemented");
  },

  getApiContext(get: DeriveGet): APIHubGetAnyPathway | null {
    const pathwayPid = get(state).pathwayPid;
    if (!pathwayPid) return null;

    const token = HubAuthStore.state.token;
    if (!token) return null;

    const pathwayResource: APIResolvePathwayInExplorer | APIResolvePathway =
      get(state).explorerPid && Boolean(get(state).explorerPid)
        ? {
            type: "pathwayInExplorer",
            repositoryPid: get(state).explorerPid ?? "",
            pathwayPid: pathwayPid,
          }
        : {
            pathwayPid: pathwayPid,
            type: "pathway",
          };

    const ctx: APIHubGetAnyPathway = {
      token: HubAuthStore.state.token ?? "",
      resource: pathwayResource,
      createUserSession: state.firstLoad,
    };

    return ctx;
  },
};

export const derived = derive({
  pathway: (get) => selectors.getPathway(get),
  previousResource: (get) =>
    selectors.getPreviousResource(get).previousResource,
  nextResource: (get) => selectors.getNextResource(get).nextResource,
  currentResourceIndexInPathway: (get) =>
    selectors.getCurrentResourceIdxInPathway(get) + 1,
  totalResources: (get) => selectors.getTotalResources(get),
  screen: (get) => {
    const currentResourceId = get(state).currentResource?.id;
    return Boolean(currentResourceId) ? "resource" : "home";
  },
});

export const actions = {
  setFirstTimeLoad: (firstLoad: boolean) => {
    state.firstLoad = firstLoad;
  },

  setPathwayPid: (pid: string) => {
    state.pathwayPid = pid;
  },
  setExplorerPid(pid: string) {
    state.explorerPid = pid;
  },
  goToHome: () => {
    actions.clearResourceAndModule();
  },
  setCurrentResourceViewed: () => {
    const currentModule = state.currentModule;
    const currentResource = state.currentResource;

    if (
      currentModule !== null &&
      currentResource !== null &&
      currentModule.type === ("module" as const) &&
      currentModule.id !== null &&
      currentResource.id !== null
    ) {
      state.enrollmentState = {
        ...state.enrollmentState,
        completed: {
          ...state.enrollmentState?.completed,
          [currentModule.id]: {
            ...state.enrollmentState?.completed?.[currentModule.id],
            [currentResource.id]: true,
          },
        },
      };

      state.modules = selectors.allSequentialModulesInOrder((v) => v);
    }
  },

  // TODO: should be a saga
  initFirstResource: (specification: ResolvedPathwaySpecification) => {
    const firstResource =
      selectors.getFirstResourceInFirstModule(specification);
    if (!firstResource) return;
    actions.setCurrentResourceAndModule(
      firstResource.resource,
      firstResource.module
    );
  },

  setCurrentResourceAndModule(
    resource: CompactResourceAggregateWithInteractions | null,
    module: ResolvedPathwayStep | null
  ) {
    state.currentModule = module;
    state.currentResource = resource;
    actions.setCurrentResourceViewed();
  },

  clearResourceAndModule: () => {
    state.currentModule = null;
    state.currentResource = null;
  },

  goBackToFirstResource: async () => {
    state.isSuccessScreenOpen = false;
    const pathway = derived.pathway;

    if (pathway) actions.initFirstResource(pathway.specification);
  },

  openSuccessModal: () => {
    state.isSuccessScreenOpen = true;
  },

  closeSuccessModal: () => {
    state.isSuccessScreenOpen = false;
  },

  transitionToNextResource: async () => {
    const { nextResource, module } = selectors.getNextResource((v) => v);

    if (!nextResource) {
      // We make sure we have the most up to date state
      actions.setCurrentResourceViewed();
      const allCompleted = selectors.allResourcesCompleted(
        state.enrollmentState ?? {
          type: "pathway",
          completed: {},
          specVersion: "v1",
        }
      )((v) => v);

      if (allCompleted) {
        actions.openSuccessModal();
      } else {
        actions.goBackToFirstResource();
      }
      return;
    }
    actions.setCurrentResourceAndModule(nextResource, module);
  },

  transitionToPreviousResource: async () => {
    const { previousResource, module } = selectors.getPreviousResource(
      (v) => v
    );

    if (!previousResource) {
      actions.clearResourceAndModule();

      return;
    }
    actions.setCurrentResourceAndModule(previousResource, module);
  },

  openResource: () => {
    state.isResourceOpened = true;
  },

  closeResource: () => {
    state.isResourceOpened = false;
  },
};

export const sagas = {
  toggleLayout() {
    state.isGrid = !state.isGrid;
  },

  recordCompletionEvent: () => {
    const token = HubAuthStore.state.token;
    if (!token) return;
    // return ops.completeEvent.run({
    //   token: token,
    // });
  },

  dispose() {
    ops.fetchPathway.reset();
  },

  async fetchAssetInPathwayFromContext(resourceId: string) {
    const token = HubAuthStore.state.token;
    if (!token) return;

    if (CurrentLinkStore.state.rootResourceLink?.type === "explorer") {
      return ops.fetchAssetInPathwayInExplorer.run({
        resource: {
          type: "assetInPathway",
          explorer: CurrentLinkStore.state.rootResourceLink.repositoryPid,
          resourceId: resourceId,
          pathwayPid: CurrentLinkStore.derived.pathwayPid ?? "",
        },

        token: token,
      });
    }

    const pathwayPid = CurrentLinkStore.derived.targetPid;
    if (!pathwayPid) return;

    const resourceLink: APIResolveAssetInPathway = {
      type: "assetInPathway",
      resourceId: resourceId,
      pathwayPid,
    };

    const ctx: APIHubGetResourceInPathway = {
      token: HubAuthStore.state.token ?? "",
      resource: resourceLink,
    };

    const res = await ops.fetchAssetInPathway.run(ctx);

    return res;
  },

  async refetchEnrollmentFromContext() {
    const ctx = selectors.getApiContext((v) => v);
    if (ctx === null) return;

    const enrollment = await ops.fetchEnrollmentState.run(ctx);

    state.enrollmentStartDate = enrollment.startedAt;
    state.enrollmentState = enrollment.state;
  },

  async fetchPathwayAndEnrollmentFromContext() {
    // emit last view time for previous if existing
    const ctx = selectors.getApiContext((v) => v);
    if (ctx === null) return;

    const pathway = await ops.fetchPathway.run(ctx);
    const enrollment = await ops.fetchEnrollmentState.run(ctx);

    state.enrollmentStartDate = enrollment.startedAt;
    state.enrollmentState = enrollment.state;

    if (state.firstLoad) {
      actions.setFirstTimeLoad(false);
    }
    return { pathway, enrollment };
  },

  async init(pid: string) {
    actions.setFirstTimeLoad(true);
    const token = HubAuthStore.state.token;
    if (!token) return;

    // TODO: infer this from the CurrentLinkStore directly
    const explorerPid =
      CurrentLinkStore.state.rootResourceLink?.type === "explorer"
        ? CurrentLinkStore.state.rootResourceLink.repositoryPid
        : null;
    if (explorerPid) actions.setExplorerPid(explorerPid);

    // save pid
    actions.setPathwayPid(pid);
    const data = await sagas.fetchPathwayAndEnrollmentFromContext();

    if (!data) {
      // FIXME: add error state
      return;
    }

    const specification = data.pathway.specification;
    if (!specification) return;

    state.modules = selectors.allSequentialModulesInOrder((v) => v);

    actions.clearResourceAndModule();
  },

  useOnResourceChange(resourceId: string) {
    const ref = useRef(resourceId);

    useEffect(() => {
      if (ref.current !== resourceId) {
        ref.current = resourceId;
      }

      sagas.fetchAssetInPathwayFromContext(resourceId);

      return () => {
        ops.fetchAssetInPathway.reset();
        ops.fetchAssetInPathwayInExplorer.reset();
      };
    }, [resourceId]);
  },
};

export const PathwayStore = {
  state,
  actions,
  derived,
  ops,
  sagas,
} satisfies ClientStore;

export const usePathwayStore = () => {
  return useStore(PathwayStore);
};
