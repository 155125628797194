import { PillarHubTokenName } from "./auth.hub.constants";

export const withHubToken = <T>(token: string, opts: T): T => {
  return {
    ...opts,
    __headers: {
      [PillarHubTokenName]: token,
    },
  };
};
