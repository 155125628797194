import { FC, PropsWithChildren, ReactNode } from "react";
import { Group, Image } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import {
  FOOTER_HEIGHT,
  HEADER_HEIGHT,
  MobileAppPage,
} from "@pillar/ui/obsidian/layout";
import { StaticResourceAggregate } from "@pillar/assets/hub/types";
import { MobileResourceMetadataOrActualContentRenderer } from "@pillar/singleResource/hub/client/MobileResourceRenderer";
import { validString } from "@pillar/std/strings";
import { useHubURLParamsStore } from "@pillar/v3/client/urls/params.hub.store";

export const MobilePublicResourceRenderer: FC<{
  resource: StaticResourceAggregate;
  actions: ReactNode;
  goBack?: () => void;
}> = (p) => {
  const [isResourceOpened, { open, close }] = useDisclosure();
  const {
    derived: { isEmbed },
  } = useHubURLParamsStore();

  const Layout: FC<PropsWithChildren> = ({ children }) =>
    isEmbed && !p.goBack ? (
      <MobileAppPage.Main px={0} pt={0} pb={FOOTER_HEIGHT}>
        {children}
      </MobileAppPage.Main>
    ) : (
      <>
        <MobileAppPage.Header
          goBack={isResourceOpened ? close : p.goBack}
          logo={
            validString(p.resource.logoUrl) ? (
              <Image src={p.resource.logoUrl} alt="Service Logo" mah="54px" />
            ) : null
          }
        >
          {p.resource.logoUrl ? undefined : p.resource.name}
        </MobileAppPage.Header>
        <MobileAppPage.Main
          styles={{ display: "flex" }}
          className="mobile-app-page-main"
          px={0}
          pt={HEADER_HEIGHT}
          pb={FOOTER_HEIGHT}
        >
          {children}
        </MobileAppPage.Main>
      </>
    );

  return (
    <MobileAppPage>
      <Layout>
        <MobileResourceMetadataOrActualContentRenderer
          resource={p.resource}
          opened={isResourceOpened}
          onOpen={open}
          showTitle={isEmbed && !p.goBack}
        />
      </Layout>
      <MobileAppPage.Footer>
        <Group px={"1em"} h="100%" justify={"space-between"} align={"center"}>
          {p.actions}
        </Group>
      </MobileAppPage.Footer>
    </MobileAppPage>
  );
};
