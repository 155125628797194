import {
  Badge,
  Button,
  Center,
  Group,
  Modal,
  Stack,
  Text,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { ResourceMetadata } from "@pillar/resources/types";
import {
  defaultCtaText,
  defaultEnrollmentSuccess,
} from "@pillar/services/types";

import { ApiEnrollmentServiceMetadata } from "@pillar/services/types";
import { normalizeUrl, validString } from "@pillar/std/strings";
import { Check } from "lucide-react";
import { useState } from "react";

type BaseProps = {
  onClick: () => void;
  compact: boolean;
  children: React.ReactNode;
};
type LandingPageCTAFlowProps = {
  type: "landing-page";
  href: string;
} & BaseProps;

type EnrollCTAProps = {
  type: "enrollment";
  meta: ApiEnrollmentServiceMetadata;
} & BaseProps;

const ButtonProps = (base: BaseProps) => ({
  mx: 4,
  px: 50,
  size: base.compact ? "md" : "lg",
});

export const OutboundFlowCTA = (p: LandingPageCTAFlowProps) => {
  return (
    <Button
      component={"a"}
      href={normalizeUrl(p.href) ?? ""}
      onClick={p.onClick}
      target={"_blank"}
      {...ButtonProps(p)}
    >
      {p.children}
    </Button>
  );
};

export const EnrolledPlaceholder = (p: { label?: string }) => {
  return (
    <Badge
      size="lg"
      color="green.2"
      leftSection={<Check size="1.5em" />}
      styles={{
        label: {
          fontWeight: "normal",
        },
      }}
    >
      {validString(p.label) ? p.label : defaultEnrollmentSuccess.badgeText}
    </Badge>
  );
};

export const EnrollFlowCTA = (p: EnrollCTAProps) => {
  const [enrolled, setEnrolled] = useState(false);
  const [opened, { open, close }] = useDisclosure();

  const onEnroll = () => {
    if (opened) return close();
    open();
    setEnrolled(true);
    p.onClick();
  };

  return (
    <>
      {enrolled ? (
        <EnrolledPlaceholder label={p.meta.success.badgeText} />
      ) : (
        <Button onClick={onEnroll} {...ButtonProps(p)}>
          {p.children}
        </Button>
      )}

      <Modal.Root
        padding={"xs"}
        size={"md"}
        opened={opened}
        onClose={close}
        centered
      >
        <Modal.Overlay
          {...{
            backgroundOpacity: 0.55,
            blur: 3,
          }}
        />
        <Modal.Content>
          <Modal.Header
            style={{
              position: "fixed",
              right: 0,
            }}
            pt={"0px"}
          >
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body
            style={{ margin: "5rem 3rem 5rem 3rem", textAlign: "center" }}
          >
            <Stack gap="lg">
              <Modal.Title
                style={{
                  fontWeight: "500",
                  fontSize: "1.4em",
                }}
              >
                {validString(p.meta.success.modalTitle)
                  ? p.meta.success.modalTitle
                  : defaultEnrollmentSuccess.modalTitle}
              </Modal.Title>
              <Text>
                {validString(p.meta.success.modalContent)
                  ? p.meta.success.modalContent
                  : defaultEnrollmentSuccess.modalContent}
              </Text>
            </Stack>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </>
  );
};

export const ServiceCTA: React.FC<
  { serviceMeta: ResourceMetadata } & Omit<BaseProps, "children">
> = (p) => {
  if (p.serviceMeta.type === "landing-page") {
    return (
      <OutboundFlowCTA
        type="landing-page"
        href={p.serviceMeta.url}
        onClick={p.onClick}
        compact={p.compact}
        children={
          validString(p.serviceMeta.ctaText)
            ? p.serviceMeta.ctaText
            : defaultCtaText["landing-page"]
        }
      />
    );
  } else if (p.serviceMeta.type === "enrollment") {
    return (
      <EnrollFlowCTA
        compact={p.compact}
        meta={p.serviceMeta}
        type="enrollment"
        onClick={p.onClick}
      >
        {validString(p.serviceMeta.ctaText)
          ? p.serviceMeta?.ctaText
          : defaultCtaText.enrollment}
      </EnrollFlowCTA>
    );
  }

  return null;
};
