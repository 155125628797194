import { useLayoutEffect, useState } from "react";

export const useIsMobile = (bp = 768) => {
  const force = window.location.search.includes("forceMobile");
  const [loading, setIsLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(force);

  useLayoutEffect(() => {
    if (force) return;
    if (window.document.documentElement.clientWidth < bp) {
      setIsMobile(true);
    }
    setIsLoading(false);
  }, [bp]);
  return {
    loading,
    isMobile,
  };
};
