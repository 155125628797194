import {
  Button,
  Card,
  Container,
  Flex,
  Group,
  Stack,
  Title,
} from "@mantine/core";
import { FC, PropsWithChildren, ReactNode, useState } from "react";

import { StaticResourceAggregate } from "@pillar/assets/hub/types";
import { ResourceMetadata } from "@pillar/resources/hub/client/PublicResourceEntities";
import {
  ChevronLeft,
  ChevronRight,
  ChevronRightIcon,
  Minus,
  Plus,
} from "lucide-react";

/** @deprecated */
export const ResourceMetadataCard: FC<{
  resource: StaticResourceAggregate;
  actions?: ReactNode;
  leftActions?: ReactNode;
}> = (p) => {
  const [opened, setOpened] = useState(false);
  return (
    <Card radius={"md"} flex="none" shadow={"md"} mx={"lg"} p="lg">
      <Stack gap="xs" flex={1}>
        <Group align="center" justify="space-between">
          {p.resource.name && p.resource.name !== "" && (
            <Group align="center">
              <Title order={3}>{p.resource.name}</Title>
              <Button
                size="compact-sm"
                color="gray"
                variant="subtle"
                rightSection={
                  opened ? <Minus size={"1em"} /> : <Plus size={"1em"} />
                }
                onClick={() => setOpened((val) => !val)}
              >
                {opened ? "Close" : "View"} Details
              </Button>
            </Group>
          )}
          <Group>
            {p.leftActions}
            {p.actions}
          </Group>
        </Group>
        {opened && <ResourceMetadata resource={p.resource} compact />}
      </Stack>
    </Card>
  );
};
