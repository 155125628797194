import { Alert, Center, MantineProvider } from "@mantine/core";
import { Info } from "lucide-react";
import { FC } from "react";

export const NotFound: FC = () => (
  <Center h="100%" w="100%">
    <Alert title="Something went wrong" color="orange" icon={<Info />}>
      We could not find the requested content. Please notify your sender.
    </Alert>
  </Center>
);

export const PageNotFound: FC = () => (
  <MantineProvider>
    <Center h="100%" w="100%">
      <Alert title="Something went wrong" color="orange" icon={<Info />}>
        This page does not exist. Please notify your sender.
      </Alert>
    </Center>
  </MantineProvider>
);
