import { Container } from "@mantine/core";
import { FC, PropsWithChildren, ReactNode } from "react";

export const PublicResourceEmbedLayout: FC<
  PropsWithChildren<{
    withBackground?: boolean;
    actions?: ReactNode;
    goBack?: () => void;
  }>
> = (p) => {
  return (
    <Container
      bg={p.withBackground ? "gray.2" : undefined}
      px={0}
      pt={16}
      className={"min-w-full overflow-auto flex"}
      h="100dvh"
    >
      {p.children}
    </Container>
  );
};
