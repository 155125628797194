import { WithOrgId } from "@pillar/v3/data/data.types";
import { TranslationMeta } from "@pillar/v3/resource-instances/resource-instances.types";
import { $APIGetResourceById } from "@pillar/v3/resources/resources.types";
import { z } from "zod";

export enum $SupportedInputLangs {
  en = "en",
}

export enum $SupportedOutputLangs {
  en = "en",
  es = "es",
  zh = "zh",
}

export type APIRequestTranslateDocument = {
  resourceId: string;
  targetLanguage: $SupportedOutputLangs;
};

export const APIRequestTranslateDocument = z.object({
  resourceId: z.string(),
  targetLanguage: z.nativeEnum($SupportedOutputLangs),
});

export type APIRequestTranslateDocumentResult = {
  jobId: string;
};

export const APIPollTranslateDocument = z.object({
  jobId: z.string(),
});

export type APIPollTranslateDocument = z.infer<typeof APIPollTranslateDocument>;

export type APIPollDocumentTranslationResultOK = {
  status: "SUCCESS";
  translationId: DocumentTranslationJobResult;
};

export type APIPollDocumentTranslationResultPending = {
  status: "PENDING";
};

export type APIPollDocumentTranslationResultProcessing = {
  status: "PROCESSING";
};

export type APIPollDocumentTranslationResult =
  | APIPollDocumentTranslationResultOK
  | APIPollDocumentTranslationResultPending
  | APIPollDocumentTranslationResultProcessing;

export type TranslationJobSuccesfullySubmitted =
  | {
      type: "pending";
      batchId: string;
    }
  | {
      type: "done";
      resourceId: string;
    };

export type TranslationCompleted = {
  id: string;
  uri: string;
};
export type APICheckBatchStatus = {
  batchId: string;
};

export const APICheckBatchStatus = z.object({
  batchId: z.string(),
});

export const CheckBatchStatusSuccess = z.object({
  type: z.literal("success"),
  resourceId: z.string(),
  instanceId: z.string(),
});
export type TranslateDocument = WithOrgId<APIRequestTranslateDocument> & {
  // the origin bucket (AWS)
  bucketName: string;
  resourcePath: string;
};

export type TranslateBlobInStorage = {
  // the transient
  inputLanguage: $SupportedInputLangs;
  inputUri: string;
  outputLanguage: $SupportedOutputLangs;
  outputUri: string;
};

export type TranslationAggregate = {
  id: string;
  organizationId: string;
  resourceId: string;
  lang: $SupportedOutputLangs;
  url: string;
  meta: TranslationMeta;
};

export const $APIGetAllTranslations = $APIGetResourceById;

export type $APIGetAllTranslations = $APIGetResourceById;

export type $APIApproveTranslation = {
  resourceInstanceId: string;
  approve: boolean;
};

export const $APIApproveTranslation = z.object({
  resourceInstanceId: z.string(),
  approve: z.boolean(),
});

///
// Job management
export const APIDocumentTranslationJob = z.object({
  targetLanguage: z.nativeEnum($SupportedOutputLangs),
  membershipId: z.string(),
  resourceId: z.string(),
  organizationId: z.string(),
});

export type APIDocumentTranslationJob = z.infer<
  typeof APIDocumentTranslationJob
>;

export const DocumentTranslationJobResult = z.object({
  id: z.string(),
  uri: z.string().url(),
});

export type DocumentTranslationJobResult = z.infer<
  typeof DocumentTranslationJobResult
>;

export type TranslationJob = "job-request-document-translation";

export const translationJobType = <T extends TranslationJob>(id: T) => id;

export type TranslationInputType = {
  "job-request-document-translation": APIDocumentTranslationJob;
};

export type TranslationOutputType = {
  "job-request-document-translation": DocumentTranslationJobResult;
};

///
