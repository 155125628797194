import { FC, memo, useEffect, useState } from "react";
import { Edit, Star, StarIcon } from "lucide-react";
import { z } from "zod";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  Rating,
  ResourceFeedbackStore,
  useCurrentPathwayFeedback,
  useCurrentResourceFeedback,
} from "./ResourceFeedback.store";

import {
  ActionIcon,
  Box,
  Button,
  ButtonVariant,
  Flex,
  FlexProps,
  Group,
  Modal,
  rem,
  Text,
  Textarea,
  Title,
} from "@mantine/core";
import { BottomDrawer } from "@pillar/ui/obsidian/layout/drawer";
import { useDisclosure } from "@mantine/hooks";

export enum StarSize {
  Small = "small",
  Large = "large",
}

const Feedback = z.object({
  feedback: z.string().min(1, "Please enter your feedback"),
});

export const FeedbackForm = (p: { instance: ResourceFeedbackStore }) => {
  const form = useForm({
    defaultValues: {
      feedback: "",
    },
    resolver: zodResolver(Feedback),
  });

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(async (values) => {
          console.info("Feedback submitted", values.feedback);
          await p.instance.record({
            type: "com.withpillar.resource-commented",
            comment: values.feedback,
          });
          p.instance.transitionScreen("success");
        })}
      >
        <Flex direction="column" gap={"md"}>
          <Box>
            <Title order={3}>
              Is there anything else that you'd like to share?
            </Title>
            <Text>This helps us make your experience better!</Text>
          </Box>

          <Textarea
            placeholder="Share your feedback"
            minRows={4}
            maxRows={8}
            size="lg"
            autosize
            {...form.register("feedback")}
            error={form.formState?.errors?.feedback?.message ?? ""}
          />
          <Flex justify={"flex-end"}>
            <Button size="lg" type="submit">
              Submit
            </Button>
          </Flex>
        </Flex>
      </form>
    </FormProvider>
  );
};

const EditRating = (p: {
  initialRating: Rating;
  onSave: (rating: number) => void;
}) => {
  const [rating, setRating] = useState(p.initialRating);
  return (
    <>
      <Flex direction={"column"} align="stretch" w="100%" gap={"xs"}>
        <Stars
          id={"edit"}
          rating={rating}
          onClick={setRating}
          size={StarSize.Large}
        />
        <Flex w="100%" justify={"space-between"}>
          <Text size={"sm"}>Very unsatisfied</Text>
          <Text size={"sm"}>Very satisfied</Text>
        </Flex>
      </Flex>
      <Flex w="100%" justify={"flex-end"}>
        <Button
          disabled={rating === 0}
          onClick={() => {
            console.info("from component");
            p.onSave(rating);
          }}
        >
          Next
        </Button>
      </Flex>
    </>
  );
};

export const RatingForm: FC<{
  instance: ResourceFeedbackStore;
}> = (p) => {
  return (
    <Box className="space-y-4">
      <Title order={3}>How satisfied are you with this resource?</Title>
      <EditRating
        initialRating={p.instance.lastRating as Rating}
        onSave={async (r: Rating) => {
          await p.instance.record({
            type: "com.withpillar.resource-rated",
            rating: r,
          });

          p.instance.transitionScreen("feedback");
        }}
      />
    </Box>
  );
};

export const Thanks: FC = () => {
  return (
    <Title order={3} ta="center">
      Thanks!
    </Title>
  );
};

export const RatingAndFeedbackModalContent: FC<{
  instance: ResourceFeedbackStore;
  compact?: boolean;
}> = (p) => {
  return (
    <Box
      className="feedback-content"
      px={p.compact ? rem(16) : rem(24)}
      py={p.compact ? rem(0) : rem(24)}
    >
      {p.instance.screen === "rating" ? (
        <RatingForm instance={p.instance} />
      ) : p.instance.screen === "feedback" ? (
        <FeedbackForm instance={p.instance} />
      ) : (
        <Thanks />
      )}
    </Box>
  );
};

export type RatingAndFeedbackModalProps = ConnectedFeedbackBarProps;

export const RatingAndFeedbackModal: FC<RatingAndFeedbackModalProps> = (
  props: RatingAndFeedbackModalProps
) => {
  const [opened, disclosure] = useDisclosure(false);

  const open = () => {
    disclosure.open();
    props.instance.transitionScreen("rating");
  };

  const close = () => {
    disclosure.close();
    props.instance.transitionScreen("idle");
  };

  const compact = props.compact === undefined ? true : props.compact;

  return (
    <>
      {props.instance.hasRating ? (
        <ActionIcon
          variant={"subtle"}
          aria-label={"Edit button"}
          onClick={open}
        >
          <Edit />
        </ActionIcon>
      ) : (
        <Button
          styles={{
            inner: {
              flexWrap: "wrap",
              wordWrap: "break-word",
              textTransform: props.buttonText ? "capitalize" : undefined,
            },
            root: { flexWrap: "wrap", wordWrap: "break-word" },
          }}
          onClick={open}
          size={compact ? "sm" : "lg"}
          variant={props.buttonVariant}
        >
          {props.buttonText ?? "Leave a rating"}
        </Button>
      )}

      <Modal
        closeButtonProps={{ "aria-label": "Close button" }}
        styles={{
          header: { minHeight: 0, padding: 0 },
          body: {
            paddingLeft: "28px",
            paddingRight: "28px",
            paddingBottom: "28px",
          },
        }}
        opened={opened}
        onClose={close}
        centered
        size={rem(572)}
      >
        <RatingAndFeedbackModalContent instance={props.instance} />
      </Modal>
    </>
  );
};

export const MobileRatingAndFeedbackModal: FC<RatingAndFeedbackModalProps> = (
  props: RatingAndFeedbackModalProps
) => {
  const [opened, disclosure] = useDisclosure(false);
  const open = () => {
    disclosure.open();
    props.instance.transitionScreen("rating");
  };

  const close = () => {
    disclosure.close();
    props.instance.transitionScreen("idle");
  };

  return (
    <>
      {props.instance.hasRating ? (
        <ActionIcon
          variant={"subtle"}
          aria-label={"Edit button"}
          onClick={open}
        >
          <Edit />
        </ActionIcon>
      ) : (
        <Button
          styles={{
            inner: {
              flexWrap: "wrap",
              wordWrap: "break-word",
              textTransform: props.buttonText ? "capitalize" : undefined,
            },
            root: { flexWrap: "wrap", wordWrap: "break-word" },
          }}
          onClick={open}
          size="md"
          variant={props.buttonVariant}
        >
          {props.buttonText ?? "Leave a rating"}
        </Button>
      )}
      <BottomDrawer
        opened={opened}
        onClose={close}
        size={
          props.instance.screen === "feedback"
            ? "390px"
            : props.instance.screen === "success"
            ? "110px"
            : "270px"
        }
        styles={{
          header: {
            minHeight: "42px",
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
      >
        <RatingAndFeedbackModalContent
          instance={props.instance}
          compact={true}
        />
      </BottomDrawer>
    </>
  );
};

const Stars = (p: {
  id: string;
  size?: StarSize;
  rating: Rating;
  onClick?: (newRating: Rating) => void;
}) => {
  const starSize = p.size === StarSize.Large ? "2.75em" : "1.1em";
  const starSpacing = p.size === StarSize.Large ? "xs" : "2";
  const starStroke = p.size === StarSize.Large ? "1.4px" : "2.4px";
  return (
    <Flex
      color="primary"
      direction="row"
      justify={"space-between"}
      gap={starSpacing}
      p={0}
    >
      {[1, 2, 3, 4, 5].map((star) => {
        const starState =
          star <= p.rating ? (
            <Star fill="currentColor" size={starSize} />
          ) : (
            <StarIcon strokeWidth={starStroke} size={starSize} />
          );

        return p.onClick !== undefined ? (
          <Button
            key={`${p.id}-${star}`}
            aria-label={`rating-${star}`}
            variant={"transparent"}
            onClick={async () => {
              p.onClick?.(star as Rating);
            }}
            p={0}
          >
            {starState}
          </Button>
        ) : (
          <Box key={`${p.id}-${star}`} p={1} c="primary">
            {starState}
          </Box>
        );
      })}
    </Flex>
  );
};

// we need to isolate this state change, otherwise the video will reload
export const ConnectedStars: FC<{ instance: ResourceFeedbackStore }> = ({
  instance,
}) => {
  return instance.hasRating ? (
    <Stars
      id={"current-rating"}
      size={StarSize.Small}
      rating={instance.lastRating as Rating}
    />
  ) : null;
};

// PUBLIC COMPONENTS

type FeedbackBarProps = {
  buttonText?: string;
  buttonVariant?: ButtonVariant;
  compact?: boolean;
};
type ConnectedFeedbackBarProps = FeedbackBarProps & {
  instance: ResourceFeedbackStore;
};
export const FeedbackBar: FC<ConnectedFeedbackBarProps> = (props) => {
  return (
    <Group>
      <ConnectedStars instance={props.instance} />
      <RatingAndFeedbackModal
        instance={props.instance}
        buttonText={props.buttonText}
        buttonVariant={props.buttonVariant}
        compact={props.compact}
      />
    </Group>
  );
};

export const ResourceFeedbackBar = (props: FeedbackBarProps) => {
  const store = useCurrentResourceFeedback();
  return <FeedbackBar instance={store as ResourceFeedbackStore} {...props} />;
};

export const PathwayFeedbackBar = (props: FeedbackBarProps) => {
  const store = useCurrentPathwayFeedback();
  return <FeedbackBar instance={store as ResourceFeedbackStore} {...props} />;
};

type MobileFeedbackBarProps = {
  buttonText?: string;
  buttonVariant?: ButtonVariant;
} & FlexProps;
type ConnectedMobileFeedbackBarProps = MobileFeedbackBarProps & {
  instance: ResourceFeedbackStore;
};

export const MobileFeedbackBar: FC<ConnectedMobileFeedbackBarProps> = (
  props
) => {
  const { buttonText, buttonVariant, ...flexProps } = props;
  return (
    <Flex gap={rem(6)} align={"center"} {...flexProps}>
      <ConnectedStars instance={props.instance} />
      <MobileRatingAndFeedbackModal
        instance={props.instance}
        buttonText={buttonText}
        buttonVariant={buttonVariant}
      />
    </Flex>
  );
};

export const MobileResourceFeedbackBar = (props: MobileFeedbackBarProps) => {
  const store = useCurrentResourceFeedback();
  return (
    <MobileFeedbackBar instance={store as ResourceFeedbackStore} {...props} />
  );
};

export const MobilePathwayFeedbackBar = (props: MobileFeedbackBarProps) => {
  const store = useCurrentPathwayFeedback();
  return (
    <MobileFeedbackBar instance={store as ResourceFeedbackStore} {...props} />
  );
};
