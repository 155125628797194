import { ScrollArea, Stack, Box, Container, Group, Title } from "@mantine/core";
import { FC, ReactNode } from "react";
import { StaticResourceAggregate } from "@pillar/assets/hub/types";
import { ResourceTypeTag } from "@pillar/resources/types.legacy";
import { ResourceMetadata } from "@pillar/resources/hub/client/PublicResourceEntities";
import { MobileVideoRenderer } from "@pillar/resources/hub/client/ResourceVideoRenderer";

import { MobileServiceRendererOrNull } from "@pillar/services/hub/client/MobilePreviewServiceRenderer";

import { FormInPathway } from "@pillar/v3/forms/hub/client/FormInPathway";
import { useHubResourceTranslations } from "@pillar/v3/accessibility/translation/hub/client/hooks";
import { MobilePDFRenderer } from "@pillar/v3/files/hub/client/PDFRenderer";

export const ActualVideoRenderer = (p: {
  resource: StaticResourceAggregate;
  showTitle?: boolean;
}) => {
  return (
    <>
      <MobileVideoRenderer
        href={p.resource.url}
        poster={p.resource.thumbnail}
        title={p.resource.name}
        send={console.debug}
        // EndScreen={({ onClose }) => <RatingForm onSuccess={onClose} />}
      />
      <ScrollArea h="50%" px={18}>
        {p.showTitle && <Title order={3}>{p.resource.name}</Title>}
        <ResourceMetadata resource={p.resource} />
      </ScrollArea>
    </>
  );
};

export const MobileActualPDfRenderer = (p: {
  resource: StaticResourceAggregate;
  showTitle?: boolean;
}) => {
  const currentTranslation =
    useHubResourceTranslations().state.currentTranslation.val;
  return (
    <>
      <Stack gap="sm" px={"sm"} pb={0} pt={"1.2em"}>
        {p.showTitle && <Title order={3}>{p.resource.name}</Title>}
        <ResourceMetadata resource={p.resource} compact />
      </Stack>
      <MobilePDFRenderer
        className="flex-1 relative"
        href={currentTranslation.url}
      />
    </>
  );
};

/** @FIXME: open / close logic should be in mobile PDF Preview */
export const MobileMetadataScreen: FC<{
  resource: StaticResourceAggregate;
  onOpen: () => void;
  showTitle?: boolean;
  // temp hack
  overrideServiceRenderer?: ReactNode;
}> = (p) => {
  const notAVideo = !p.resource.types.includes(ResourceTypeTag.video);
  return (
    <Stack
      w="100%"
      // h="100%"
      gap={"sm"}
      flex={1}
      style={{
        overflow: notAVideo ? undefined : "hidden",
      }}
    >
      {p.resource.types.includes(ResourceTypeTag.document) && (
        <MobileActualPDfRenderer {...p} />
      )}
      {p.resource.types.includes(ResourceTypeTag.video) && (
        <ActualVideoRenderer {...p} />
      )}
      {p.resource.types.includes(ResourceTypeTag.form) && (
        <Box px={"0"}>
          <FormInPathway />
        </Box>
      )}
      {/* Temporary fix. this should be more composable */}
      {p.overrideServiceRenderer ? (
        p.overrideServiceRenderer
      ) : (
        <MobileServiceRendererOrNull onOpen={p.onOpen} resource={p.resource} />
      )}
    </Stack>
  );
};
