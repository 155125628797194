import { CompactResourceAggregateWithInteractions } from "@pillar/pathways/types";
import { FC } from "react";

import { GridLayout, ListLayout } from "./HubExplorerLayout";

import { Button, Group, Stack, Title } from "@mantine/core";
import { ChevronRight } from "lucide-react";
import {
  ResourceGridItem,
  ResourceListItem,
} from "@pillar/resources/hub/client/ResourceListItem";
import { ExplorerSectionName, useExplorerSearch } from "./ExplorerSearch.store";

export const ExplorerResultSection = (p: {
  title: ExplorerSectionName;
  getResults: (
    results: CompactResourceAggregateWithInteractions[]
  ) => CompactResourceAggregateWithInteractions[];
  onSelectResource: (
    resource: CompactResourceAggregateWithInteractions
  ) => Promise<void>;
}) => {
  const store = useExplorerSearch();
  const results = store.state.results;
  const actualResults = p.getResults(results);
  const isGrid = store.state.isGrid;
  const expanded = store.state.expandedSection === p.title;
  const selectedSection = store.state.expandedSection;
  const visible = selectedSection === p.title || selectedSection === null;

  if (!visible) return null;
  if (actualResults.length === 0) return null;
  return (
    <ExplorerResultSectionComponent
      title={p.title ?? "Any"}
      isGrid={isGrid}
      resources={actualResults}
      onSelectResource={p.onSelectResource}
      expanded={expanded}
      onExpandSection={() => {
        store.actions.setExpandedSection(p.title);
      }}
      onCloseSection={() => {
        store.actions.setExpandedSection(null);
      }}
    />
  );
};
export type ResultSectionComponentProps = {
  title: string;
  isGrid: boolean;
  resources: CompactResourceAggregateWithInteractions[];
  onSelectResource: (
    resource: CompactResourceAggregateWithInteractions
  ) => Promise<void>;
  expanded: boolean;
  onExpandSection: () => void;
  onCloseSection: () => void;
};

export const ExplorerResultSectionComponent: FC<ResultSectionComponentProps> = (
  p
) => {
  const Layout = p.isGrid ? GridLayout : ListLayout;
  const isList = !p.isGrid;
  const ResourceItem = p.isGrid ? ResourceGridItem : ResourceListItem;

  const cutoff = isList ? 3 : 4;
  const toShow = p.expanded ? p.resources : p.resources.slice(0, cutoff);

  return (
    <Stack gap="xs">
      <Group justify={"space-between"}>
        <Title order={4}>{p.title}</Title>
        {p.resources.length > cutoff && (
          <Button
            onClick={p.expanded ? p.onCloseSection : p.onExpandSection}
            variant="transparent"
            size="sm"
            px={0}
            rightSection={<ChevronRight size="1rem" />}
          >
            {p.expanded ? "Close" : "View all"}
          </Button>
        )}
      </Group>
      <Layout>
        {toShow.map((resource, index) => (
          <ResourceItem
            key={`explorer-${index}-${resource.id}`}
            variant="explorer"
            resource={resource}
            openResource={async () => {
              await p.onSelectResource(resource);
            }}
          />
        ))}
      </Layout>
    </Stack>
  );
};
