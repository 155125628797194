import { Title } from "@mantine/core";

import { type FilterCategory, useExplorerSearch } from "./ExplorerSearch.store";
import { FilterSelector } from "./HubExplorerFilters";
import { resourceTypeTags as resourceTypes } from "@pillar/resources/fixtures";

export const ExplorerFiltersSidebar = () => {
  const store = useExplorerSearch();
  const tags = store.ops.fetchFilters.derived.dataOrNull ?? [];
  const filtersSpec = [
    { name: "Tags", key: "tags", options: tags, repositoryId: null },
    resourceTypes,
  ];

  const filters = store.state.query;

  return (
    <>
      <Title order={4} className="pb-2">
        Filter Resources
      </Title>
      <FilterSelector
        currentFilters={[
          ...(filters.tags ?? []),
          ...(filters.types ?? []),
          ...(filters.rating ?? []),
        ]}
        filterCategories={filtersSpec}
        setFilter={(
          category: FilterCategory,
          value: string,
          isChecked: boolean
        ) => {
          return store.sagas.onQueryChange({
            type: "filter",
            category,
            value,
            isChecked,
          });
        }}
      />
    </>
  );
};
