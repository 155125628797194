import { v3TrpcConfig, makeAgnosticV3ApiClient } from "@pillar/v3/rpc/client";
import { createTRPCReact } from "@trpc/react-query";

import { type V3InternalApiRouter } from "@pillar/v3/rpc/index";

export const v3ApiUrl = "/api/proxy";

export const platformApiFactory = createTRPCReact<V3InternalApiRouter>();

export const makeReactPlatformApiClient = (url: string) =>
  platformApiFactory.createClient(v3TrpcConfig(url));

export const platformApi = makeAgnosticV3ApiClient(v3ApiUrl);

/** A version of the v3 client that does not batch by default */
export const sequentialV3Api = makeAgnosticV3ApiClient(v3ApiUrl);
