import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { HubConfig } from "@pillar/config/hub/client/HubConfig";
import { HubRoutesV2, useHubParams } from "@pillar/hub/navigation";
import { InferCurrentLinkFromEnv } from "@pillar/links/hub/client/InferResourceLinkFromEnv";

import { PathwayRendererFlow } from "./PathwayRendererPage";

import { LoadingOverlay } from "@mantine/core";
import { Autologin } from "@pillar/v3/auth/hub/client/AutoLogin";
import { HubURLParams } from "@pillar/v3/client/urls/HubURLParams";
import { useHubURLParamsStore } from "@pillar/v3/client/urls/params.hub.store";
import { InteractionRecorder } from "@pillar/v3/interactions/hub/client/InteractionsRecorder";
import { SyncExplorerInteractionRecorder } from "@pillar/v3/repos/hub/client/SyncExplorerInteractionRecorder";

type Props = {
  repositoryPid: string;
  pathwayPid: string;
};

export const PathwayInExplorerRendererer: FC<Props> = (props) => {
  const router = useNavigate();
  const {
    derived: { allParamsToURLString },
  } = useHubURLParamsStore();

  return (
    <InteractionRecorder
      command={{
        type: "enter-res-in-explorer",
        session: {
          sessionType: "pathway",
          pathwayPid: props.pathwayPid,
          sessionId: "",
        },
      }}
    >
      <PathwayRendererFlow
        pid={props.pathwayPid}
        repositoryId={props.repositoryPid}
        goBack={() =>
          router({
            pathname: HubRoutesV2.explorer(props.repositoryPid),
            search: allParamsToURLString,
          })
        }
      />
    </InteractionRecorder>
  );
};

export const PathwayInExplorerPage: FC = () => {
  const params = useHubParams();

  const repositoryPid = params.explorerPid;
  const pathwayPid = params.pathwayPid;

  if (!(repositoryPid && pathwayPid)) return <LoadingOverlay />;

  return (
    <>
      <InferCurrentLinkFromEnv>
        <HubConfig pid={repositoryPid}>
          <HubURLParams>
            <Autologin pid={repositoryPid}>
              <SyncExplorerInteractionRecorder explorerPid={repositoryPid}>
                <PathwayInExplorerRendererer
                  repositoryPid={repositoryPid}
                  pathwayPid={pathwayPid}
                />
              </SyncExplorerInteractionRecorder>
            </Autologin>
          </HubURLParams>
        </HubConfig>
      </InferCurrentLinkFromEnv>
    </>
  );
};
