import { FC } from "react";
import { Center, Loader } from "@mantine/core";

import { HubConfig } from "@pillar/config/hub/client/HubConfig";
import { HubAuth } from "@pillar/auth/hub/client/HubAuth";
import { NotFound } from "@pillar/hub/client/NotFound";
import { useHubParams } from "@pillar/hub/navigation";
import { InferCurrentLinkFromEnv } from "@pillar/links/hub/client/InferResourceLinkFromEnv";

import { Form } from "./Form";
import { useHubForm } from "./hub.hooks";
import { FormLoader } from "./FormLoader";
import { useIsMobile } from "@pillar/ui/obsidian/layout/hooks";
import { InteractionRecorder } from "@pillar/v3/interactions/hub/client/InteractionsRecorder";
import { HubURLParams } from "@pillar/v3/client/urls/HubURLParams";

export const FormFromLink: FC<{
  pid: string;
  repositoryId?: string;

  goBack?: () => void;
}> = (p: { pid: string; repositoryId?: string; goBack?: () => void }) => {
  const { loading } = useIsMobile();

  const { query, submitMutation } = useHubForm({
    pid: p.pid,
  });

  if (loading || query.isLoading) {
    return <FormLoader />;
  }

  if (!query.data) return null;
  return <Form form={query.data} onSubmit={submitMutation} />;
};

export const FormFromLinkPage: FC = () => {
  const params = useHubParams();
  const pid = params.pid;

  if (!pid) {
    return <NotFound />;
  }

  return (
    <InferCurrentLinkFromEnv>
      <HubConfig pid={pid}>
        <HubURLParams>
          <HubAuth pid={pid}>
            <InteractionRecorder
              command={{
                type: "load-link",
                session: {
                  sessionType: "resource",
                  anyId: pid,
                  sessionId: "",
                },
              }}
            >
              <FormFromLink pid={pid} />
            </InteractionRecorder>
          </HubAuth>
        </HubURLParams>
      </HubConfig>
    </InferCurrentLinkFromEnv>
  );
};
