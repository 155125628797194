import { FC } from "react";
import {
  BackgroundImage,
  Button,
  Center,
  Flex,
  Overlay,
  Stack,
} from "@mantine/core";
import { Play } from "lucide-react";

import { ResourceTypeTag } from "@pillar/resources/types.legacy";

export const ResourcePreview: FC<{
  thumbnail?: string;
  title: string;
  type: ResourceTypeTag;
}> = (p) => {
  return (
    <BackgroundImage
      src={`${p.thumbnail}`}
      radius="md"
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
      }}
    >
      <Overlay zIndex={1} backgroundOpacity={0.5} blur={4} h="100%" radius="sm">
        <Center h="100%">
          <Stack align="center" style={{ overflow: "hidden" }}>
            {/* <Title
              c="white"
              style={{ textAlign: "center" }}
              order={2}
              w="100%"
              className="line-clamp-3"
            >
              {p.title +
                p.title +
                p.title +
                p.title +
                p.title +
                p.title +
                p.title +
                "hhh jjjhhj  jkjkkjljk klklkkl klkklj"}
            </Title> */}
            {p.type === ResourceTypeTag.video && (
              <Play fill={"white"} color={"white"} size={64} strokeWidth={1} />
            )}
            {p.type === ResourceTypeTag.document && (
              <Button component={Flex}>Open Document</Button>
            )}
          </Stack>
        </Center>
      </Overlay>
    </BackgroundImage>
  );
};
