import React, { FC } from "react";
import {
  Box,
  Center,
  Container,
  Flex,
  Loader,
  Stack,
  Title,
} from "@mantine/core";

import { ResourceListItem } from "@pillar/resources/hub/client/ResourceListItem";
import { AppPage } from "@pillar/ui/obsidian/layout";

import { usePathwayStore } from "./PathwayStore";
import { PathwayFeedback } from "../../../v3/pathways/hub/client/PathwayFeedback";
import {
  LockedModuleOverlay,
  ModuleRenderProps,
  PathwayModuleSection,
} from "./MobilePathwayHomeScreen";
import { ResourceMetadata } from "@pillar/v3/resources/hub/client/ResourceMetadataItems";

export const PathwayModule: FC<ModuleRenderProps> = ({
  step,
  steps,
  humanIndex,
  onSelectResource,
  unlocked,
  lockMessage,
  currentResourceId,
}) => {
  const shouldDisplay = steps.length > 1;
  return (
    <Box>
      {shouldDisplay && (
        <Title order={4} mb={4}>
          {/* @ts-ignore FIXME type should have name and description*/}
          {step?.name ?? `Section ${humanIndex}`}
        </Title>
      )}

      <Stack pos={"relative"}>
        {step.resources.map((resource) => {
          return (
            <ResourceListItem
              key={`list-${resource.id}`}
              resource={resource}
              variant="pathway"
              openResource={async () => {
                await onSelectResource(resource);
              }}
              isSelected={resource.id === currentResourceId}
            />
          );
        })}
        {!unlocked && (
          <LockedModuleOverlay showIcon>{lockMessage}</LockedModuleOverlay>
        )}
      </Stack>
    </Box>
  );
};

export const PathwayMetadata = () => {
  const { derived } = usePathwayStore();

  return derived.pathway ? (
    <Flex py="lg" w="100%">
      {/* TODO: refine the type */}
      <ResourceMetadata resource={derived.pathway as any} />
    </Flex>
  ) : null;
};

export const PathwayHomeScreen: FC<{ goBack?: () => void }> = (props) => {
  const store = usePathwayStore();

  return (
    <AppPage>
      <AppPage.Header
        actions={<PathwayFeedback buttonText="Rate Pathway" />}
        goBack={props.goBack}
      >
        {store.derived.pathway?.name}
      </AppPage.Header>
      <AppPage.Main>
        {!store.derived.pathway ? (
          <Center w="100%" h="100%">
            <Loader size="lg" />
          </Center>
        ) : (
          <Container size={"xl"} w={"100%"} maw={"60em"}>
            <PathwayMetadata />
            <PathwayModuleSection ModuleRender={PathwayModule} />
          </Container>
        )}
      </AppPage.Main>
    </AppPage>
  );
};
