import { useRef } from "react";
import { proxy, useSnapshot } from "valtio";

export type DeriveGet = <T extends object>(proxyObject: T) => T;

export type ClientStore = {
  state: Record<string, any>;
  derived?: Record<string, any>;
  sagas?: Record<string, any>;
  actions?: Record<string, any>;
  ops?: Record<string, any>;
};

export const useStore = <T extends ClientStore>({
  state,
  derived,
  ops,
  ...others
}: T) => {
  const masterProxy = useRef(
    proxy({
      state,
      derived,
      ops,
      ...others,
    })
  );

  const snap = useSnapshot(masterProxy.current);
  return snap as Required<T>;
};

export const useLocalStore = <T extends ClientStore>(fac: () => T) => {
  const ref = useRef(fac());
  return useStore(ref.current);
};
