import { proxy, ref } from "valtio";
import { derive } from "valtio/utils";

export type AsyncStates<P> =
  | {
      type: "idle";
    }
  | {
      type: "loading";
      data?: P;
    }
  | {
      type: "error";
      error: Error;
    }
  | {
      type: "success";
      data: P;
    };
export const makeAsync = <P extends any, T>(
  initialValue: (v: P) => Promise<T>
) => {
  const state = proxy<{ operation: AsyncStates<T> }>({
    operation: { type: "idle" },
  });

  const derived = derive({
    isLoading: (get) => get(state).operation.type === "loading",
    isIdle: (get) => get(state).operation.type === "idle",
    isSuccess: (get) => get(state).operation.type === "success",
    isError: (get) => get(state).operation.type === "error",
    dataOrNull: (get) => {
      const op = get(state).operation;
      if (op.type !== "success" && op.type !== "loading") return null;
      return op?.data ?? null;
    },
  });

  return {
    state,
    derived,
    run: async (v: P): Promise<T> => {
      try {
        state.operation = {
          type: "loading",
          data:
            state.operation?.type === "success"
              ? state.operation.data
              : undefined,
        };
        const result = await initialValue(v);
        state.operation = { type: "success", data: result };
        return result;
      } catch (e) {
        state.operation = { type: "error", error: e };
        throw e;
      }
    },
    reset: () => {
      state.operation = { type: "idle" };
    },
  };
};

export type AsyncOperation<I, O> = ReturnType<typeof makeAsync<I, O>>;
