import Axios from "axios";
import { FC, useRef } from "react";
import { Accept } from "react-dropzone";
import { proxy, ref, useSnapshot } from "valtio";

import { UploadURL } from "@pillar/storage/services/BlobStorageService";

import Dropzone from "./Dropzone";
import { ResourceFileType, UploadProgress } from "./FileUploader";

export class Uploader {
  state: {
    file: File | null;
    originUrl?: string;
    url: string;
    isUploading: boolean;
    progress: number;
    hasBeenEdited: boolean;
    shouldOnlyUploadIfEdited: boolean;
    disableChangeFile: boolean;
  } = {
    file: null,

    url: "",
    isUploading: false,
    progress: -1,
    hasBeenEdited: false,
    shouldOnlyUploadIfEdited: false,
    disableChangeFile: false,
  };

  getUploaderURL: (file: File) => Promise<UploadURL>;
  fileAccept: Accept;

  /**
   * Initialize the Uploader
   *
   * @param p.file: the initial file of this uploader, can be `null`
   * @param p.getUploaderUrl: the function that will get the url to upload the file to
   * @param p.fileAccept: the accepted file types (eg. image/png)
   * @param p.shouldOnlyUploadIfEdited: if this flag is true, the file will be uploaded only if it differs from the initial file
   */
  constructor(p: {
    file: File | null;
    originUrl?: string;
    getUploaderURL: (file: File) => Promise<UploadURL>;
    fileAccept: Accept;
    shouldOnlyUploadIfEdited?: boolean;
  }) {
    this.state.file = p.file === null ? p.file : (ref(p.file) as File);
    this.state.originUrl = p.originUrl;
    this.state.hasBeenEdited = false;
    this.getUploaderURL = p.getUploaderURL;
    this.fileAccept = p.fileAccept;
    this.state.shouldOnlyUploadIfEdited = p.shouldOnlyUploadIfEdited ?? false;
  }

  async uploadFileWithProgress(url: string, file: File) {
    return await Axios.put(url, file, {
      onUploadProgress: (progressEvent) => {
        if (progressEvent.progress !== undefined) {
          this.state.progress = progressEvent.progress;
        }
      },
    });
  }

  async uploadFile() {
    if (
      (this.state.shouldOnlyUploadIfEdited && this.state.hasBeenEdited) ||
      !this.state.shouldOnlyUploadIfEdited
    ) {
      if (this.state.file instanceof File) {
        this.state.isUploading = true;
        const url = await this.getUploaderURL(this.state.file);

        const upload = await this.uploadFileWithProgress(
          url.signedURL,
          this.state.file
        );
        this.state.isUploading = false;
        this.state.progress = 1;

        return url.objectURL;
      }
      // This is to signal the file has been removed
      return null;
    }
    this.state.isUploading = false;
    this.state.progress = 1;
    // This is to signal the file has been unchanged
    return undefined;
  }

  useUploaderProxy(): Uploader {
    return proxy(this);
  }
  getCurrentFile(): File | null {
    return this.state.file;
  }

  disableChangeFile() {
    this.state.disableChangeFile = true;
  }

  useUploaderInstance(proxy: Uploader) {
    return useSnapshot(useRef(proxy).current) as Uploader;
  }

  Field: FC = () => {
    // Used to update state
    const proxy = this.useUploaderProxy();

    // Used to read state
    const instance = this.useUploaderInstance(proxy);

    return (
      <>
        <Dropzone
          accept={this.fileAccept}
          onFileAccepted={(file) => {
            proxy.state.file = file === null ? file : ref(file);
            proxy.state.hasBeenEdited = true;
          }}
          initialUrl={instance.state.originUrl}
          initialFile={instance.state.file ?? undefined}
          disableChangeFile={instance.state.disableChangeFile}
        />
        <UploadProgress uploader={instance} type={ResourceFileType.Resource} />
      </>
    );
  };
}
