import { FC } from "react";
import { useDisclosure } from "@mantine/hooks";
import { Button, Center, Loader, Skeleton, Text } from "@mantine/core";

import { Navigation } from "@pillar/ui/obsidian/navigation";
import {
  AppPage,
  HEADER_HEIGHT,
  NAVBAR_WIDTH,
} from "@pillar/ui/obsidian/layout";

import { usePathwayStore } from "./PathwayStore";
import { PathwayResourceFeedback } from "./PathwayResourceFeedback";
import {
  MobilePathwayModule,
  PathwayModuleSection,
} from "./MobilePathwayHomeScreen";

import { PathwayCompletedModal } from "./MantinePathwayCompletedScreen";
import { ArrowRight, ChevronsLeft, ChevronsRight } from "lucide-react";
import { ResourceTypeTag } from "@pillar/resources/types.legacy";
import { PathwayActualContentRenderer } from "./PathwayActualContentRenderer";
import { FadeInContent } from "@pillar/v3/async/client/suspense.shim";
import { InteractionRecorder } from "@pillar/v3/interactions/hub/client/InteractionsRecorder";

export const PathwayResourceScreen: FC<{ resourceId: string }> = (p) => {
  const store = usePathwayStore();
  const [menuOpened, { toggle }] = useDisclosure(false);

  store.sagas.useOnResourceChange(p.resourceId);

  const isLoading =
    store.ops.fetchAssetInPathway.derived.isLoading ||
    store.ops.fetchAssetInPathwayInExplorer.derived.isLoading;
  const fullResource =
    store.ops.fetchAssetInPathway.derived.dataOrNull ??
    store.ops.fetchAssetInPathwayInExplorer.derived.dataOrNull;

  if (!store.derived.pathway) {
    <Center w="100%" h="100%">
      <Loader size="lg" />
    </Center>;
  }

  return (
    <AppPage showNavbar={menuOpened}>
      <AppPage.HeaderWithNavbarToggle
        navbarTrigger={
          <Button
            onClick={toggle}
            variant="subtle"
            leftSection={menuOpened ? <ChevronsLeft /> : <ChevronsRight />}
          >
            {menuOpened ? "Close" : "Open"} Table of Contents
          </Button>
        }
        actions={
          <Navigation
            onNext={store.actions.transitionToNextResource}
            onPrevious={store.actions.transitionToPreviousResource}
          >
            {store.derived.currentResourceIndexInPathway &&
              store.derived.totalResources && (
                <Text fw="bold" px={4}>
                  {store.derived.currentResourceIndexInPathway} of{" "}
                  {store.derived.totalResources}
                </Text>
              )}
          </Navigation>
        }
        goBack={store.actions.goToHome}
      >
        {store.derived.pathway?.name}
      </AppPage.HeaderWithNavbarToggle>
      <AppPage.Navbar>
        <PathwayModuleSection ModuleRender={MobilePathwayModule} />
      </AppPage.Navbar>
      <AppPage.Main
        bg={
          store.state.currentResource?.types?.includes(
            ResourceTypeTag.service
          ) ||
          store.state.currentResource?.types?.includes(ResourceTypeTag.form)
            ? undefined
            : "gray.2"
        }
        pt={
          store.state.currentResource?.types?.includes(ResourceTypeTag.form)
            ? HEADER_HEIGHT
            : 60
        }
        pl={menuOpened ? NAVBAR_WIDTH : 0}
        pr={0}
        pb={0}
      >
        {fullResource && (
          <FadeInContent
            isLoading={isLoading}
            style={{
              minHeight: "90vh",
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
            }}
          >
            <InteractionRecorder
              command={{
                type: "enter-res-in-pathway",
                parent: store.state.pathwayPid ?? "",
                session: {
                  sessionType: "resource",
                  anyId: fullResource.id,
                  sessionId: "",
                },
              }}
              regenOn={fullResource.id}
            >
              <PathwayActualContentRenderer
                resource={fullResource}
                actions={
                  <>
                    <PathwayResourceFeedback
                      resourceId={fullResource.id}
                      resourceType={fullResource.types?.[0]}
                    />
                  </>
                }
                successActions={
                  <Button
                    variant="primary"
                    onClick={store.actions.transitionToNextResource}
                    rightSection={<ArrowRight />}
                  >
                    Next
                  </Button>
                }
              />
            </InteractionRecorder>
          </FadeInContent>
        )}
        <PathwayCompletedModal
          opened={store.state.isSuccessScreenOpen}
          onClose={() => store.actions.closeSuccessModal()}
        />
      </AppPage.Main>
    </AppPage>
  );
};
