import { ActionIcon, Button, Group } from "@mantine/core";
import { ArrowLeft, ArrowRight, ChevronLeft, ChevronRight } from "lucide-react";
import { FC, PropsWithChildren } from "react";

export type NavigationProps = PropsWithChildren<{
  onNext: () => Promise<void>;
  onPrevious: () => Promise<void>;
}>;

export const MobileNavigation: FC<NavigationProps> = (p) => {
  return (
    <Group gap={"0.25rem"} wrap="nowrap" className="only:ml-auto">
      <ActionIcon
        variant="subtle"
        onClick={p.onPrevious}
        w={"4rem"}
        aria-label="Go to Previous"
      >
        <ArrowLeft />
      </ActionIcon>
      {p.children}
      <ActionIcon
        variant="subtle"
        onClick={p.onNext}
        w={"4rem"}
        aria-label="Go to Next"
      >
        <ArrowRight />
      </ActionIcon>
    </Group>
  );
};

export const Navigation: FC<NavigationProps> = (p) => {
  return (
    <Group gap={"0.25rem"} wrap="nowrap">
      <Button
        variant="subtle"
        onClick={p.onPrevious}
        leftSection={<ArrowLeft />}
      >
        Previous
      </Button>
      {p.children}
      <Button variant="subtle" onClick={p.onNext} rightSection={<ArrowRight />}>
        Next
      </Button>
    </Group>
  );
};
