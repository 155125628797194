import { Grid, Select, Stack } from "@mantine/core";
import { FC, ReactNode } from "react";

export enum LayoutType {
  grid = "grid",
  list = "list",
}
export const LayoutSelector: FC<{
  onChange: (type: LayoutType) => void;
  currentType: LayoutType;
}> = (p) => {
  return (
    <Select
      data={[
        { value: LayoutType.grid, label: "Grid" },
        { value: LayoutType.list, label: "List" },
      ]}
      value={p.currentType}
      onChange={(_value) => {
        if (_value !== null) p.onChange(_value as LayoutType);
      }}
      size="md"
      w="120px"
    />
  );
};

export const ListLayout: FC<{ children: ReactNode[] }> = (p) => {
  return <Stack gap="xs">{p.children}</Stack>;
};

export const GridLayout: FC<{ children: ReactNode[] }> = (p) => {
  return (
    <Grid>
      {p.children?.map((child, index) => (
        <Grid.Col
          key={`grid-component-${
            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
            index
          }`}
          span={{ base: 12, sm: 6, md: 6, lg: 3, xl: 3 }}
        >
          {child}
        </Grid.Col>
      ))}
    </Grid>
  );
};
