import { ResourceTypeTag } from "@pillar/resources/types.legacy";
import { ResourceMetadataCard } from "@pillar/singleResource/hub/client/ResourceRenderer";

import { VideoRenderer } from "@pillar/resources/hub/client/ResourceVideoRenderer";
import type { FC } from "react";
import type { AnyResourceRendererProps } from "../types";
import { Box, Container, Flex } from "@mantine/core";
import { PDFRendererBody } from "@pillar/v3/files/hub/client/PDFRendererBody";

export const AgnosticFileRenderer: FC<AnyResourceRendererProps> = (p) => {
  return (
    <>
      {p.resource.types.includes(ResourceTypeTag.document) && (
        <PDFRendererBody {...p} />
      )}
      {p.resource.types.includes(ResourceTypeTag.video) && (
        <Flex direction="column" w="100%" h="100%" flex={1}>
          <VideoRenderer
            href={p.resource.url}
            poster={p.resource.thumbnail}
            title={p.resource.name}
            send={console.debug}
          />
          <ResourceMetadataCard resource={p.resource} actions={p.actions} />
        </Flex>
      )}
    </>
  );
};
