import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Component, lazy, Suspense } from "react";
import { hubParam, HubRoutes, HubRoutesV2 } from "../navigation";
import { LoadingBlock } from "@pillar/ui/obsidian/loading";
import { PidRouter } from "@pillar/links/hub/client/PidRouter";
import { NotFound, PageNotFound } from "./NotFound";
import { SingleResourceRendererPage } from "@pillar/assets/hub/client/SingleResourceRenderer";
import { ExplorerRendererPage } from "@pillar/explorer/hub/client/HubExplorer";
import { PathwayRendererPage } from "@pillar/pathways/hub/client/PathwayRendererPage";

import { PathwayInExplorerPage } from "@pillar/pathways/hub/client/PathwayInExplorer";
import { ServiceFromLinkPage } from "@pillar/services/hub/client/ServiceRendererPage";
// import { ServiceInExplorerPage } from "@pillar/services/hub/client/ServiceInExplorerPage";

import { FormFromLinkPage } from "@pillar/v3/forms/hub/client/FormFromLinkPage";
import { RedirectToRenderer } from "@pillar/v3/invite-links/hub/client/RedirectToRenderer";
import { DEFAULT_THEME, MantineProvider } from "@mantine/core";
import { FileInExplorerPage } from "@pillar/v3/files/hub/client/FileInExplorer";
import { ServiceInExplorerPage } from "@pillar/v3/services/hub/client/ServiceInExplorer";

const ShareResourcePage = lazy(() =>
  import("@pillar/assets/hub/client/SingleResourceRenderer").then((mod) => ({
    default: mod.SingleResourceRendererPage,
  }))
);

const HubPathwayPage = lazy(() =>
  import("@pillar/pathways/hub/client/PathwayRendererPage").then((mod) => ({
    default: mod.PathwayRendererPage,
  }))
);

export const InnerRouter: React.FC = () => (
  <Suspense
    fallback={
      <MantineProvider defaultColorScheme={"light"} theme={DEFAULT_THEME}>
        <LoadingBlock />
      </MantineProvider>
    }
  >
    <Routes>
      {/* Deprecated routes. kept here for backward-compatibility */}
      <Route
        path={HubRoutes.resource.deprecated_shared()}
        Component={ShareResourcePage}
      />
      <Route
        path={HubRoutes.pathway.deprecated_public()}
        Component={HubPathwayPage}
      />
      <Route
        path={HubRoutesV2.staticAsset(hubParam("pid"))}
        Component={SingleResourceRendererPage}
      />
      <Route
        path={HubRoutesV2.service(hubParam("pid"))}
        Component={ServiceFromLinkPage}
      />
      {/** New Route */}
      <Route
        path={HubRoutesV2.pathway(hubParam("pid"))}
        Component={PathwayRendererPage}
      />
      <Route path={"/form/:pid"} Component={FormFromLinkPage} />
      {/* FIXME: use an AC for documents */}
      <Route path={"/doc/:pid"} Component={ServiceFromLinkPage} />
      <Route path={"/file/:pid"} Component={SingleResourceRendererPage} />
      <Route
        path={HubRoutesV2.pathwayInExplorer(
          hubParam("explorerPid"),
          hubParam("pathwayPid")
        )}
        Component={PathwayInExplorerPage}
      />
      <Route
        path={HubRoutesV2.serviceInExplorer(
          hubParam("explorerPid"),
          hubParam("resourcePid")
        )}
        Component={ServiceInExplorerPage}
      />
      <Route
        path={HubRoutesV2.explorer(hubParam("pid"))}
        Component={ExplorerRendererPage}
      />
      <Route
        path={HubRoutesV2.assetInExplorer(
          hubParam("explorerPid"),
          hubParam("resourcePid")
        )}
        Component={FileInExplorerPage}
      />
      <Route path=":pid" Component={RedirectToRenderer} />
      <Route path={HubRoutesV2.notFound()} Component={PageNotFound} />
      <Route path="*" Component={PageNotFound} />
    </Routes>
  </Suspense>
);
export const HubRouter: React.FC = () => (
  <BrowserRouter>
    <InnerRouter />
  </BrowserRouter>
);
