import { FC, useEffect } from "react";
import { useSingleResourceStore } from "./SingleResource.store";

import { HubAuth } from "@pillar/auth/hub/client/HubAuth";
import { HubConfig } from "@pillar/config/hub/client/HubConfig";
import { useHubParams } from "@pillar/hub/navigation";
import { InferCurrentLinkFromEnv } from "@pillar/links/hub/client/InferResourceLinkFromEnv";

import { LoadingOverlay } from "@mantine/core";
import { useIsMobile } from "@pillar/ui/obsidian/layout/hooks";
import { PublicResourceRendererMaster } from "./PublicResourceRendererMaster";
import { InteractionRecorder } from "@pillar/v3/interactions/hub/client/InteractionsRecorder";
import { HubURLParams } from "@pillar/v3/client/urls/HubURLParams";

export const SingleResourceFlow: FC<{ pid: string }> = (props: {
  pid: string;
}) => {
  const store = useSingleResourceStore();

  useEffect(() => {
    store.sagas.init({
      pid: props.pid,
      explorerPid: undefined,
    });
  }, []);

  const data = store.ops.fetchAsset.derived.dataOrNull;

  const { loading, isMobile } = useIsMobile();

  if (loading || !data) return <div>Loading...</div>;

  return <PublicResourceRendererMaster isMobile={isMobile} data={data} />;
};

export const SingleResourceRendererPage: FC = () => {
  const pid = useHubParams().pid;

  if (!pid) {
    return <LoadingOverlay />;
  }

  return (
    <InferCurrentLinkFromEnv>
      <HubConfig pid={pid}>
        <HubURLParams>
          <HubAuth pid={pid}>
            <InteractionRecorder
              command={{
                type: "load-link",
                session: {
                  sessionType: "resource",
                  anyId: pid,
                  sessionId: "",
                },
              }}
            >
              <SingleResourceFlow pid={pid} />
            </InteractionRecorder>
          </HubAuth>
        </HubURLParams>
      </HubConfig>
    </InferCurrentLinkFromEnv>
  );
};
