import { FC, PropsWithChildren, ReactNode, useEffect } from "react";

import { useHubAuthStore } from "@pillar/auth/hub/client/HubAuth.store";
import { Loading, LoadingFullPage } from "@pillar/ui/obsidian/loading";
import { HubTrackLogin } from "./TrackLogin";
import { Box } from "@mantine/core";
import { useHubURLParamsStore } from "@pillar/v3/client/urls/params.hub.store";

export const useHubAuthAnonymousLogin = () => {};

export const HubAuthAnonymousLogin = () => {};

export const useHubAuthEmailTracking = () => {};

export const HubAuthEmailTracking = () => {};

/** @deprecated */
export const useHubAuth = (pid: string) => {
  const memberAuth = useHubAuthStore();

  useEffect(() => {
    memberAuth.sagas.checkNextAuthAction(pid);
  }, []);

  return memberAuth;
};

export const HubAuth: FC<
  PropsWithChildren<{ pid: string; previewLayer?: ReactNode }>
> = (props) => {
  const authStore = useHubAuth(props.pid);
  let fragment: ReactNode = <Loading />;
  const urlParamsStore = useHubURLParamsStore();

  useEffect(() => {
    if (
      authStore.state.method !== "tracking" &&
      urlParamsStore.state.auth?.skipLogin &&
      urlParamsStore.state.auth?.userID !== undefined
    ) {
      authStore.ops.trackLogin.run({
        pid: props.pid,
        email: urlParamsStore.state.auth.userID,
        // TODO pass the organization token
      });
    }
  }, [urlParamsStore.state.auth]);

  if (authStore.state.nextState === "render") {
    fragment = <>{props.children}</>;
  } else if (authStore.state.nextState === "login") {
    fragment = authStore.state.method ? (
      authStore.state.method === "tracking" &&
      urlParamsStore.state.hasLoadedParams &&
      !urlParamsStore.state.auth ? (
        <HubTrackLogin pid={props.pid} />
      ) : null
    ) : null;
  } else if (authStore.state.nextState === "idle") {
    fragment = <LoadingFullPage />;
  }

  if (props.previewLayer) {
    //className="h-full w-full z-0 relative top-0 left-0 right-0 bottom-0"
    <Box>
      {props.previewLayer ?? null}
      {/* "h-full w-full z-0 absolute backdrop-blur-sm bg-white/30 top-0 left-0 right-0 bottom-0" */}
      <Box>{fragment}</Box>
    </Box>;
  }
  return fragment;
};
