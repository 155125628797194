import { Optional } from "./types/nullable";
import levenshtein from "fast-levenshtein";

export const hasEmoji = (str: string) => {
  return /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g.test(
    str
  );
};

export const urlPathWithLeadingSlash = (url: string) => {
  if (url.startsWith("/")) {
    return url;
  }

  return `/${url}`;
};

export const validString = (v: Optional<string>): v is string =>
  typeof v === "string" && v.length > 0;
export const slugifyString = (s: string) =>
  s
    .toString()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .trim()
    .replace(/\s+/g, "-")
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, "-");

export const ellipsify = (
  str: string | null | undefined,
  maxLength: number
) => {
  if (!str) return "";
  if (str.length <= maxLength) return str;
  return str.slice(0, maxLength) + "...";
};

/**
 * Computes the Levenshtein Ratio between two string.
 * p = (1 - l/m) × 100
 * Where
 *      - l: the Levenshtein distance, ie the number of edits to go from string s1 to string s2,
 *      - m: the length of the longest of the two words
 * @param s1 The first string to compare
 * @param s2 The second string to compare
 * @returns the Levenshtein ratio
 */
export const getLevenshteinRatio = (s1: string, s2: string) => {
  const levenshteinDistance = levenshtein.get(s1, s2);
  const maxLength = Math.max(s1.length, s2.length);
  const levenshteinRatio =
    maxLength === 0 ? 1 : 1 - levenshteinDistance / maxLength;

  return levenshteinRatio;
};

export function upperFirst(word: string) {
  if (word.length === 0) {
    return word;
  }
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function stringTemplate(
  subject: string,
  params: Record<string, string>
) {
  let result = subject.slice();
  Object.keys(params).forEach((k) => {
    result = result.replaceAll(`{${k}}`, params[k]);
  });

  return result;
}
