import { Alert, List } from "@mantine/core";
import {
  ErrorListProps,
  FormContextType,
  RJSFSchema,
  StrictRJSFSchema,
  TranslatableString,
} from "@rjsf/utils";
import { IconZoomExclamation } from "@tabler/icons-react";

/** The `ErrorList` component is the template that renders the all the errors associated with the fields in the `Form`
 *
 * @param props - The `ErrorListProps` for this component
 */
export default function ErrorList<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>({ errors, registry }: ErrorListProps<T, S, F>) {
  const { rootSchema, translateString } = registry;

  return (
    <Alert
      color="orange"
      title={translateString(TranslatableString.ErrorsLabel)}
      icon={<IconZoomExclamation />}
      mb={16}
    >
      Please complete required fields.
      <List size="sm">
        {errors.map((error, index) => {
          // For some reason, the fieldKey is prefixed with a `.` once the form is dirty again after validation
          // This allows to retrieve the field label regardless of that prefix
          const fieldLabel = (error?.property ?? "").replace(".", "");
          return (
            // <List.Item key={`error-${index}`}>
            //   {error.message}:{" "}
            //   {/* @ts-ignore: for some reason `properties[fieldName]` has type false? */}
            //   {rootSchema.properties?.[fieldLabel]?.title}
            // </List.Item>
            null
          );
        })}
      </List>
    </Alert>
  );
}
