import { FC } from "react";
import {
  AspectRatio,
  Box,
  Button,
  Card,
  Center,
  Flex,
  Group,
  Image,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import {
  ChevronRight,
  ListTodo,
  FileImage,
  PlayCircle,
  CheckCircle2,
  ScrollText,
  ExternalLink,
} from "lucide-react";

import { ResourceTypeTag } from "@pillar/resources/types.legacy";
import { Tags } from "./ResourceTags";
import { CompactResourceAggregateWithInteractions } from "@pillar/pathways/types";

export type ItemProps = {
  resource: CompactResourceAggregateWithInteractions;
  openResource: () => void;
  variant?: "pathway" | "explorer";
  compact?: boolean;
  isSelected?: boolean;
};

// TODO rename file to ResourceItem
export const ResourceListItem: FC<ItemProps> = ({
  resource,
  openResource,
  variant = "explorer",
  compact = false,
  isSelected = false,
  ...props
}) => {
  const type = resource.types?.[0];
  const theme = useMantineTheme();
  return (
    <Card
      withBorder={variant === "explorer" || isSelected}
      radius="sm"
      h={compact ? "5rem" : "6rem"}
      onClick={openResource}
      p={compact ? "0.25rem" : "md"}
      // TODO don't hardcode this color
      bg={variant === "pathway" ? "#f5f6f7" : undefined}
      style={{
        cursor: "pointer",
        borderColor: isSelected ? theme.colors.primary[5] : undefined,
      }}
      {...props}
    >
      <Flex
        h="100%"
        justify="space-between"
        px={"0.25rem"}
        align={"center"}
        gap={"1em"}
      >
        <AspectRatio ratio={3 / 2} w={"6rem"}>
          <ResourceThumbnail
            isSmall
            thumbnail={resource.thumbnail}
            type={type}
          />
        </AspectRatio>
        <Stack style={{ flex: 1, overflow: "hidden" }} gap={"0.25rem"}>
          <Text truncate="end">{resource.name}</Text>
          <Tags type={type} tags={resource.tags} maxTags={compact ? 2 : 4} />
        </Stack>
        <Group gap={compact ? "0.1rem" : "xl"}>
          {variant === "pathway" && resource.viewed && <CompleteBadge />}
          {compact ? (
            <Button variant="subtle" onClick={openResource} p={0}>
              <ChevronRight />
            </Button>
          ) : (
            <Button
              onClick={openResource}
              variant={resource.viewed ? "outline" : "filled"}
            >
              View
            </Button>
          )}
        </Group>
      </Flex>
    </Card>
  );
};

export const ResourceGridItem: FC<ItemProps> = ({
  resource,
  openResource,
  isSelected = false,
  variant = "explorer",
  compact = false,
  ...props
}) => {
  const type = resource.types?.[0];
  return (
    <Card withBorder padding={6} radius="sm" onClick={openResource} {...props}>
      <AspectRatio ratio={3 / 2}>
        <ResourceThumbnail thumbnail={resource.thumbnail} type={type} />
      </AspectRatio>
      <Stack gap={"md"} pt={"0.5rem"}>
        <Stack gap={"0.25rem"} px="0.25rem">
          <Text truncate="end">{resource.name}</Text>
          <Tags type={type} tags={resource.tags} maxTags={2} />
        </Stack>
        <Button onClick={openResource}>View</Button>
      </Stack>
    </Card>
  );
};

export const ResourceThumbnail: FC<{
  thumbnail: string;
  type: string;
  isSmall?: boolean;
}> = (p) => {
  return p.thumbnail ? (
    <Image src={p.thumbnail} radius="sm" fit="cover" alt="Resource image" />
  ) : (
    <Box bg="gray.3" className="rounded">
      <Center h="100%">
        {p.type === ResourceTypeTag.video ? (
          <PlayCircle size={p.isSmall ? "1.5em" : "3em"} />
        ) : p.type === ResourceTypeTag.pathway ? (
          <ListTodo size={p.isSmall ? "1.5em" : "3em"} />
        ) : p.type === ResourceTypeTag.service ? (
          <ExternalLink size={p.isSmall ? "1.5em" : "3em"} />
        ) : p.type === ResourceTypeTag.form ? (
          <ScrollText size={p.isSmall ? "1.5em" : "3em"} />
        ) : (
          <FileImage size={p.isSmall ? "1.5em" : "3em"} />
        )}
      </Center>
    </Box>
  );
};

export const CompleteBadge: FC = () => {
  return (
    <Group gap="xs" c={"green.7"}>
      <CheckCircle2 color="currentColor" size="1.8rem" />
    </Group>
  );
};
