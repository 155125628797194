import { Box, Container, Flex, Grid, Image } from "@mantine/core";
import { useSingleResourceStore } from "@pillar/assets/hub/client/SingleResource.store";
import { ResourceTypeTag } from "@pillar/resources/types.legacy";
import { AppEventDomain, AppEventType } from "@pillar/events/types";
import { AnyResourceRendererProps } from "@pillar/singleResource/hub/types";
import { FC } from "react";

import {
  PreviewServiceRendererType,
  ServiceMetadata,
} from "./MobilePreviewServiceRenderer";
import { validString } from "@pillar/std/strings";
import { serviceStore } from "./Service.store";

export const PreviewServiceRenderer: React.FC<PreviewServiceRendererType> = (
  p
) => {
  const haveImage = validString(p.poster);
  return (
    <Container
      size={"xl"}
      flex={1}
      // styles={{
      //   root: {
      //     alignItems: "center",
      //     justifyContent: "center",
      //   },
      // }}
    >
      <Grid
        justify={haveImage ? "center" : "left"}
        align={haveImage ? "center" : "left"}
        gutter={{ base: "2rem", md: "5rem" }}
        mt={{ base: "xs", md: haveImage ? "1em" : "8em" }}
        ml={{ base: "1em", md: haveImage ? "4em" : "1em" }}
        mr={{ base: "1em", md: haveImage ? "3em" : "1em" }}
        miw={haveImage ? undefined : { base: "1em", md: "52em" }}
      >
        <Grid.Col span={haveImage ? 6 : { base: 12, md: 8 }}>
          <ServiceMetadata {...p} />
        </Grid.Col>
        {haveImage ? (
          <Grid.Col span={6}>
            <Image py={48} w="100%" mah="85vh" src={p.poster} />
          </Grid.Col>
        ) : null}
      </Grid>
    </Container>
  );
};

export const ServiceRendererOrNull: FC<AnyResourceRendererProps> = (
  p: AnyResourceRendererProps
) => {
  return p.resource.types.includes(ResourceTypeTag.service) ? (
    <ConnectedPreviewServiceRenderer
      id={p.resource.id}
      description={p.resource.description}
      poster={p.resource.thumbnail}
      // thumbnail={p.resource.thumbnail}
      logo={p.resource.logoUrl}
      tags={p.resource.tags}
      title={p.resource.name}
      actions={p.actions}
      meta={p.resource.meta}
    />
  ) : null;
};
export const ConnectedPreviewServiceRenderer: React.FC<
  Omit<PreviewServiceRendererType, "onOutboundClick">
> = (p) => {
  const store = useSingleResourceStore();

  return (
    <PreviewServiceRenderer
      onOutboundClick={() => {
        serviceStore.recordClickEvent();
      }}
      {...p}
    />
  );
};
