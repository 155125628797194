import { ResourceRendererType } from "@pillar/links/types";
import { useParams } from "react-router-dom";

export const HubRoutes = {
  resource: {
    deprecated_shared: () => `/:organizationId/share/pub/:inviteLinkPid`,
  },
  pathway: {
    deprecated_public: () =>
      `/:organizationId/repository/pub/:repositoryPid/pathway/:pathwayPid`,
  },
};

export type HubParams =
  | "pid"
  | "resourceId"
  | "resourcePid"
  | "pathwayPid"
  | "explorerPid";

export type HubRendererPrefix =
  | ResourceRendererType.asset
  | ResourceRendererType.explorer
  | ResourceRendererType.form
  | ResourceRendererType.pathway
  | ResourceRendererType.service;

export const rendererPrefix = (s: HubRendererPrefix): HubRendererPrefix => s;
export type RouterHubParams = `:${HubParams}`;

export const HubRoutesV2 = {
  // unique targets
  unknownResource: (pid: string) => `/${pid}`,
  staticAsset: (pid: string) =>
    `/${rendererPrefix(ResourceRendererType.asset)}/${pid}`,
  explorer: (pid: string) =>
    `/${rendererPrefix(ResourceRendererType.explorer)}/${pid}`,
  // composite targets
  assetInExplorer: (explorerPid: string, resourcePid: string) =>
    `/${rendererPrefix(
      ResourceRendererType.explorer
    )}/${explorerPid}/asset/${resourcePid}`,
  serviceInExplorer: (explorerPid: string, resourcePid: string) =>
    `/${rendererPrefix(
      ResourceRendererType.explorer
    )}/${explorerPid}/service/${resourcePid}`,
  pathwayInExplorer: (explorerPid: string, pathwayPid: string) =>
    `/${rendererPrefix(
      ResourceRendererType.explorer
    )}/${explorerPid}/pathway/${pathwayPid}`,
  resourceInPathwayInExplorer: (
    explorerPid: string,
    pathwayPid: string,
    resourceId: string
  ) =>
    `/${rendererPrefix(
      ResourceRendererType.explorer
    )}/${explorerPid}/pathway/${pathwayPid}/resource/${resourceId}`,

  pathway: (pid: string) =>
    `/${rendererPrefix(ResourceRendererType.pathway)}/${pid}`,
  service: (pid: string) =>
    `/${rendererPrefix(ResourceRendererType.service)}/${pid}`,
  form: (pid: string) => `/form/${pid}`,
  resourceInPathway: (pid: string, resourceId: string) =>
    `/${rendererPrefix(
      ResourceRendererType.pathway
    )}/${pid}/resource/${resourceId}`,

  //error page
  notFound: () => "/not-found",
};

export const hubParam = <S extends HubParams>(param: S): `:${S}` =>
  ((":" as const) + param) as `:${S}`;

export const useHubParams = (): Partial<Record<HubParams, string>> => {
  return useParams<Record<HubParams, string>>();
};
