import { Checkbox, Spoiler, Stack, Text } from "@mantine/core";
import { MatchingFiltersAggregate } from "@pillar/taxonomy/types";
import { FilterCategory } from "./ExplorerSearch.store";

export type FilterSelectorProps = {
  currentFilters: (string | number)[];
  filterCategories: MatchingFiltersAggregate[];
  setFilter: (
    category: FilterCategory,
    label: string,
    isChecked: boolean
  ) => void;
};

export const FilterSelector = (p: FilterSelectorProps) => {
  return (
    <Stack gap="md">
      {p.filterCategories.map((category) => {
        return (
          <Stack gap="xs" key={category.key}>
            <Text fw={500}>
              {category.name === "Tags" ? "Focus Areas" : category.name}
            </Text>
            <Spoiler maxHeight={300} showLabel="Expand +" hideLabel="Hide -">
              <Stack gap="xs" key={category.key}>
                {category.options.map((filter) => {
                  const isChecked = p.currentFilters?.includes(filter.value);
                  return (
                    <Checkbox
                      key={`select-filter-${filter.value}`}
                      radius={"sm"}
                      checked={isChecked}
                      label={filter.label}
                      onChange={() => {
                        p.setFilter(
                          category.key === "resourceType" ? "types" : "tags",
                          filter.value,
                          !isChecked
                        );
                      }}
                    />
                  );
                })}
              </Stack>
            </Spoiler>
          </Stack>
        );
      })}
    </Stack>
  );
};
