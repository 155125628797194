import { FC } from "react";
import { Stack, Text } from "@mantine/core";

import { Spoiler } from "@pillar/ui/obsidian/layout/spoiler";

import { Tags } from "./ResourceTags";
import { Legacy_ResourceType } from "../../resources.types";

export interface ResourceMetadata {
  name: string;
  description?: string;
  tags: string[];
  types: Legacy_ResourceType[];
  authorName?: string;
}

export const ResourceAuthorName: FC<{
  resource: ResourceMetadata;
}> = (p) => {
  return Boolean(p.resource.authorName) ? (
    <Text fw={"bold"}>{p.resource.authorName}</Text>
  ) : null;
};

export const ResourceTags: FC<{
  resource: ResourceMetadata;
  maxTags?: number;
}> = (p) => {
  return p.resource.types?.length > 0 ? (
    <Tags
      tags={p.resource.tags}
      type={p.resource.types?.[0]}
      wrap={"wrap"}
      maxTags={p.maxTags ?? 100}
    />
  ) : null;
};

export const ResourceDescription: FC<{
  resource: ResourceMetadata;
  compact?: boolean;
}> = (p) => {
  return Boolean(p.resource.description) ? (
    p.compact ? (
      <Spoiler hideLabel="Close -" showLabel="Expand +" maxLines={2}>
        <Text style={{ whiteSpace: "pre-wrap" }}>{p.resource.description}</Text>
      </Spoiler>
    ) : (
      <Text style={{ whiteSpace: "pre-wrap" }}>{p.resource.description}</Text>
    )
  ) : null;
};

export const ResourceMetadata: FC<{
  resource: ResourceMetadata;
  compact?: boolean;
  maxTags?: number;
}> = (p) => {
  return (
    <Stack gap="0.5rem">
      <ResourceAuthorName resource={p.resource} />
      <ResourceTags {...p} />
      <ResourceDescription {...p} />
    </Stack>
  );
};
