import { FC, PropsWithChildren } from "react";
import { Drawer, DrawerProps } from "@mantine/core";

export const BottomDrawer: FC<PropsWithChildren<DrawerProps>> = (p) => {
  const { children, onClose, opened, ...drawerProps } = p;
  return (
    <Drawer
      position="bottom"
      size="75%"
      radius={"1% 1% 0 0"}
      onClose={onClose}
      opened={opened}
      {...drawerProps}
    >
      {p.children}
    </Drawer>
  );
};
