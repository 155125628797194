import { HubAuthStore } from "@pillar/auth/hub/client/HubAuth.store";
import { makeAsync } from "@pillar/client/async";
import { type ClientStore, useStore } from "@pillar/client/store";
import { ResourceTypeTag } from "@pillar/resources/types.legacy";
import type {
  APIHubGetExplorer,
  APIHubGetSuggestedResources,
  APISearchPublishedResources,
} from "@pillar/explorer/types";
import { hubApi } from "@pillar/hub/api/client";
import { HubRoutesV2 } from "@pillar/hub/navigation";
import type { CompactResourceAggregateWithInteractions } from "@pillar/pathways/types";
import { withHubToken } from "@pillar/v3/auth/hub/auth.hub.client";
import { getImperativeHubV3Auth } from "@pillar/v3/auth/hub/client/auth.hub.store";
import { exponentialBackoffRetry } from "@pillar/v3/client/client-operations.service";
import { HubInteractionStore } from "@pillar/v3/interactions/hub/client/interaction.store";
import { type Legacy_ResourceType } from "@pillar/v3/resources/resources.types";
import { v3HubApi } from "@pillar/v3/rpc/client.hub";
import { validString } from "@pillar/v3/stdlib/string";
import { proxy } from "valtio";
import { derive } from "valtio/utils";
import { debounce } from "@pillar/std/fns";

export type FilterCategory = "tags" | "types" | "rating";
export type ExplorerSectionName = "Pathways" | "Services" | "Content" | null;

type Query = {
  search?: string;
  types?: string[];
  tags?: string[];
  rating?: number[];
};

const supportedTypes = [
  ResourceTypeTag.document,
  ResourceTypeTag.video,
  ResourceTypeTag.pathway,
  ResourceTypeTag.service,
];
type State = {
  repoPid: string | null;
  isLoading: boolean;
  isError: boolean;
  noResults: boolean;
  results: CompactResourceAggregateWithInteractions[];
  query: Query;
  isGrid: boolean;
  expandedSection: ExplorerSectionName | null;
};
const state = proxy<State>({
  repoPid: null,
  isLoading: false,
  isError: false,
  noResults: false,
  results: [],
  query: {},
  isGrid: true,
  expandedSection: null,
});

const ops = {
  fetchSuggestedResources: makeAsync((input: APIHubGetSuggestedResources) => {
    const sessions = HubInteractionStore.currentSession?.sessions;
    if (!sessions) return Promise.resolve([]);
    return exponentialBackoffRetry({
      fn: () => {
        return v3HubApi.v3.hub.resource.suggested.query(
          withHubToken(input.token, {
            resourceTypes: input.types as unknown as
              | Legacy_ResourceType[]
              | null,
            sessions: sessions,
          }),
        );
      },
    });
  }),
  search: makeAsync((input: APISearchPublishedResources) => {
    const sessions = HubInteractionStore.currentSession?.sessions;
    if (!sessions) return Promise.resolve([]);

    return v3HubApi.v3.hub.resource.search.query(
      withHubToken(input.token, {
        tags: input.tags,
        query: input.query,
        resourceTypes: input.types as Legacy_ResourceType[] | null,
        sessions: sessions,
      })
    );
  }),

  fetchFilters: makeAsync((ids: APIHubGetExplorer) => {
    const sessions = HubInteractionStore.currentSession?.sessions;

    if (!sessions) return Promise.resolve([]);

    return exponentialBackoffRetry({
      fn: () =>
        v3HubApi.v3.hub.taxonomy.listTags.query(
          withHubToken(ids.token, {
            sessions: sessions,
          })
        ),
    });
  }),

  navigateToService:
    (repoId: string, router: (path: string) => void) =>
    async (resource: CompactResourceAggregateWithInteractions) => {
      router(HubRoutesV2.serviceInExplorer(repoId, resource?.pid ?? ""));
    },
  navigateToPathway:
    (repoId: string, router: (path: string) => void) =>
    async (resource: CompactResourceAggregateWithInteractions) => {
      router(HubRoutesV2.pathwayInExplorer(repoId, resource?.pid ?? ""));
    },
  navigateToFile:
    (repoId: string, router: (path: string) => void) =>
    async (resource: CompactResourceAggregateWithInteractions) => {
      router(HubRoutesV2.assetInExplorer(repoId, resource?.pid ?? ""));
    },
};

export const selectors = {
  onlyServices: (resources: CompactResourceAggregateWithInteractions[]) => {
    return resources.filter((r) => r.types.includes(ResourceTypeTag.service));
  },
  onlyPathways: (resources: CompactResourceAggregateWithInteractions[]) => {
    return resources.filter((r) => r.types.includes(ResourceTypeTag.pathway));
  },
  onlyContent: (resources: CompactResourceAggregateWithInteractions[]) => {
    return resources.filter(
      (r) =>
        r.types.includes(ResourceTypeTag.document) ||
        r.types.includes(ResourceTypeTag.video)
    );
  },

  hasActiveFilters: (state: State) =>
    validString(state.query.search) ||
    (state.query.tags?.length ?? 0) > 0 ||
    (state.query.types?.length ?? 0) > 0 ||
    (state.query.rating?.length ?? 0) > 0,
};

export const derived = derive({
  hasActiveFilters: (get) => selectors.hasActiveFilters(get(state)),
});

export const actions = {
  //   setFilters: (filters: MatchingFiltersAggregate[]) => {
  //     state.filters = filters;
  //   },
  setNoResults: (noResults: boolean) => {
    state.noResults = noResults;
  },
  setError(error: boolean) {
    state.isError = error;
  },

  setResults: (results: CompactResourceAggregateWithInteractions[]) => {
    state.results = results;
  },

  setQuery: (query: Query) => {
    state.query = { ...state.query, ...query };
  },

  setExpandedSection: (s: ExplorerSectionName | null) => {
    state.expandedSection = s;
  },

  setIsGrid: (isGrid: boolean) => {
    state.isGrid = isGrid;
  },

  updateSearchQuery(value: string) {
    actions.setQuery({ search: value });
  },

  updateFilter(category: FilterCategory, value: string, isChecked: boolean) {
    const query = state.query[category] ?? [];
    const newQuery = isChecked
      ? [...query, value]
      : query.filter((v) => v !== value);
    actions.setQuery({ [category]: newQuery });
  },
  debouncedSearch: debounce(async (v3Token, state) => {
    const result = await ops.search.run({
      token: v3Token,
      query: state.query.search ?? null,
      tags: state.query.tags ?? null,
      types: state.query.types ?? null,
      rating: state.query.rating ?? null,
      resource: {
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        repositoryPid: state.repoPid!,
        type: "explorer",
      },
    });

    if (result.length === 0) {
      actions.setNoResults(true);
      actions.setResults([]);
      return;
    }

    actions.setNoResults(false);
    console.info("search results", result.length);
    actions.setExpandedSection(null);

    actions.setResults(
      result.map((r) => {
        return {
          ...r,
          types: r.types as unknown as ResourceTypeTag[],
        } as CompactResourceAggregateWithInteractions;
      })
    );
  }, 300),

  resetToSuggestedResults: () => {
    console.info("reset to suggested");
    actions.setNoResults(false);
    const resources = ops.fetchSuggestedResources.derived.dataOrNull ?? [];
    return actions.setResults(
      resources.map((res) => ({
        ...res,
        types: res.types as unknown as ResourceTypeTag[],
      })),
    );
  },
};

const sagas = {
  async init(repoPid: string) {
    state.repoPid = repoPid;

    const token = HubAuthStore.state.token;
    if (!token) {
      console.info("No token found");
      return actions.setError(true);
    }
    const { token: v3Token } = getImperativeHubV3Auth({
      pid: state.repoPid ?? "",
    });

    await sagas.initFiltersAndSuggestedResources({
      token: token,
      v3Token: v3Token,
      repoPid,
    });
  },

  initFiltersAndSuggestedResources: async (opts: {
    token: string;
    v3Token: string;
    repoPid: string;
  }) => {
    const [_filters, resources] = await Promise.all([
      ops.fetchFilters.run({
        resource: {
          type: "explorer",
          repositoryPid: opts.repoPid,
        },
        token: opts.v3Token,
      }),
      ops.fetchSuggestedResources.run({
        types: supportedTypes,
        resource: {
          repositoryPid: opts.repoPid,
          type: "explorer",
        },
        token: opts.v3Token,
      }),
    ]);
    actions.setResults(
      resources.map((res) => ({
        ...res,
        types: res.types as unknown as ResourceTypeTag[],
      })),
    );
  },

  onQueryChange: async (
    change:
      | {
          type: "search";
          value: string;
        }
      | {
          type: "filter";
          category: FilterCategory;
          value: string;
          isChecked: boolean;
        }
  ) => {
    if (change.type === "search") {
      actions.updateSearchQuery(change.value);
      if (change.value.length < 4) {
        console.info("filter query is less than 3 characters");
        return actions.resetToSuggestedResults();
      }
    } else {
      actions.updateFilter(change.category, change.value, change.isChecked);
    }

    const hasActiveFilters = selectors.hasActiveFilters(state);

    if (!hasActiveFilters) {
      console.info("no filters is selected");
      return actions.resetToSuggestedResults();
    }

    const token = HubAuthStore.state.token;
    if (!token) return;

    const { token: v3Token } = getImperativeHubV3Auth({
      pid: state.repoPid ?? "",
    });

    actions.debouncedSearch(v3Token, state);
  },
};

const store = {
  state,
  sagas,
  actions,
  ops,
  derived,
  selectors,
} satisfies ClientStore & { selectors: unknown };

export const useExplorerSearch = () => useStore(store);
