import { FC, PropsWithChildren, ReactNode } from "react";
import {
  ActionIcon,
  AppShell,
  AppShellHeaderProps,
  AppShellMainProps,
  AppShellProps,
  Box,
  Divider,
  Flex,
  Group,
  rem,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { ChevronLeft } from "lucide-react";
import { BottomDrawer } from "./drawer";
import "./layout.css";

export type HeaderProps = {
  goBack?: () => void;
  actions?: ReactNode;
  logoUrl?: boolean;
  logo?: ReactNode;
  subHeader?: ReactNode;
  leftActions?: ReactNode;
} & AppShellHeaderProps;

export const MobileAppPageHeader: FC<PropsWithChildren<HeaderProps>> = ({
  children,
  goBack,
  logo,
  actions,
  subHeader,
  ...props
}) => {
  const cutoff = 40;
  const hasLongText =
    typeof children === "string" ? children.length > cutoff : false;
  return (
    <AppShell.Header {...props}>
      <Flex direction="column" h="100%" justify={"center"}>
        <Group
          wrap="nowrap"
          justify="space-between"
          w="100%"
          preventGrowOverflow={true}
          p={rem(8)}
        >
          <Group
            gap={"md"}
            wrap="nowrap"
            className="no-scrollbar"
            styles={{
              root: {
                overflowX: "auto",
              },
            }}
          >
            {goBack !== undefined && (
              <ActionIcon variant="subtle" onClick={goBack}>
                <ChevronLeft size={"4rem"} />
              </ActionIcon>
            )}
            {logo !== undefined && logo}
            <Title
              px={1}
              order={3}
              className="no-scrollbar line-clamp-2"
              // size={hasLongText ? "sm" : "md"}
              styles={{
                root: {
                  fontSize: hasLongText ? "1rem" : "1.3rem",
                  lineHeight: hasLongText ? "1.2rem" : "1.5rem",
                },
              }}

              // style={{
              //   whiteSpace: "nowrap",
              //   overflowX: "auto",
              //   overflowY: "hidden",
              //   scrollbarWidth: "none",
              // }}
            >
              {children}
            </Title>
          </Group>
          {actions !== undefined && actions}
        </Group>
        {subHeader}
      </Flex>
    </AppShell.Header>
  );
};

export const Footer: FC<PropsWithChildren> = (p) => {
  return <AppShell.Footer>{p.children}</AppShell.Footer>;
};

export const AppPageMain: FC<PropsWithChildren & AppShellMainProps> = (p) => {
  const { children, styles, ...mainProps } = p;
  return (
    <AppShell.Main
      h="100dvh"
      style={{ overflow: "auto", display: "flex", flexDirection: "column" }}
      styles={styles}
      {...mainProps}
    >
      {children}
    </AppShell.Main>
  );
};

export const MobileAppPage: FC<PropsWithChildren<AppShellProps>> & {
  Header?: any;
  Main?: any;
  Footer?: any;
  BottomDrawer?: any;
} = (p) => {
  const { children, ...appShellProps } = p;
  return (
    <AppShell
      header={{ height: HEADER_HEIGHT }}
      footer={{ height: FOOTER_HEIGHT }}
      padding="xl"
      {...appShellProps}
    >
      {children}
    </AppShell>
  );
};

MobileAppPage.Header = MobileAppPageHeader;
MobileAppPage.Main = AppPageMain;
MobileAppPage.Footer = Footer;
MobileAppPage.BottomDrawer = BottomDrawer;

export const DesktopAppHeader: FC<PropsWithChildren<HeaderProps>> = ({
  children,
  goBack,
  logo,
  actions,
  subHeader,
  ...props
}) => {
  return (
    <AppShell.Header {...props}>
      <Flex direction="column" h="100%" justify="center">
        <Group
          wrap="nowrap"
          justify="space-between"
          w="100%"
          preventGrowOverflow={true}
          p={rem(8)}
        >
          <Group gap={"md"} wrap="nowrap">
            {goBack !== undefined && (
              <ActionIcon variant="subtle" onClick={goBack}>
                <ChevronLeft size={"4rem"} />
              </ActionIcon>
            )}
            {logo !== undefined && logo}
            <Title order={3} lineClamp={1}>
              {children}
            </Title>
          </Group>
          {actions !== undefined && actions}
        </Group>
        {subHeader}
      </Flex>
    </AppShell.Header>
  );
};
export const HeaderWithNavbarToggle: FC<
  PropsWithChildren<HeaderProps & { navbarTrigger: ReactNode }>
> = (p) => {
  const { children, navbarTrigger, ...headerProps } = p;
  return (
    <DesktopAppHeader {...headerProps}>
      <Group>
        <Box maw={headerProps.goBack ? NAVBAR_WIDTH - 60 : NAVBAR_WIDTH}>
          <Title order={3} lineClamp={1}>
            {children}
          </Title>
        </Box>
        <Flex
          pos="absolute"
          top={0}
          left={NAVBAR_WIDTH - 1}
          align="center"
          gap={"sm"}
        >
          <Divider h={60} orientation="vertical" />
          {navbarTrigger}
        </Flex>
      </Group>
    </DesktopAppHeader>
  );
};

export const Navbar: FC<PropsWithChildren> = (p) => {
  return <AppShell.Navbar p={"sm"}>{p.children}</AppShell.Navbar>;
};

export const AppPage: FC<
  PropsWithChildren<AppShellProps & { showNavbar?: boolean }>
> & {
  BottomDrawer?: any;
  Footer?: any;
  Header?: any;
  HeaderWithNavbarToggle?: any;
  Main?: any;
  Navbar?: any;
} = (p) => {
  const { children, showNavbar = false, ...appShellProps } = p;
  return (
    <AppShell
      header={{ height: HEADER_HEIGHT }}
      navbar={{
        width: NAVBAR_WIDTH,
        breakpoint: "sm",
        collapsed: { mobile: !showNavbar, desktop: !showNavbar },
      }}
      padding="lg"
      {...appShellProps}
    >
      {children}
    </AppShell>
  );
};

export const NAVBAR_WIDTH = 400;
export const HEADER_HEIGHT = 60;
export const FOOTER_HEIGHT = 60;

AppPage.HeaderWithNavbarToggle = HeaderWithNavbarToggle;
AppPage.Header = DesktopAppHeader;
AppPage.Main = AppPageMain;
AppPage.Footer = Footer;
AppPage.BottomDrawer = BottomDrawer;
AppPage.Navbar = Navbar;
