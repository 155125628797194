import { FC, ReactNode } from "react";
import { StaticResourceAggregate } from "@pillar/assets/hub/types";

import { MobileMetadataScreen } from "./MobileMetadataScreen";

export const MobileResourceMetadataOrActualContentRenderer: FC<{
  resource: StaticResourceAggregate;
  opened: boolean;
  onOpen: () => void;
  showTitle?: boolean;
  // temp hack, lets remove asap
  overrideServiceRenderer?: ReactNode;
}> = (p) => {
  // TODO: this is only used for pdf. we should encapsulate this better

  return (
    <MobileMetadataScreen
      overrideServiceRenderer={p.overrideServiceRenderer}
      resource={p.resource}
      onOpen={p.onOpen}
      showTitle={p.showTitle}
    />
  );
};
