import { FC } from "react";

import { HubAuth } from "@pillar/auth/hub/client/HubAuth";
import { HubConfig } from "@pillar/config/hub/client/HubConfig";
import { PageNotFound } from "@pillar/hub/client/NotFound";
import { useHubParams } from "@pillar/hub/navigation";
import { InferCurrentLinkFromEnv } from "@pillar/links/hub/client/InferResourceLinkFromEnv";
import { useIsMobile } from "@pillar/ui/obsidian/layout/hooks";

import { MobileHubExplorerScreen } from "./MobileHubExplorerScreen";
import { PublicRepositoryFlow as HubExplorerScreen } from "./PublicRepositoryFlow";
import { LoadingOverlay } from "@mantine/core";
import { SyncExplorerInteractionRecorder } from "@pillar/v3/repos/hub/client/SyncExplorerInteractionRecorder";
import { HubURLParams } from "@pillar/v3/client/urls/HubURLParams";
import { Autologin } from "@pillar/v3/auth/hub/client/AutoLogin";

export const ExplorerRenderer: FC<{ pid: string }> = (props) => {
  const { isMobile } = useIsMobile();

  return isMobile ? (
    <MobileHubExplorerScreen repositoryPid={props.pid} />
  ) : (
    <HubExplorerScreen repositoryPid={props.pid} />
  );
};

export const ExplorerRendererPage: FC = () => {
  const pid = useHubParams()?.pid;

  if (!pid) return <LoadingOverlay />;

  return (
    <InferCurrentLinkFromEnv>
      <HubConfig pid={pid}>
        <HubURLParams>
          <Autologin pid={pid}>
            <SyncExplorerInteractionRecorder explorerPid={pid}>
              <ExplorerRenderer pid={pid} />
            </SyncExplorerInteractionRecorder>
          </Autologin>
        </HubURLParams>
      </HubConfig>
    </InferCurrentLinkFromEnv>
  );
};
