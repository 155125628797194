import { DateTime, Duration, DurationUnit, Interval } from "luxon";
import { pluralize, validString } from "./strings";

/** @deprecated */
export const hhMMSSformat = (seconds: number) =>
  Duration.fromObject({
    seconds,
  }).toFormat("hh:mm:ss");

export enum TimeUnit {
  seconds = "seconds",
  minutes = "minutes",
  hours = "hours",
  days = "days",
  weeks = "weeks",
  months = "months",
}

export const computeDurationToSeconds = (
  duration: number = 1,
  timeUnit: TimeUnit = TimeUnit.weeks
) => {
  let result = 1;
  switch (timeUnit) {
    case TimeUnit.seconds:
      result = duration;
      break;
    case TimeUnit.minutes:
      result = duration * 60;
      break;
    case TimeUnit.hours:
      result = duration * 60 * 60;
      break;
    case TimeUnit.days:
      result = duration * 24 * 60 * 60;
      break;
    case TimeUnit.months:
      result = duration * 30 * 24 * 60 * 60;
      break;
    case TimeUnit.weeks:
    default:
      result = duration * 7 * 24 * 60 * 60;
  }

  return result;
};

function toDuration(seconds: number, rest: number, ref: number) {
  return Math.round((seconds - rest) / ref) + (rest > ref / 2 ? 1 : 0);
}

export const computeSecondToNearestDuration = (seconds: number) => {
  // detect months
  let ref = 30 * 86400;
  let rest = seconds % ref;
  if (rest < seconds) {
    return {
      timeUnit: TimeUnit.months,
      duration: toDuration(seconds, rest, ref),
    };
  }
  // detect years
  ref = 7 * 86400;
  rest = seconds % ref;
  if (rest < seconds) {
    return {
      timeUnit: TimeUnit.weeks,
      duration: toDuration(seconds, rest, ref),
    };
  }
  // detect days
  ref = 86400;
  rest = seconds % ref;
  if (rest < seconds) {
    return {
      timeUnit: TimeUnit.days,
      duration: toDuration(seconds, rest, ref),
    };
  }

  // detect hours
  ref = 3600;
  rest = seconds % ref;
  if (rest < seconds) {
    return {
      timeUnit: TimeUnit.hours,
      duration: toDuration(seconds, rest, ref),
    };
  }

  // detect minutes
  ref = 60;
  rest = seconds % ref;
  if (rest < seconds) {
    return {
      timeUnit: TimeUnit.minutes,
      duration: toDuration(seconds, rest, ref),
    };
  }

  return {
    timeUnit: TimeUnit.seconds,
    duration: seconds,
  };
};

export function addTime(
  referenceDate: Date,
  duration: number = 1,
  timeUnit: TimeUnit = TimeUnit.weeks
): Date {
  const newDate = new Date(referenceDate.valueOf());
  switch (timeUnit) {
    case TimeUnit.seconds:
      newDate.setSeconds(newDate.getSeconds() + duration);
      break;
    case TimeUnit.minutes:
      newDate.setMinutes(newDate.getMinutes() + duration);
      break;
    case TimeUnit.hours:
      newDate.setHours(newDate.getHours() + duration);
      break;
    case TimeUnit.days:
      newDate.setDate(newDate.getDate() + duration);
      break;
    case TimeUnit.weeks:
      newDate.setDate(newDate.getDate() + duration * 7);
      break;
    case TimeUnit.months:
      newDate.setMonth(newDate.getMonth() + duration);
      break;
    default:
      return referenceDate;
  }

  return newDate;
}

const getPreviousUnit = (u: DurationUnit): DurationUnit => {
  const unitsOrder: DurationUnit[] = [
    "years",
    "months",
    "weeks",
    "days",
    "hours",
    "minutes",
    "seconds",
    "milliseconds",
  ];
  const index = unitsOrder.indexOf(u);

  if (index > 0 && index < unitsOrder.length - 1) {
    return unitsOrder[index - 1];
  }

  return "years";
};

/**
 * Rounds a Luxon duration on a specific unit.
 * eg.  roundTimeUnit( {days: 1, hours: 23, minutes: 59}, 'minutes', 30 ) = {days: 2}
 *      roundTimeUnit( {days: 1, hours: 23, minutes: 29}, 'minutes', 30 ) = {days: 1, hours: 23}
 *
 * @param duration The Luxon Duration to round
 * @param unit A Luxon DurationUnit (eg 'days')
 * @param threshold The value considered as the middle of the interval
 * @returns roundedDuration A new Luxon Duration, rounded
 */
export const roundTimeUnit = (
  duration: Duration,
  unit: DurationUnit,
  threshold: number
) => {
  let roundedDuration = Duration.fromDurationLike(duration);
  const toRound = duration[unit];
  if (toRound > threshold)
    roundedDuration = roundedDuration.set({
      [getPreviousUnit(unit)]: duration[getPreviousUnit(unit)] + 1,
    });

  // This step sets the values of only the units of interest, and sets all the others to 0
  // eg If we're rounding on 'minutes', we will only keep 'days' and 'hours'.
  const previousUnit = getPreviousUnit(unit);
  const previousPreviousUnit = getPreviousUnit(previousUnit);
  roundedDuration = roundedDuration.set({
    years: 0,
    months: 0,
    weeks: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    [previousUnit]: roundedDuration[previousUnit],
    [previousPreviousUnit]: roundedDuration[previousPreviousUnit],
  });

  return roundedDuration.rescale();
};

export const roundDuration = (duration: Duration): Duration => {
  let roundedDuration = Duration.fromDurationLike(duration);
  // Round weeks for years + months
  if (roundedDuration.years > 0) {
    return roundTimeUnit(duration, "weeks", 4);
  }
  // Round days for months + weeks
  if (roundedDuration.months > 0) {
    return roundTimeUnit(duration, "days", 4);
  }

  // Round hours for weeks + days
  if (roundedDuration.weeks > 0) {
    return roundTimeUnit(duration, "hours", 12);
  }
  // Round minutes for days + hours
  if (roundedDuration.days > 0) {
    return roundTimeUnit(duration, "minutes", 30);
  }
  // Round seconds for hours + minutes
  if (roundedDuration.hours > 0) {
    return roundTimeUnit(duration, "seconds", 30);
  }
  // Round seconds for minutes + seconds
  if (roundedDuration.minutes > 0) {
    return roundTimeUnit(duration, "seconds", 30);
  }

  return duration.rescale();
};

export function humanizeLeftTime(
  p: {
    referenceDate: Date;
    duration: number | undefined;
    timeUnit: TimeUnit | undefined;
  },
  clockTime: Date = new Date()
) {
  const newDate = addTime(p.referenceDate, p.duration, p.timeUnit);
  const interval = Interval.fromDateTimes(
    DateTime.fromJSDate(clockTime),
    DateTime.fromJSDate(newDate)
  );
  const duration = interval.toDuration([
    "years",
    "months",
    "weeks",
    "days",
    "hours",
    "minutes",
    "seconds",
  ]);

  if (!duration.isValid) return "0 seconds, by completing the prior section.";

  const roundedDuration = roundDuration(duration);
  const humanTime = roundedDuration.toHuman({ listStyle: "long" });
  return `${validString(humanTime) ? humanTime : "0 seconds"}${
    duration.as("seconds") <= 0 ? ", by completing the prior section." : "."
  }`;
}

export function timeLeft(
  p: {
    referenceDate: Date;
    duration: number | undefined;
    timeUnit: TimeUnit | undefined;
  },
  clockTime: Date = new Date()
) {
  const newDate = addTime(p.referenceDate, p.duration, p.timeUnit);
  const seconds = Math.round((newDate.valueOf() - clockTime.valueOf()) / 1000);

  return seconds;
}
