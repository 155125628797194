import { createTRPCProxyClient, httpLink, TRPCLink } from "@trpc/client";
import { env } from "@pillar/env/vars-esm";
import type { HubApiRouter } from "./server";
import superjson from "superjson";

const debugLink: TRPCLink<HubApiRouter> =
  (opts) =>
  ({ next, op }) => {
    const n = next(op);
    return n;
  };

export const makeHubApiClient = (url: string) =>
  createTRPCProxyClient<HubApiRouter>({
    transformer: superjson,
    links: [
      debugLink,
      httpLink({
        url,
        fetch(url, options) {
          return fetch(url, {
            ...options,
            keepalive: true,
          });
        },
      }),
    ],
  });

export const hubApi = makeHubApiClient(env.public.VITE_PUBLIC_HUB_API);
