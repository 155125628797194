import { AgnosticFileRenderer } from "@pillar/singleResource/hub/client/FileRenderer";
import { AnyResourceRendererProps } from "@pillar/singleResource/hub/types";
import { FC } from "react";
import { ServiceInPathwayOrNull } from "./ServiceInPathwayRenderer";
import { ResourceTypeTag } from "@pillar/resources/types.legacy";
import { FormInPathway } from "@pillar/v3/forms/hub/client/FormInPathway";

import { HubResourceTranslationsProvider } from "@pillar/v3/accessibility/translation/hub/client/hooks";

export const PathwayActualContentRenderer: FC<
  AnyResourceRendererProps & {
    successActions?: React.ReactNode;
  }
> = (p) => {
  return (
    <HubResourceTranslationsProvider
      initialState={{
        vernacular: {
          id: p.resource.id,
          uri: p.resource.url,
        },
      }}
    >
      <AgnosticFileRenderer {...p} />
      <ServiceInPathwayOrNull {...p} />
      {p.resource.types.includes(ResourceTypeTag.form) && (
        <FormInPathway successActions={p.successActions} />
      )}
    </HubResourceTranslationsProvider>
  );
};
