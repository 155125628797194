import { createTRPCProxyClient, httpBatchLink, httpLink } from "@trpc/client";
import superjson from "superjson";
import { V3InternalApiRouter } from ".";

export const v3TrpcConfig = (url: string) => ({
  transformer: superjson,
  links: [
    httpBatchLink({
      url,
    }),
  ],
  fetch(url: string, options: Partial<FetchRequestInit>) {
    return fetch(url, {
      ...options,
      credentials: "include",
      keepalive: true,
    });
  },
});

export const v3SequentialTrpcConfig = (url: string) => ({
  transformer: superjson,
  links: [
    httpLink({
      url,
    }),
  ],
  fetch(url: string, options: Partial<FetchRequestInit>) {
    return fetch(url, {
      ...options,
      credentials: "include",
      keepalive: true,
    });
  },
});

type Config = ReturnType<typeof v3TrpcConfig>;

export const makeAgnosticV3ApiClient = (url: string, config?: Config) =>
  createTRPCProxyClient<V3InternalApiRouter>(config ?? v3TrpcConfig(url));

export const makeSequentialV3ApiClient = (url: string) =>
  createTRPCProxyClient<V3InternalApiRouter>(v3SequentialTrpcConfig(url));
