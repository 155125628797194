import { makeAgnosticV3ApiClient } from "@pillar/v3/rpc/client";
import { httpLink } from "@trpc/client";
import superjson from "superjson";

export const hubClientTrpcConfig = (url: string) => ({
  transformer: superjson,
  links: [
    httpLink({
      url,
      headers(opts) {
        const input = opts.op.input as { __headers?: Record<string, string> };
        if (!input.__headers) {
          return {};
        }
        const copy = input.__headers;
        // biome-ignore lint/performance/noDelete: we need to delete the header to not confuse the actual rpc handler
        delete input.__headers;
        return copy;
      },
    }),
  ],
  fetch(url: string, options: Partial<FetchRequestInit>) {
    return fetch(url, {
      ...options,
      credentials: "include",
      keepalive: true,
    });
  },
});

// @ts-ignore
export const v3ApiUrl = import.meta.env.VITE_PUBLIC_V3_API ?? "";

export const v3HubApi = makeAgnosticV3ApiClient(
  v3ApiUrl,
  hubClientTrpcConfig(v3ApiUrl)
);
