import { VStack } from "@chakra-ui/react";
import { Box, Image, Overlay, Stack, Title } from "@mantine/core";
import { useHubConfigStore } from "@pillar/config/hub/client/HubConfig/index.store";
import { FC } from "react";

export const HubExplorerBanner: FC = () => {
  const config = useHubConfigStore();

  const assets = config.derived.config.banner?.image;

  if (!assets) return null;
  return (
    <Box pos="relative">
      <Image
        fit="cover"
        h="12em"
        bgp={"center center"}
        src={assets}
        alt="Health Hub Banner"
      />
      <Stack
        style={{ zIndex: 2 }}
        align="center"
        justify="center"
        pos="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
      >
        <Title
          order={2}
          size="h2"
          styles={{
            root: {
              color: "white",
            },
          }}
        >
          {config.derived.banner.title}
        </Title>
        {(config.derived.banner.line1 || config.derived.banner.line2) && (
          <VStack gap={0}>
            {config.derived.banner.line1 && (
              <Title
                order={3}
                size="h5"
                styles={{
                  root: {
                    fontWeight: "normal",
                    color: "white",
                    textAlign: "center",
                  },
                }}
              >
                {config.derived.banner.line1}
              </Title>
            )}
            {config.derived.banner.line2 && (
              <Title
                order={3}
                size="h5"
                styles={{
                  root: {
                    fontWeight: "normal",
                    color: "white",
                    textAlign: "center",
                  },
                }}
              >
                {config.derived.banner.line2}
              </Title>
            )}
          </VStack>
        )}
      </Stack>
    </Box>
  );
};
