import { WithOrganization } from "@pillar/architect/types";

import { PathwayAggregate } from "@pillar/pathways/types";
import { ComputedResourceStats } from "@pillar/resources/interactions/types";
import { ResourceAggregate } from "@pillar/resources/types";
import { ServiceAggregate } from "@pillar/services/types";
import { z } from "zod";

export type InviteLinkAggregateBase = {
  organizationId: string;
  id: string;
  name: string;
  description: string;
  pid: string;
  url: string;
  initiatedById: string;
  sharedAt: Date;
  updatedAt: Date;
  repoId: string;
};

export type ResourceInviteLinkAggregate = {
  type: "resource";
  resource: {
    id: string;
    name: string;
    repositoryId: string;
  };
  stats: ComputedResourceStats;
} & InviteLinkAggregateBase;

export type InviteLinkAggregate = ResourceInviteLinkAggregate;

export type InviteLinkWithAuthorAggregate<I extends InviteLinkAggregate> = I & {
  initiatedBy: {
    id: string;
    name: string;
  };
};

export const APIGetInviteLinkById = z.object({
  inviteLinkId: z.string(),
});

export type APIGetInviteLinkById = z.infer<typeof APIGetInviteLinkById>;

export type GetInviteLinkById = WithOrganization<{
  inviteLinkId: string;
}>;

// #region invite links acceptance

export type GetInviteAcceptanceForUser = {
  inviteLinkId: string;
  organizationId: string;
  membershipId: string;
};

export type APIGetInviteLinkCohort = {
  inviteLinkId: string;
};
export type GetInviteLinkAcceptanceCohort =
  WithOrganization<APIGetInviteLinkCohort>;

export type APICreateInviteLinkAcceptance = {
  inviteLinkId: string;
  membershipId: string;
};

export type CreateInviteLinkAcceptance =
  WithOrganization<APICreateInviteLinkAcceptance>;

// #endregion

export type GetGloballyUniqueInviteLink = {
  inviteLinkId: string;
};

/** @deprecated */
export enum InviteLinkPurpose {
  resourceShare = "resourceShare",
  signupAsMember = "signupAsMember",
  signupAsProvider = "signupAsProvider",
  signupAsVendor = "signupAsVendor",
}

/** @deprecated */
export enum LinkType {
  deprecated_sharedLink = "pr",
  resource = "pubr",
  repository = "prr",
  pathway = "pptw",
  service = "psvc",

  form = "frm",
}

export enum SharedResourceType {
  asset = "asset",
  repository = "repository",
  pathway = "pathway",
  service = "service",
  assetInPathway = "assetInPathway",
  /** @deprecated */
  serviceInPathway = "serviceInPathway",
  assetInRepository = "assetInRepository",
  /** @deprecated */
  serviceInRepository = "serviceInRepository",
  assetInPathwayInRepository = "assetInPathwayInRepository",
  /** @deprecated */
  serviceInPathwayInRepository = "serviceInPathwayInRepository",
  pathwayInRepository = "pathwayInRepository",
}

/** @deprecated */
export enum ResourceRendererType {
  asset = "asset",
  explorer = "explorer",
  form = "form",
  pathway = "pathway",
  service = "service",
}

export const LinkPublicId = z.string().max(64);
export const LinkTypeIdEnum = z.nativeEnum(LinkType);

export const ResourceRendererTypeEnum = z.nativeEnum(ResourceRendererType);

export const ResourceLink = z.object({
  pid: z.string(),
  targetPid: z.string().optional(),
  type: LinkTypeIdEnum,
});

export type ResourceLink = z.infer<typeof ResourceLink>;

export type PublicResourceIds = ResourceLink & {
  organizationId: string;
  renderer: ResourceRendererType;
};

// resolve an incoming pid to their appropriate type
export const APIResolveAnyShareableResource = z.object({
  type: z.literal("unknown"),
  pid: z.string(),
});

export type APIResolveAnyShareableResource = z.infer<
  typeof APIResolveAnyShareableResource
>;

export const APIResolveAsset = z.object({
  type: z.literal("asset"),
  pid: z.string(),
});

export type APIResolveAsset = z.infer<typeof APIResolveAsset>;

export const APIResolveService = z.object({
  type: z.literal("service"),
  servicePid: z.string(),
});

export type APIResolveService = z.infer<typeof APIResolveService>;

export const APIResolvePathway = z.object({
  type: z.literal("pathway"),
  pathwayPid: z.string(),
});

export type APIResolvePathway = z.infer<typeof APIResolvePathway>;

export const APIResolveAssetInPathway = z.object({
  type: z.literal("assetInPathway"),
  pathwayPid: z.string(),
  resourceId: z.string(),
  explorer: z.string().optional(),
});
export type APIResolveAssetInPathway = z.infer<typeof APIResolveAssetInPathway>;

export const APIResolveExplorer = z.object({
  type: z.literal("explorer"),
  repositoryPid: z.string(),
});

export type APIResolveExplorer = z.infer<typeof APIResolveExplorer>;

export const APIResolveAssetInExplorer = z.object({
  type: z.literal("assetInExplorer"),
  repositoryPid: z.string(),
  resourcePid: z.string(),
});

export type APIResolveResourceInExplorer = z.infer<
  typeof APIResolveAssetInExplorer
>;

export const APIResolvePathwayInExplorer = z.object({
  type: z.literal("pathwayInExplorer"),
  repositoryPid: z.string(),
  pathwayPid: z.string(),
});

export type APIResolvePathwayInExplorer = z.infer<
  typeof APIResolvePathwayInExplorer
>;

export const APIResolveFromPid = z.union([
  APIResolveAsset,

  APIResolveService,

  APIResolvePathway,
  APIResolveAssetInPathway,

  APIResolveExplorer,
  APIResolveAssetInExplorer,
  APIResolvePathwayInExplorer,
]);

export type APIResolveFromPid = z.infer<typeof APIResolveFromPid>;

export type RelatedRepository = {
  type: SharedResourceType.repository;
  id: string;
};

export type RelatedLink = {
  type: "link";
  organizationId: string;
  repositoryId: string;
  id: string;
  by: string;
};

export type BaseResolvedResourceFromPid = {
  organizationId: string;
  repositoryId: string;
};

export type ResolvedAsset = BaseResolvedResourceFromPid & {
  type: SharedResourceType.asset;
  resource: ResourceAggregate;
  repository: RelatedRepository;
  link: RelatedLink;
};

export type ResolvedAssetInExplorer = BaseResolvedResourceFromPid & {
  type: SharedResourceType.assetInRepository;
  resource: ResourceAggregate;
  repository: RelatedRepository;
};

export type ResolvedPathway = BaseResolvedResourceFromPid & {
  type: SharedResourceType.pathway;
  pathway: PathwayAggregate;
  repository: RelatedRepository;
  link: RelatedLink;
};

export type ResolvedPathwayInExplorer = BaseResolvedResourceFromPid & {
  type: SharedResourceType.pathwayInRepository;
  pathway: PathwayAggregate;
  repository: RelatedRepository;
};

export type ResolvedAssetInPathwayInExplorer = BaseResolvedResourceFromPid & {
  type: SharedResourceType.assetInPathwayInRepository;
  pathway: PathwayAggregate;
  resource: ResourceAggregate;
  repository: RelatedRepository;
};

export type ResolvedAssetInPathway = BaseResolvedResourceFromPid & {
  type: SharedResourceType.assetInPathway;
  pathway: PathwayAggregate;
  resource: ResourceAggregate;
  repository: RelatedRepository;
};

export type ResolvedRepository = BaseResolvedResourceFromPid & {
  type: SharedResourceType.repository;
  repository: RelatedRepository;
  link: RelatedLink | null;
};

export type ResolvedLinkableResource =
  | ResolvedAsset
  | ResolvedPathway
  | ResolvedRepository;

export type ResolvedNestedResource =
  | ResolvedAssetInPathway
  | ResolvedAssetInExplorer
  | ResolvedPathwayInExplorer
  | ResolvedAssetInPathwayInExplorer;

export type ResolvedHubResource =
  | ResolvedLinkableResource
  | ResolvedNestedResource;

export type ResolvedResourceFromHubForMembership<
  T extends ResolvedHubResource = ResolvedHubResource
> = T & {
  membershipId: string;
};

export type ResolvedResourceFromHubForEnrollment<
  T extends ResolvedHubResource = ResolvedHubResource
> = ResolvedResourceFromHubForMembership<T> & {
  enrollmentId: string;
};

export const APITokenWithUnknownPid = z.object({
  resource: APIResolveAnyShareableResource,
  token: z.string(),
});

export type APITokenWithUnknownPid = z.infer<typeof APITokenWithUnknownPid>;

export const APISendResourceById = z.object({
  id: z.string(),
  repositoryId: z.string(),
  emails: z.array(z.string()),
});

export type APISendResourceById = z.infer<typeof APISendResourceById>;

export const APIRecordResourceLinkOpened = APIResolveAnyShareableResource;
export type APIRecordResourceLinkOpened = APIResolveAnyShareableResource;
