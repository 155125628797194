import ReactPlayer from "react-player";
import {
  ComponentType,
  FC,
  memo,
  PropsWithChildren,
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { AspectRatio, Card, Flex, FlexProps, Modal, rem } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import { debounce } from "@pillar/std/fns";
import { AppEventType } from "@pillar/events/types";
import { ResourceVideoEvent } from "@pillar/resources/interactions/types";
import { BottomDrawer } from "@pillar/ui/obsidian/layout/drawer";

import { VideoRendererStore } from "./ResourceVideoRenderer.store";
import { ResourcePreview } from "./ResourcePreview";
import { ResourceTypeTag } from "@pillar/resources/types.legacy";

export type VideoRendererProps = {
  href: string;
  title: string;
  poster?: string;
  send: (opts: ResourceVideoEvent) => void;
  EndScreen?: ComponentType<{ onClose: () => void }>;
} & FlexProps;

type VideoRendererInstance = { getCurrentTime: () => number };

const getTime = (ref: Ref<VideoRendererInstance>) => {
  // @ts-expect-error FIXME
  return ref?.current.getCurrentTime() ?? 0;
};

export const InnerVideoRenderer: FC<
  VideoRendererProps & {
    EndScreenLayout: FC<
      PropsWithChildren<{ opened: boolean; onClose: () => void }>
    >;
  }
> = (p) => {
  const ref = useRef<ReactPlayer>(null);
  const [feedbackOpen, { open: setFeedbackOpen, close: setFeedbackClose }] =
    useDisclosure(false);

  const onClose = () => {
    setFeedbackClose();
  };

  const onPlay = useCallback(() => {
    p.send({ type: AppEventType.resourceResumed, at: getTime(ref) });
    setFeedbackClose();
  }, [p.send]);

  const onPause = useCallback(() => {
    p.send({ type: AppEventType.resourcePaused, at: getTime(ref) });
  }, [p.send]);

  const onScrub = useCallback(
    debounce(() => {
      p.send({ type: AppEventType.resourceScrubbed, at: getTime(ref) });
    }, 500),
    [p.send]
  );

  const onFinished = useCallback(() => {
    if (p.EndScreen) setFeedbackOpen();
    p.send({ type: AppEventType.resourceFinished, at: -1 });
  }, [p.send]);

  useEffect(() => {
    setFeedbackClose();
  }, []);

  return (
    <>
      <ReactPlayer
        playing={true}
        ref={ref}
        playIcon={
          <ResourcePreview
            title={p.title}
            thumbnail={p.poster}
            type={ResourceTypeTag.video}
          />
        }
        light={true}
        controls
        url={p.href}
        onPlay={onPlay as any}
        onEnded={onFinished as any}
        onPause={onPause as any}
        onSeek={onScrub as any}
        width="100%"
        height="100%"
      />
      <p.EndScreenLayout
        opened={Boolean(p.EndScreen) && feedbackOpen}
        onClose={onClose}
      >
        {p.EndScreen && <p.EndScreen onClose={onClose} />}
      </p.EndScreenLayout>
    </>
  );
};

export const VideoRenderer: FC<VideoRendererProps> = (p) => {
  const InnerVideoRendererMemoized = useMemo(
    () => memo(InnerVideoRenderer),
    []
  );
  return (
    <Card
      mih="calc(100% - 194px)" // 194px is the maximum height the metadata should be taking (ie Title + Author + Tags + 1 line description)
      bg="black"
      className="video-controls-wrapper"
      style={{
        position: "relative",
        flex: `1 0 ${rem(100)}`,
      }}
      mx="lg"
      mb="sm"
      radius="md"
      p={0}
    >
      <InnerVideoRendererMemoized EndScreenLayout={Modal} {...p} />
    </Card>
  );
};

export const MobileVideoRenderer: FC<VideoRendererProps> = (p) => {
  const InnerVideoRendererMemoized = useMemo(
    () => memo(InnerVideoRenderer),
    []
  );

  return (
    <AspectRatio
      ratio={3 / 2}
      bg="black"
      className="video-controls-wrapper"
      style={{
        position: "relative",
        flex: `1 0 ${rem(100)}`,
      }}
    >
      <InnerVideoRendererMemoized EndScreenLayout={BottomDrawer} {...p} />
    </AspectRatio>
  );
};
