import { CompactResourceAggregate } from "@pillar/resources/types";
import { ResourceTypeTag } from "@pillar/resources/types.legacy";
import { MatchingFiltersAggregate } from "@pillar/taxonomy/types";
import { EntityStats, Resource } from "@pillar/v3/data/models/transactional.sql";

type Stats = Omit<EntityStats, "organizationId" | "id">;

export const defaultResourceStats = (partial: Partial<Stats> = {}): Stats => {
  return {
    commented: 0,
    shared: 0,
    liked: 0,
    rated: 0,
    ratingSum: 0,
    saved: 0,
    viewed: 0,
    returningViews: 0,
    viewTime: 0,
    clicked: 0,
    materializedAt: new Date(),
    ctaPerformed: 0,
    downloads: 0,
    updatedAt: new Date(),
    ...partial,
  };
};

export const resourceTypesOptions = [
  {
    label: "Document",
    value: ResourceTypeTag.document,
  },
  {
    label: "Video",
    value: ResourceTypeTag.video,
  },
  {
    label: "Pathway",
    value: ResourceTypeTag.pathway,
  },
  {
    label: "Service",
    value: ResourceTypeTag.service,
  },
];

export const resourceTypeTags: MatchingFiltersAggregate = {
  key: "resourceType",
  name: "Resource Type",
  repositoryId: "system",
  options: resourceTypesOptions,
};

export const resourcesFixtures: CompactResourceAggregate[] = [
  {
    id: "res-test-id-1",
    pid: null,
    name: "Test resource 1",
    thumbnail: "https://some-thumbnail-1.org",
    tags: ["Career", "Mental Health"],
    types: [ResourceTypeTag.document],
  },
  {
    id: "res-test-id-2",
    pid: null,
    name: "Test resource 2",
    thumbnail: "https://some-thumbnail-2.org",
    tags: ["Gratitude"],
    types: [ResourceTypeTag.video],
  },
  {
    id: "res-test-id-3",
    pid: null,
    name: "Test resource 3",
    thumbnail: "https://some-thumbnail-3.org",
    tags: ["Grief"],
    types: [ResourceTypeTag.video],
  },
];
