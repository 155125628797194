import { createId } from "@paralleldrive/cuid2";
import { ClientWindowEvents } from "./interactions.window-events";
import { APIInteractions } from "../../interaction-types";
import {
  APIAnyResourceSession,
  APIEventSession,
  APIEventSessions,
  APIResourceSession,
} from "@pillar/v3/sessions/session.types";
import { withHubToken } from "@pillar/v3/auth/hub/auth.hub.client";
import { v3HubApi } from "@pillar/v3/rpc/client.hub";

export class ClientInteractionSession {
  private _events: ClientWindowEvents = new ClientWindowEvents({
    // in ms | 5 minutes
    idle: 5 * 60 * 1000,
    onBlur: () => {
      // console.info("EVT: onBlur");
      this.stopTimer();
    },
    onFocus: () => {
      // console.info("EVT: onFocus");
      this.startTimer();
    },
    onShow: () => {
      // console.info("EVT: onShow");
      this.startTimer();
    },
    onHide: () => {
      // console.info("EVT: onHide");
      this.stopTimer();
    },
    onIdle: () => {
      // console.info("EVT: onIdle");
      this.stopTimer();
    },
    onActive: () => {
      // console.info("EVT: onActive");
      this.startTimer();
    },
  });
  constructor(
    public session: APIEventSession,
    public onEvent: (evt: APIInteractions) => Promise<void>,
    public parent: ClientInteractionSession | null = null,
    public root: ClientInteractionSession | null = null,

    public debugRefId = ""
  ) {
    this._events.start();
    this.startTimer();
  }

  private currentTimer: NodeJS.Timeout | null = null;
  private elapsedFromStart = 0;
  private fromLastMeasurement = 0;
  private setElapsed = (elapsed: number) => {
    this.elapsedFromStart = elapsed;
  };

  private setFromLastMeasurement = (elapsed: number) => {
    this.fromLastMeasurement = elapsed;
  };

  private measurementThreshold = 5;
  private onMeasurement = () => {
    if (this.fromLastMeasurement > this.measurementThreshold) {
      return this.recordViewTime();
    }
  };

  private startTimer = () => {
    if (!this.currentTimer) {
      // console.info("timer: start");
      console.info("interaction/timer started", this.debugRefId);
      this.currentTimer = setInterval(() => {
        // console.info("measure", this.debugRefId);
        this.setFromLastMeasurement(this.fromLastMeasurement + 1);
        this.onMeasurement();
      }, 1000);
    }
  };

  private stopTimer = () => {
    if (this.currentTimer) {
      console.info("interaction/timer stopped", this.debugRefId);
      clearInterval(this.currentTimer);
      this.currentTimer = null;
      this.recordViewTime();
    }
  };

  private reconcile = () => {
    // console.info("diff: ", this.fromLastMeasurement);
    this.setElapsed(this.elapsedFromStart + this.fromLastMeasurement);
    this.setFromLastMeasurement(0);
  };

  get sessions(): APIEventSessions {
    return {
      session: this.session,
      parent: this.parent?.session ?? null,
      root: this.root?.session ?? null,
    };
  }

  recordViewTime = () => {
    this.reconcile();
    const evt = {
      type: "com.withpillar.view-time-checkpoint",
      sessions: this.sessions,
      duration: this.elapsedFromStart,
    } as const;
    // console.info("EMIT", evt);
    this.onEvent(evt);
  };

  dispose = () => {
    this.stopTimer();
    this._events.stop();
  };
}

type SessionId = string;
export class ClientInteractionsStore {
  sessions: Record<SessionId, ClientInteractionSession> = {};

  currentSession: ClientInteractionSession | null = null;

  private token: string;

  ac_getToken = () => {
    return this.token;
  };

  withToken(token: string) {
    this.token = token;
    return this;
  }

  /* A resource directly accessible by a unique invite link such as a pathway, a file or an explorer */

  private getSessionOrFail = (id: string) => {
    if (!this.sessions[id]) {
      throw new Error(`Session with id ${id} not found`);
    }
    return this.sessions[id];
  };

  private makeSessionId = () => {
    return `is-${createId()}`;
  };

  loadLink = (entry: APIEventSession, sessionId: string | null) => {
    const actualSessionId = sessionId ?? this.makeSessionId();
    const refId =
      entry.sessionType === "resource"
        ? entry.anyId
        : entry.sessionType === "pathway"
        ? entry.pathwayPid
        : entry.repoPid;

    console.info("[LINK] load for", refId, Object.keys(this.sessions));

    if (this.sessions[refId]) {
      console.info("[LINK] session already exists", refId);
      return;
    }

    const session = new ClientInteractionSession(
      { ...entry, sessionId: actualSessionId } as APIEventSession,
      this.recordInteraction,
      null,
      null,
      refId
    );

    this.sessions[refId] = session;
    this.currentSession = session;
    // this.debugSessions();
    return sessionId;
  };

  loadResInPathway = (parent: string, entry: APIResourceSession) => {
    const refId = entry.anyId;
    console.debug("[PTW] load a resource in", parent, entry.anyId);
    const pathwaySession = this.getSessionOrFail(parent);
    const sessionId = this.makeSessionId();

    const session = new ClientInteractionSession(
      { ...entry, sessionId } as APIEventSession,
      this.recordInteraction,
      pathwaySession,
      pathwaySession.parent ?? null,
      refId
    );

    this.sessions[sessionId] = session;
    this.currentSession = session;

    this.debugSessions();
  };

  unloadSessions(parents: string[]) {
    for (const sessionId in parents) {
      console.debug("[UNLOAD] session", sessionId);
      this.sessions[sessionId]?.dispose();
      delete this.sessions[sessionId];
    }
  }
  unloadAllBut(parents: string[]) {
    const set = new Set(parents);
    for (const sessionId in this.sessions) {
      if (!set.has(sessionId)) {
        console.debug("[UNLOAD] session", sessionId);
        this.sessions[sessionId]?.dispose();
        delete this.sessions[sessionId];
      }
    }
  }

  loadResInRepo = (parent: string, entry: APIAnyResourceSession) => {
    const ref =
      entry.sessionType === "resource" ? entry.anyId : entry.pathwayPid;
    console.debug("[EXPLORER]: load resource from", parent, ref);
    const repoSession = this.getSessionOrFail(parent);
    const sessionId = this.makeSessionId();
    const session = new ClientInteractionSession(
      { ...entry, sessionId } as APIEventSession,
      this.recordInteraction,
      repoSession,
      repoSession,
      ref
    );

    this.sessions[ref] = session;
    this.currentSession = session;
  };

  recordInteraction = async (evt: APIInteractions) => {
    console.info("RECORD", evt.sessions.session.sessionType);
    const payload = withHubToken(this.token, evt);
    return v3HubApi.v3.hub.interactions.record.mutate(payload);
  };

  recordInteractionWithCurrentSession = (
    evt: Omit<APIInteractions, "sessions">
  ) => {
    if (!this.currentSession) {
      throw new Error("No current session");
    }
    return this.recordInteraction({
      ...evt,
      sessions: this.currentSession.sessions,
    } as APIInteractions);
  };

  debugSessions = () => {
    for (const sessionId in this.sessions) {
      console.info("\tSession:", sessionId, this.sessions[sessionId].session);
    }
  };

  selectSessions = (cond: (session: APIEventSessions) => boolean) => {
    const sessions = Object.values(this.sessions)
      .map((session) => session.sessions)
      .filter(cond)[0];

    if (!sessions) {
      throw new Error("No session found");
    }

    return sessions;
  };

  clear = () => {
    for (const sessionId in this.sessions) {
      this.sessions[sessionId].dispose();
    }
    this.sessions = {};
  };
}

export const HubInteractionStore = new ClientInteractionsStore();

// @ts-expect-error
if (import.meta?.hot) {
  // @ts-expect-error
  import.meta?.hot.accept();
  // @ts-expect-error
  import.meta?.hot.dispose(() => {
    HubInteractionStore.clear();
  });
}
