export const flattenNestedObjects = <T extends Record<string, any>>(
  obj: T,
  prefix = ""
): Record<string, any> => {
  let result: Record<string, any> = {};
  for (const [key, value] of Object.entries(obj)) {
    // stop at arrays, we'll assume scalar arrays for now

    if (Array.isArray(value)) {
      result[`${prefix}${key}`] = value;
      continue;
    }
    if (typeof value === "object" && value !== null) {
      result = Object.assign(
        result,
        flattenNestedObjects(value, `${prefix}${key}.`)
      );
    } else {
      result[`${prefix}${key}`] = value;
    }
  }
  return result;
};

export const unflatten = (data: Record<string, any>): Record<string, any> => {
  const result: Record<string, any> = {};
  for (const [key, value] of Object.entries(data)) {
    const keys = key.split(".");
    let current = result;
    for (let i = 0; i < keys.length - 1; i++) {
      const k = keys[i];
      if (!current[k]) {
        current[k] = {};
      }
      current = current[k];
    }
    current[keys[keys.length - 1]] = value;
  }
  return result;
};

/**
 * @deprecated
 * get utility - an alternative to lodash.get
 * @param {Object} object
 * @param {String|Array} path
 * @param {*} defaultVal
 */
export function get(object, path, defaultVal: any = undefined) {
  const _path = Array.isArray(path)
    ? path
    : path.split(".").filter((i) => i.length);

  if (!_path.length) {
    return object === undefined ? defaultVal : object;
  }

  if (object === undefined) return defaultVal;

  return get(object[_path.shift()], _path, defaultVal);
}

export const firstObjectValue = <T>(obj: Record<string, T>): T => {
  return obj[Object.keys(obj)[0]];
};

export const mergeObjectValues = <
  A,
  B,
  K extends string,
  T extends Record<K, A>,
  U extends Record<K, B>
>(
  t: T,
  u: U
): Record<string, A & B> => {
  const keys = Object.keys(t);
  const result: Record<string, any> = {};
  for (const key of keys) {
    result[key] = {
      ...(t[key] ? u[key] : {}),
      ...(u[key] ? u[key] : {}),
    };
  }
  return result;
};
