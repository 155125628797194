import { ChangeEvent } from 'react';
import {
  ariaDescribedByIds,
  labelValue,
  FormContextType,
  RJSFSchema,
  StrictRJSFSchema,
  WidgetProps,
} from "@rjsf/utils";
import { Text, Textarea } from "@mantine/core";
import { createErrors } from "../utils/createErrors";

/** The `TextareaWidget` is a widget for rendering input fields as textarea.
 *
 * @param props - The `WidgetProps` for this component
 */
export default function TextareaWidget<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any,
>(props: WidgetProps<T, S, F>) {
  const {
    id,
    placeholder,
    value,
    required,
    disabled,
    autofocus,
    label,
    hideLabel,
    readonly,
    onBlur,
    onFocus,
    onChange,
    options,
    rawErrors,
    hideError,
  } = props;

  const _onChange = ({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) =>
    onChange && onChange(value === '' ? options.emptyValue : value);
  const _onBlur = () => onBlur && onBlur(id, value);
  const _onFocus = () => onFocus && onFocus(id, value);
  return (
    <Textarea
      id={id}
      key={id}
      name={id}
      className="armt-widget-textarea"
      size="lg"
      label={
        <>
          <Text>
            {labelValue(label || undefined, hideLabel, false)}
            <Text component="span" c="red">
              {/* We have to put the star ourselves here, because using the Textarea required triggers the browser validation first, and prevents complete form validation */}
              {required ? <span color="red"> *</span> : ""}
            </Text>{" "}
          </Text>
        </>
      }
      placeholder={placeholder}
      autoFocus={autofocus}
      disabled={disabled || readonly}
      value={value || ''}
      error={createErrors<T>(rawErrors, hideError)}
      rows={options.rows || 5}
      onChange={_onChange}
      onBlur={_onBlur}
      onFocus={_onFocus}
      aria-describedby={ariaDescribedByIds<T>(id)}
      styles={{ label: { fontWeight: 400, fontSize: "1em", paddingBottom: 8 } }}
    />
  );
}
