import { HubAuth } from "@pillar/auth/hub/client/HubAuth";
import { useHubAuthStore } from "@pillar/auth/hub/client/HubAuth.store";
import { useHubConfigStore } from "@pillar/config/hub/client/HubConfig/index.store";
import { PropsWithRequiredChildren } from "@pillar/std/types/react";
import { FC } from "react";
import { useHubMiniSession } from "./autologin.hooks";

export const Autologin: FC<PropsWithRequiredChildren<{ pid: string }>> = (
  p
) => {
  const authStore = useHubAuthStore();
  const hub = useHubConfigStore();

  const localSession = useHubMiniSession(hub.derived.config.session);

  if (!localSession.config.shouldAutologing) {
    localSession.setAutoLogin(true);
  }

  if (!authStore.state.token && localSession.session && localSession.valid) {
    authStore.ops.trackLogin.run({
      pid: p.pid,
      email: localSession.session.email,
    });
    // we do not update the session currently
    // .then(() => {
    //   invariant(localSession.session, "Should not be undefined");
    //   localSession.setMiniSession({
    //     at: new Date(),
    //     email: localSession.session.email,
    //   });
    // });

    return null;
  }

  return <HubAuth pid={p.pid}>{p.children}</HubAuth>;
};
