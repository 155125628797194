import {
  PathwayFeedbackBar,
  MobilePathwayFeedbackBar,
} from "@pillar/v3/feedbacks/hub/client/MantineResourceFeedback";

import { FC } from "react";

import { ButtonVariant } from "@mantine/core";

export const PathwayFeedback: FC<{
  buttonText?: string;
  buttonVariant?: ButtonVariant;
}> = (props) => {
  return <PathwayFeedbackBar {...props} />;
};

export const MobilePathwayFeedback: FC<{ buttonText?: string }> = (props) => {
  return <MobilePathwayFeedbackBar buttonVariant={"filled"} {...props} />;
};
