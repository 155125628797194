import { ClientStore, useStore } from "@pillar/client/store";

import { proxy } from "valtio";
import { derive } from "valtio/utils";
import {
  $SupportedInputLangs,
  $SupportedOutputLangs,
} from "@pillar/v3/accessibility/translation/translation.types";
import { z } from "zod";

const langURLParams = z.object({
  preferredLang: z
    .union([
      z.nativeEnum($SupportedOutputLangs),
      z.nativeEnum($SupportedInputLangs),
    ])
    .optional(),
});

const layoutURLParams = z.object({
  isEmbed: z
    .string()
    .optional()
    .transform((s) => s === "true"),
});

const authURLParams = z.object({
  skipLogin: z
    .string()
    .optional()
    .transform((s) => s === "true"),
  userID: z.string().optional(),
  organizationToken: z.string().optional(),
});

const FlatURLParams = langURLParams.merge(authURLParams).merge(layoutURLParams);
const URLParams = langURLParams.merge(
  z.object({
    auth: authURLParams.optional(),
    layout: layoutURLParams.optional(),
  }),
);

type URLParams = z.infer<typeof URLParams>;

type State = URLParams & { hasLoadedParams: boolean };

export const state = proxy<State>({ hasLoadedParams: false });

export const actions = {
  setPreferredLang(
    preferredLang: $SupportedOutputLangs | $SupportedInputLangs,
  ) {
    state.preferredLang = preferredLang;
  },
  setAuth(auth: {
    skipLogin: boolean;
    userID: string;
    organizationToken: string;
  }) {
    state.auth = auth;
  },
  setLayout(layout: {
    isEmbed: boolean;
  }) {
    state.layout = layout;
  },
};
export const ops = {
  loadParams: (searchParams: URLSearchParams) => {
    const paramsAsObject = Object.fromEntries(searchParams.entries());

    const parsed = FlatURLParams.safeParse(paramsAsObject);

    if (parsed.success) {
      const preferredLang = parsed.data.preferredLang;
      const isEmbed = parsed.data.isEmbed;
      const skipLogin = parsed.data.skipLogin;
      const userID = parsed.data.userID;
      const organizationToken = parsed.data.organizationToken;

      if (preferredLang) actions.setPreferredLang(preferredLang);
      if (skipLogin && userID && organizationToken)
        actions.setAuth({
          skipLogin: skipLogin,
          userID,
          organizationToken,
        });
      actions.setLayout({ isEmbed });
    }

    state.hasLoadedParams = true;
  },
};

export const derived = derive({
  isEmbed: (get) => get(state).layout?.isEmbed,
  skipLogin: (get) => get(state).auth?.skipLogin,
  allParamsToURLString: (get) => {
    const preferredLang = get(state).preferredLang;

    const auth = get(state).auth;
    const authSkipLogin = get(state).auth?.skipLogin;
    const authUserID = get(state).auth?.userID;
    const authOrganizationToken = get(state).auth?.organizationToken;

    const layoutIsEmbed = get(state).layout?.isEmbed;

    const params: {
      isEmbed?: string;
      skipLogin?: string;
      userID?: string;
      organizationToken?: string;
      preferredLang?: string;
    } = {};

    if (preferredLang !== undefined) params.preferredLang = preferredLang;
    if (auth !== undefined) {
      params.isEmbed = layoutIsEmbed ? "true" : "false";
      params.skipLogin = authSkipLogin ? "true" : "false";
      params.userID = authUserID;
      params.organizationToken = authOrganizationToken;
    }
    const urlSearchParams = new URLSearchParams({ ...params }).toString();

    return urlSearchParams;
  },
});

export const sagas = {};

export const HubURLParamsStore = {
  state,
  derived,
  ops,
  sagas,
} satisfies ClientStore;

export const useHubURLParamsStore = () => {
  return useStore(HubURLParamsStore);
};
