import { FC, PropsWithChildren, useEffect, useRef, useState } from "react";
import { Spoiler as MantineSpoiler, SpoilerProps } from "@mantine/core";

export const Spoiler: FC<
  PropsWithChildren<SpoilerProps & { maxLines?: number }>
> = (p) => {
  // Gradient created from this code
  // https://codesandbox.io/p/sandbox/mantine-spoiler-conditional-styles-xsthg3?file=%2Fsrc%2FApp.tsx%3A26%2C9-36%2C11
  const [isOpen, setIsOpen] = useState(false);
  const maxLines = p.maxLines ?? 3;

  return (
    <MantineSpoiler
      maxHeight={maxLines * 28}
      showLabel={p.showLabel}
      hideLabel={p.hideLabel}
      pos="relative"
      styles={{
        control: {
          position: "absolute",
          right: 0,
          width: "fit-content",
          marginLeft: "auto",
          paddingLeft: "18px",
          textAlign: "end",
          marginTop: !isOpen ? "  -28px" : undefined,
          background: !isOpen ? "white" : undefined,
        },
      }}
      onClick={(e) => {
        if (!e.target) return;
        const target = e.target as HTMLButtonElement;
        const isToggleButton = target?.className.includes(
          "mantine-Spoiler-control"
        );
        if (isToggleButton) {
          const { textContent } = target;
          setIsOpen(textContent === p.showLabel);
        }
      }}
      mb={!isOpen ? 0 : 32}
    >
      {p.children}
    </MantineSpoiler>
  );
};
