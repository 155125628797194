import {
  $ResourceKind,
  $ResourceMimeType,
  Legacy_ResourceType,
} from "@pillar/v3/resources/resources.types";
import { get } from "./objects";
import { Option } from "./types/option";

/** @deprecated */
export const validString = (v: Option<string>): v is string =>
  typeof v === "string" && v.length > 0;

export const looksLikeEmail = (v: string) =>
  validString(v) && v.includes("@") && v.split("@")[1].includes(".");

export const validStringOr = (v: Option<string>, or: string): string => {
  return v && validString(v?.trim() ?? "") ? v : or;
};

export const validStringOrDefaults = (v: Option<string>, or: any): any => {
  return v && validString(v?.trim() ?? "") ? v : or;
};
export const slugifyString = (s: string) =>
  s
    .toString()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .trim()
    .replace(/\s+/g, "-")
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, "-");

/**
 * Render template with appropriate data from an organization's configuration.
 * hub.resource.type can be a v2 type, a v3 kind, or a v3 mimeType
 * @param template
 * @param data
 * @returns
 */
export const renderTemplate = (template: string, data: Record<string, any>) => {
  return (
    template?.replace(/\{\{([^}]+)\}\}/g, (_, key) => {
      const variable = get(data, key.trim(), "") ?? "";

      // TODO make this change from callee
      if (key.trim() === "hub.resource.type") {
        // Handles v3 kind and mimeType, and v2 type
        if (
          variable === Legacy_ResourceType.service ||
          variable === $ResourceKind.document ||
          variable === $ResourceMimeType.landingPage ||
          variable === $ResourceMimeType.enrollmentPage
        ) {
          return "service";
        }

        if (
          variable === Legacy_ResourceType.video ||
          variable === $ResourceMimeType.mov ||
          variable === $ResourceMimeType.mp4
        ) {
          return "video";
        }
        if (
          variable === Legacy_ResourceType.document ||
          variable === $ResourceKind.file ||
          variable === $ResourceMimeType.pdf
        ) {
          return "document";
        }

        if (
          variable === Legacy_ResourceType.form ||
          variable === $ResourceKind.form ||
          variable === $ResourceMimeType.form
        ) {
          return "form";
        }

        if (
          variable === Legacy_ResourceType.pathway ||
          variable === $ResourceKind.pathway ||
          variable === $ResourceMimeType.pathway
        ) {
          return "pathway";
        }

        if (
          variable === Legacy_ResourceType.repo ||
          variable === $ResourceKind.repo ||
          variable === $ResourceMimeType.repo
        ) {
          return "repository";
        }

        return "resource";
      }
      return variable;
    }) ?? ""
  );
};

export const validateTemplate = (template: string) => {
  return (
    template.replace(/\{\{([^}]+)\}\}/g, (pathWithBrackets, path) => {
      const trimmedPath = path.trim();
      const pathSteps = trimmedPath.split(".");
      // FIXME right now users can use `organization.name` or `hub.organization.name`
      // we are making sure here that the `hub.` prefix is here
      // except for the `resource.XXX`, which are custom and not part of the config
      const validatedPath = pathSteps?.[0] !== "hub" ? "hub." + path : path;
      return `{{${validatedPath}}}`;
    }) ?? ""
  );
};

export const pluralize = (count: number, singular: string, plural: string) => {
  if (count > 1) {
    return plural;
  }

  return singular;
};

export const normalizeUrl = (url: string) => {
  if (!url) {
    return "";
  }

  if (!(url.startsWith("http://") || url.startsWith("https://"))) {
    return "https://" + url;
  }

  return url;
};
