import { Card, Center, Group, Title } from "@mantine/core";
import { Check } from "lucide-react";
import { FC, ReactNode } from "react";

export const FormSubmissionCard: FC<{
  onClose: () => void;
  actions?: ReactNode;
}> = (p) => {
  return (
    <Card withBorder>
      <Center w="100%">
        <Group>
          <Group>
            <Check />
            <Title order={4}>Thanks for your submission!</Title>
          </Group>
          {p.actions}
        </Group>
      </Center>
    </Card>
  );
};
