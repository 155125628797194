import { ReactNode } from "react";
import { Center, Flex, Image, HStack, StackProps } from "@chakra-ui/react";
import { RiFileList3Line, RiPlayCircleFill } from "react-icons/ri";
import { LuListTodo } from "react-icons/lu";
import { BsFillFileTextFill } from "react-icons/bs";

import { CompactResourceAggregate } from "@pillar/resources/types";
import { ResourceTypeTag } from "@pillar/resources/types.legacy";
import { PublicURLCacheStore } from "@pillar/uploads/client/publicUrlCacheStore";
import { FiExternalLink } from "react-icons/fi";

export enum ResourceThumbnailSize {
  small,
  medium,
  large,
}

export const ResourceThumbnailTopAddons = (p: StackProps) => {
  return <HStack {...p} position="absolute" top={2} left={2} right={2} />;
};

export const ResourceThumbnail = (p: {
  resource: CompactResourceAggregate;
  cache: PublicURLCacheStore;
  size?: ResourceThumbnailSize;
  topAddons?: ReactNode;
  bottomAddons?: ReactNode;
}) => {
  const size = p.size ?? ResourceThumbnailSize.small;

  return (
    <Flex
      position="relative"
      minW={size === ResourceThumbnailSize.large ? "full" : undefined}
    >
      {!p.resource.thumbnail ? (
        <ResourceThumbnailFallback
          type={p.resource.types?.[0] ?? ""}
          size={size}
        />
      ) : (
        <Image
          boxSize={
            size === ResourceThumbnailSize.small
              ? "5em"
              : size === ResourceThumbnailSize.medium
                ? "7em"
                : "full"
          }
          h={
            size === ResourceThumbnailSize.small
              ? "3.5em"
              : size === ResourceThumbnailSize.medium
                ? "5em"
                : "12em"
          }
          objectFit="cover"
          objectPosition="center"
          borderRadius={"md"}
          src={`${
            p.cache?.getOrCache(
              `${p.resource.id}-thumbnail-compose`,
              p.resource.thumbnail,
            ) ?? p.resource.thumbnail
          }`}
        />
      )}

      {p.topAddons ? p.topAddons : null}
      {p.bottomAddons ? p.bottomAddons : null}
    </Flex>
  );
};

export const ResourceThumbnailFallback = (p: {
  type: ResourceTypeTag;
  size?: ResourceThumbnailSize;
}) => {
  const size = p.size ?? ResourceThumbnailSize.small;

  return (
    <Center
      borderRadius={"md"}
      w={
        size === ResourceThumbnailSize.small
          ? "5em"
          : size === ResourceThumbnailSize.medium
            ? "7em"
            : "full"
      }
      h={
        size === ResourceThumbnailSize.small
          ? "3.5em"
          : size === ResourceThumbnailSize.medium
            ? "5em"
            : "12em"
      }
      bg="gray.100"
    >
      {p.type === ResourceTypeTag.video ? (
        <RiPlayCircleFill
          color={"brand.500"}
          size={size === ResourceThumbnailSize.large ? "3em" : "1.5em"}
        />
      ) : p.type === ResourceTypeTag.document ? (
        <BsFillFileTextFill
          color={"brand.500"}
          size={size === ResourceThumbnailSize.large ? "3em" : "1.5em"}
        />
      ) : p.type === ResourceTypeTag.form ? (
        <RiFileList3Line
          color={"brand.500"}
          size={size === ResourceThumbnailSize.large ? "3em" : "1.5em"}
        />
      ) : p.type === ResourceTypeTag.service ? (
        <FiExternalLink
          color={"brand.500"}
          size={size === ResourceThumbnailSize.large ? "3em" : "1.5em"}
        />
      ) : p.type === ResourceTypeTag.pathway ? (
        <LuListTodo
          color={"brand.500"}
          size={size === ResourceThumbnailSize.large ? "3em" : "1.5em"}
        />
      ) : (
        <BsFillFileTextFill
          color={"brand.500"}
          size={size === ResourceThumbnailSize.large ? "3em" : "1.5em"}
        />
      )}
    </Center>
  );
};
