import { $Nullable, valueOrNullable } from "./types/nullable";

/**
 * Returns the first element of the array or null if it's undefined.
 *
 * @param {Array} arr - The array to get the first element from.
 * @returns {$Nullable<T>} - The first element of the array or null.
 */
export const firstOrNullable = <T>(
  arr: (T | undefined | null)[]
): $Nullable<T> => {
  return valueOrNullable(arr[0]);
};

/**
 * Checks if the value is valid (not null or undefined).
 *
 * @param {Option<T>} v - The value to check.
 * @returns {boolean} - True if the value is valid, false otherwise.
 */
export const onlyValid = <T>(v: $Nullable<T> | undefined): v is T => Boolean(v);

/**
 * Filters out invalid values from an array.
 *
 * @param v - An array of nullable or undefined values.
 * @returns An array of valid values.
 * @template T - The type of values in the array.
 */
export const filterInvalid = <T>(v: ($Nullable<T> | undefined)[]): T[] =>
  v.filter(onlyValid);

/**
 * Type representing a non-empty array in TypeScript.
 * This type ensures that the array has at least one element.
 *
 * @template T The type of elements in the array.
 */
type NonEmptyArray<T> = [T, ...T[]];

/**
 * Checks if an array has at least one element.
 *
 * @param v - The array to check.
 * @returns `true` if the array has at least one element, `false` otherwise.
 * @typeParam T - The type of elements in the array.
 */
export const atLeastOneElement = <T>(
  v: ($Nullable<T> | undefined)[]
): v is NonEmptyArray<T> => (!v ? false : v.length > 0);

/**
 * Returns a non-null and non-empty array if the input array is not null or undefined.
 * Otherwise, returns an empty array.
 *
 * @param {T[] | null | undefined} arr - The input array.
 * @returns {T[]} - The non-null and non-empty array, or an empty array.
 */
export const emptyArrayIfNullable = <T>(v: $Nullable<T[]> | undefined): T[] => {
  return !v ? [] : v;
};

export const asyncReplaceMany = async <I, O extends I>(
  arr: I[],
  fn: (v: I[]) => Promise<O[]>,
  condition: (v: I) => boolean
): Promise<O[]> => {
  if (arr.length === 0) {
    return [];
  }
  const toReplace: I[] = arr.filter(condition);
  const replaced = await fn(toReplace);
  let i = 0;
  return arr.map((v) => {
    if (condition(v)) {
      const result = replaced[i];
      i++;
      return result;
    }
    return v as O;
  });
};

/**
 * A function for `deepmerge` `arrayMerge` options.
 *
 * @param destinationArray
 * @param sourceArray
 * @returns A merge array with no duplicated values.
 */
export const removeDuplicates = <T extends any>(
  destinationArray: T[],
  sourceArray: T[]
) => {
  return Array.from(new Set([...destinationArray, ...sourceArray]).values());
};
