import { Alert, Flex, Input, Rating, Stack, Text } from "@mantine/core";
import { labelValue, WidgetProps } from "@rjsf/utils";
import { Star, StarIcon } from "lucide-react";
import { createErrors } from "../rjsf-mantine/utils/createErrors";

export const RatingWidget = (props: WidgetProps) => {
  const {
    id,
    placeholder,
    value,
    required,
    disabled,
    autofocus,
    label,
    hideLabel,
    readonly,
    onBlur,
    onFocus,
    onChange,
    options,
    rawErrors,
    hideError,
    uiSchema,
    schema,
  } = props;

  const starSize = "2.25em";
  const starStroke = "1.4px";

  if (
    uiSchema?.["ui:props"]?.lowerBound === undefined ||
    uiSchema?.["ui:props"]?.upperBound === undefined
  )
    return <Alert color={"orange"}>Impossible to load this question.</Alert>;

  return (
    <Input.Wrapper
      id={id}
      key={id}
      label={labelValue(label || undefined, hideLabel, false)}
      className={"armt-rating-widget"}
      autoFocus={autofocus}
      required={required}
      error={createErrors(rawErrors, hideError)}
      styles={{ label: { fontWeight: 400, fontSize: "1em", paddingBottom: 8 } }}
    >
      <Stack maw="240px" gap={"xs"}>
        <Rating
          id={"form-rating"}
          value={value}
          onChange={(rating: number) => onChange(rating)}
          emptySymbol={<StarIcon strokeWidth={starStroke} size={starSize} />}
          fullSymbol={<Star fill="currentColor" size={starSize} />}
          w="100%"
          styles={{ root: { justifyContent: "space-between" } }}
        />
        <Flex justify={"space-between"}>
          <Text size={"sm"}>{uiSchema?.["ui:props"]?.lowerBound}</Text>
          <Text size={"sm"} ta="right">
            {uiSchema?.["ui:props"]?.upperBound}
          </Text>
        </Flex>
      </Stack>
    </Input.Wrapper>
  );
};
