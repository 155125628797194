import { useDisclosure } from "@mantine/hooks";
import { APIFormSubmission } from "../../forms.types";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";

export const useHubFormSubmission = (p: {
  sectionId: string;
  submitMutation: (
    sectionId: string,
    formData: APIFormSubmission
  ) => Promise<void>;
}) => {
  const [isSuccessModalOpen, { open, close }] = useDisclosure();
  const [isErrorModalOpen, { open: openError, close: closeError }] =
    useDisclosure();
  const [showLiveValidation, setShowLiveValidation] = useState(false);

  const mutation = useMutation(
    ["submit-form", p.sectionId],
    async (mutationProps: {
      payload: { formData?: APIFormSubmission };
      sectionId: string;
    }) => {
      if (mutationProps.payload.formData) {
        await p
          .submitMutation(p.sectionId, mutationProps.payload.formData)
          .then(
            (data) => open(),
            (error) => {
              openError();
            }
          );
      }
    }
  );
  const onSubmit = async (payload: { formData?: APIFormSubmission }, event) => {
    if (payload.formData) {
      await mutation.mutateAsync({ payload, sectionId: p.sectionId });
    }
  };

  return {
    successModal: {
      isSuccessModalOpen,
      onCloseSuccessModal: close,
    },
    errorModal: {
      isErrorModalOpen,
      onCloseErrorModal: closeError,
    },
    liveValidation: {
      show: showLiveValidation,
      activate: () => setShowLiveValidation(true),
    },
    onSubmit,
    isSubmitting: mutation.isLoading,
  };
};
