import { FC } from "react";
import { Badge, Flex, FlexProps } from "@mantine/core";
import { ResourceTypeTag } from "@pillar/resources/types.legacy";

/** @deprecated */
export const Tags: FC<{
  tags: string[];
  type: ResourceTypeTag;
  maxTags?: number;
  wrap?: FlexProps["wrap"];
  showType?: boolean;
}> = (p) => {
  const showType = p.showType === undefined ? true : p.showType;
  const tagsList = showType ? [p.type, ...p.tags] : p.tags;
  return (
    <Flex
      gap={"0.25rem"}
      p={0}
      wrap={p.wrap ?? "nowrap"}
      style={{
        overflowX:
          p.wrap !== "wrap" && p.maxTags && p.maxTags > 2 ? "auto" : "hidden",
      }}
    >
      {tagsList.slice(0, p.maxTags ?? 4).map((tag) => (
        <Badge
          key={tag}
          style={{
            textTransform: "capitalize",
            fontWeight: 500,
            overflow: "visible",
          }}
          size="md"
          // TODO don't hardcode these colors
          bg="#e4e8ee"
          c="#384051"
        >
          {tag}
        </Badge>
      ))}
    </Flex>
  );
};
