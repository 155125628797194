const bulkAddEventListener = (object, events, callback) => {
  events.forEach((event) => {
    object.addEventListener(event, callback);
  });
};

const bulkRemoveEventListener = (object, events, callback) => {
  events.forEach((event) => {
    object.removeEventListener(event, callback);
  });
};

const defaultOptions: Options = {
  idle: 10000, // idle time in ms
  events: ["mousemove", "scroll", "keydown", "mousedown", "touchstart"], // events that will trigger the idle resetter

  onIdle: null, // callback function to be executed after idle time
  onActive: null, // callback function to be executed after back form idleness

  onHide: null, // callback function to be executed when window become hidden or closed
  onShow: null, // callback function to be executed when window become visible

  onFocus: null,
  onBlur: null,

  keepTracking: true, // set it to false of you want to track only once
  startAtIdle: false, // set it to true if you want to start in the idle state
  recurIdleCall: false,
};

type UserInteractionEvents =
  | "mousemove"
  | "scroll"
  | "keydown"
  | "mousedown"
  | "touchstart";

type VisibilityEvents =
  | "visibilitychange"
  | "webkitvisibilitychange"
  | "mozvisibilitychange"
  | "msvisibilitychange";

type Options = {
  idle: number; // idle time in ms
  events: UserInteractionEvents[]; // events that will trigger the idle resetter
  onIdle: (() => void) | null; // callback function to be executed after idle time
  onActive: (() => void) | null; // callback function to be executed after back form idleness
  onHide: (() => void) | null; // callback function to be executed when window become hidden
  onShow: (() => void) | null; // callback function to be executed when window become visible
  onBlur: (() => void) | null; // callback function to be executed when window become hidden
  onFocus: (() => void) | null; // callback function to be executed when window become visible
  keepTracking: boolean; // set it to false of you want to track only once
  startAtIdle: boolean; // set it to true if you want to start in the idle state
  recurIdleCall: boolean;
};

export class ClientWindowEvents {
  settings: Options = defaultOptions;

  /** currently idle? */
  idle = false;

  /** currently visible? */
  visible = false;
  active = true;

  visibilityEvents: VisibilityEvents[] = [
    "visibilitychange",
    "webkitvisibilitychange",
    "mozvisibilitychange",
    "msvisibilitychange",
  ];

  clearTimeout: (() => void) | null = null;

  constructor(options: Partial<Options>) {
    this.settings = {
      ...this.settings,
      ...options,
    };
    this.reset();

    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onCloseHandler = this.onCloseHandler.bind(this);
    this.visibilityEventsHandler = this.visibilityEventsHandler.bind(this);
    this.idlenessEventsHandler = this.idlenessEventsHandler.bind(this);
  }

  private visibilityEventsHandler() {
    if (
      document.hidden ||
      // @ts-expect-error
      document.webkitHidden ||
      // @ts-expect-error
      document.mozHidden ||
      // @ts-expect-error
      document.msHidden
    ) {
      if (this.visible) {
        this.visible = false;
        this.settings.onHide?.();
      }
    } else {
      if (!this.visible) {
        this.visible = true;
        this.settings.onShow?.();
      }
    }
  }

  private onCloseHandler() {
    if (typeof window !== "undefined") {
      this.settings.onHide?.();
    }
  }

  private setupOnClose() {
    window.addEventListener("beforeunload", this.onCloseHandler);
  }

  private removeOnClose() {
    window.removeEventListener("beforeunload", this.onCloseHandler);
  }

  private onBlur() {
    this.active = false;
    this.settings.onBlur?.();
  }

  private onFocus() {
    this.active = true;
    this.settings.onFocus?.();
  }

  private idlenessEventsHandler(event) {
    if (this.idle) {
      this.idle = false;
      this.settings.onActive?.();
    }

    this.resetTimeout();
  }

  private stopListener() {
    this.stop();
  }

  // public API

  resetTimeout(keepTracking = this.settings.keepTracking) {
    if (this.clearTimeout) {
      this.clearTimeout();
      this.clearTimeout = null;
    }
    if (keepTracking) {
      this.timeout();
    }
  }

  timeout() {
    const timer = this.settings.recurIdleCall
      ? {
          set: setInterval.bind(window),
          clear: clearInterval.bind(window),
        }
      : {
          set: setTimeout.bind(window),
          clear: clearTimeout.bind(window),
        };

    const id = timer.set(
      function () {
        this.idle = true;
        this.settings.onIdle && this.settings.onIdle.call();
      }.bind(this),
      this.settings.idle
    );

    this.clearTimeout = () => timer.clear(id);
  }

  start() {
    window.addEventListener("idle:stop", this.stopListener);
    this.timeout();

    bulkAddEventListener(
      window,
      this.settings.events,
      this.idlenessEventsHandler
    );

    if (this.settings.onShow || this.settings.onHide) {
      bulkAddEventListener(
        document,
        this.visibilityEvents,
        this.visibilityEventsHandler
      );

      this.setupOnClose();
    }

    if (this.settings.onBlur || this.settings.onFocus) {
      window.addEventListener("blur", this.onBlur);
      window.addEventListener("focus", this.onFocus);
    }

    return this;
  }

  stop() {
    window.removeEventListener("idle:stop", this.stopListener);

    bulkRemoveEventListener(
      window,
      this.settings.events,
      this.idlenessEventsHandler
    );
    this.resetTimeout(false);

    if (this.settings.onShow || this.settings.onHide) {
      bulkRemoveEventListener(
        document,
        this.visibilityEvents,
        this.visibilityEventsHandler
      );

      this.removeOnClose();
    }

    if (this.settings.onBlur || this.settings.onFocus) {
      window.removeEventListener("blur", this.onBlur);
      window.removeEventListener("focus", this.onFocus);
    }

    return this;
  }

  reset({
    idle = this.settings.startAtIdle,
    visible = !this.settings.startAtIdle,
  } = {}) {
    this.idle = idle;
    this.visible = visible;

    return this;
  }
}
