import { Stack } from "@mantine/core";
import { AppPageMain, MobileAppPage } from "@pillar/ui/obsidian/layout";
import { FC, useEffect } from "react";
import { MobileExplorerPageHeader } from "./ExplorerHomePageHeader";
import { useExplorerSearch } from "./ExplorerSearch.store";
import { MobileHubExplorerSearchAndFilters } from "./MobileSearchBarAndFilterButton";
import { MobileExplorerResultSection } from "./MobileExplorerResultSection";
import { NoResults } from "./NoResults";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useHubURLParamsStore } from "@pillar/v3/client/urls/params.hub.store";

export const MobileHubExplorerScreen: FC<{ repositoryPid: string }> = (p) => {
  const { ops, state, sagas, selectors } = useExplorerSearch();
  const router = useNavigate();

  const {
    derived: { isEmbed, allParamsToURLString },
  } = useHubURLParamsStore();

  const onNavigate = (path) =>
    router({
      pathname: path,
      search: allParamsToURLString,
    });

  useEffect(() => {
    sagas.init(p.repositoryPid);
  }, [sagas.init, p.repositoryPid]);

  return (
    <MobileAppPage padding={0}>
      {!isEmbed && <MobileExplorerPageHeader />}
      <AppPageMain
        p={isEmbed ? 0 : undefined}
        style={{
          overflowY: "visible",
          marginTop: "2.15em",
        }}
      >
        <Stack gap="0">
          <MobileHubExplorerSearchAndFilters />
          <Stack p="md">
            {state.noResults && <NoResults />}
            <MobileExplorerResultSection
              onSelectResource={ops.navigateToService(
                p.repositoryPid,
                onNavigate
              )}
              title="Services"
              getResults={selectors.onlyServices}
            />
            <MobileExplorerResultSection
              onSelectResource={ops.navigateToFile(p.repositoryPid, onNavigate)}
              title="Content"
              getResults={selectors.onlyContent}
            />
            <MobileExplorerResultSection
              onSelectResource={ops.navigateToPathway(
                p.repositoryPid,
                onNavigate
              )}
              title="Pathways"
              getResults={selectors.onlyPathways}
            />
          </Stack>
        </Stack>
      </AppPageMain>
    </MobileAppPage>
  );
};
