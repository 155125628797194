import { HubRouter } from "@pillar/hub/client/router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <HubRouter />
    </QueryClientProvider>
  );
};
