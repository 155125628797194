import { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Portal,
  Transition,
  LoadingOverlay,
} from "@mantine/core";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { useHubFlags } from "@pillar/config/hub/client/useFlags";

// pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

// const options = {
//   cMapUrl: "cmaps/",
//   standardFontDataUrl: "standard_fonts/",
// };

// export const useParentWidth = () => {
//   const ref = useRef<HTMLDivElement | null>(null);
//   const [w, setW] = useState<number | null>(null);

//   // FIXME our `debounce()` is unfortunately making the resize not work
//   // this means however that rerendering is not optimal when resizing
//   const adjustWidth = () => {
//     if (!ref || ref?.current === null) return;

//     // const actualRef = document.documentElement ref.current;
//     const actualRef = ref.current;
//     const newParentWidth = ref.current.clientWidth;
//     setW(newParentWidth);
//   };

//   useLayoutEffect(() => {
//     adjustWidth();
//     // only resize for desktop

//     const mql = window.matchMedia("(orientation: portrait)");
//     mql.addEventListener("change", adjustWidth);

//     if (window.innerWidth > 760) {
//       window.addEventListener("resize", adjustWidth);
//       // add orientation changed events

//       return () => {
//         mql.removeEventListener("change", adjustWidth);
//         window.removeEventListener("resize", adjustWidth);
//       };
//     }
//     return () => {
//       mql.removeEventListener("change", adjustWidth);
//     };
//   }, [adjustWidth]);
//   const computedWidth = (w ?? 0) - (5 / 100) * (w ?? 0);

//   return {
//     ref,
//     width: computedWidth > 1024 ? 1024 : computedWidth,
//   };
// };

// export const PrintStub = (props: { href: string }) => {
//   const [currentWidth, setCurrentWidth] = useState(0);

//   useEffect(() => {
//     const handleResize = () => {
//       setCurrentWidth(window.innerWidth);
//     };

//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   const [numPages, setNumPages] = useState(0);

//   if (!currentWidth) return null;
//   return (
//     <div
//       style={{
//         display: "block",
//         top: 0,
//         left: 0,
//         bottom: 0,
//         right: 0,
//         position: "fixed",
//         zIndex: 5000,
//         backgroundColor: "white",
//       }}
//     >
//       <Document
//         file={props.href}
//         className={"pdf-document"}
//         loading={<Box />}
//         onLoadSuccess={({ numPages }) => setNumPages(numPages)}
//         options={options}
//       >
//         {Array.from(new Array(numPages), (el, index) => (
//           <Box
//             style={{
//               pageBreakAfter: "always",
//             }}
//             key={`page_${index + 1}`}
//           >
//             <Page
//               loading={<Box />}
//               width={currentWidth}
//               pageNumber={index + 1}
//             />
//           </Box>
//         ))}
//       </Document>
//     </div>
//   );
// };

// const useInDocumentPrint = () => {
//   const [isPrinting, setIsPrinting] = useState(false);

//   const printPage = () => {
//     window.print();
//   };

//   useEffect(() => {
//     const handleBeforePrint = () => {
//       setIsPrinting(true);
//     };

//     const handleAfterPrint = () => {
//       setIsPrinting(false);
//     };
//     window.addEventListener("beforeprint", handleBeforePrint);
//     window.addEventListener("afterprint", handleAfterPrint);

//     return () => {
//       window.removeEventListener("beforeprint", handleBeforePrint);
//       window.removeEventListener("afterprint", handleAfterPrint);
//     };
//   }, []);

//   return {
//     isPrinting,
//     printPage,
//   };
// };

/*
export const MobilePDFRenderer: FC<{
  href: string;
}> = (p) => {
  const [numPages, setNumPages] = useState(0);
  const { ref, width } = useParentWidth();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const [printMode, setPrintMode] = useState(false);

  useEffect(() => {
    window.onafterprint = () => setPrintMode(false);
  }, []);

  const onPrint = () => {
    setPrintMode(true);

    // setTimeout(() => {
    //   window.print();
    // }, 1000);

    // var newWindow = window.open("", "_blank");
    // const html = `
    // <head>
    //   ${document.head.outerHTML}
    //   </head>
    //   <body>
    //   ${document.querySelector(".react-pdf__Document")?.outerHTML ?? ""}
    //   </body>
    // `;
    // console.info(html);
    // // Write content to the new window (optional)
    // newWindow.document.write(html);
    // newWindow.document.close(); // Ensure the document is fully loaded
    // // Trigger the print command in the new window
    // newWindow.print();
  };

  const fragment = (
    <Document
      file={p.href}
      className={"pdf-document"}
      loading={<Box />}
      onLoadSuccess={onDocumentLoadSuccess}
      options={options}
    >
      {Array.from(new Array(numPages), (el, index) => (
        <Page
          loading={<Box />}
          width={width ?? undefined}
          key={`page_${index + 1}`}
          pageNumber={index + 1}
        />
      ))}
    </Document>
  );

  const printFragment = (
    <Box
      style={{
        visibility: printMode ? "visible" : "hidden",
        pointerEvents: "none",
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 5000,
      }}
    >
      {fragment}
    </Box>
  );

  return (
    <>
      <Portal>{printFragment}</Portal>
      <Flex
        styles={{
          root: {
            flexGrow: 1,
            marginLeft: "auto",
            marginRight: "auto",
          },
        }}
        ref={ref}
        w="100%"
        justify="center"
        bg="gray.2"
      >
        <Box>
          <RenderIfNotHubFlag flag={"disable-hub-print"}>
            <Flex w="100%" py="md" justify="flex-end" bg="gray.2">
              <Button onClick={onPrint}>Print</Button>
            </Flex>
          </RenderIfNotHubFlag>
          {fragment}
        </Box>
      </Flex>
    </>
  );
};

*/

const viewerPdfKey =
  typeof window.location === "undefined"
    ? ""
    : window.location.host.includes("withpillardev.com")
    ? "kxhC095Y7rWAaakuesTN"
    : window.location.host.includes("ts.net")
    ? "pL9Xt6JYNYAzHo3yS6lT"
    : "mw3GN8U5lismNLButNvG";

// export const MobilePDFRenderer: FC<{
//   href: string;
// }> = (props) => {
//   const adobeDivRef = useRef<HTMLDivElement>(null);
//   const [adobeDCView, setAdobeDCView] = useState<any>(null);

//   const loadingState = useScript(
//     "https://acrobatservices.adobe.com/view-sdk/viewer.js"
//   );

//   // useEffect(() => {
//   //   if (!adobeDCView) return;
//   //   adobeDCView.previewFile(
//   //     {
//   //       content: {
//   //         location: {
//   //           url: props.href,
//   //         },
//   //       },
//   //       metaData: {
//   //         fileName: "Document",
//   //       },
//   //       hasReadOnlyAccess: true,
//   //     },
//   //     // {
//   //     //   // showAnnotationTools: false,
//   //     //   // showDownloadPDF: false,
//   //     //   showPrintPDF: true,
//   //     //   showDownloadPDF: false,
//   //     //   showAnnotationTools: false,
//   //     //   // embedMode: "IN_LINE",
//   //     // },
//   //     {
//   //       embedMode: "IN_LINE",
//   //       showPrintPDF: true,
//   //     }
//   //   );
//   // }, [props.href, adobeDCView]);

//   // useEffect(() => {
//   //   const onReady = () => {
//   //     setAdobeDCView(
//   //       new window.AdobeDC.View({
//   //         // @ts-expect-error
//   //         clientId: import.meta.env.VITE_PUBLIC_ADOBE_PDF_EMBED_KEY ?? "",
//   //         divId: "adobe-dc-view",
//   //       })
//   //     );
//   //   };
//   //   document.addEventListener("adobe_dc_view_sdk.ready", onReady);
//   // }, []);

//   return (
//     <>
//      <RenderIfNotHubFlag flag={"disable-hub-print"}>
//        <Button onClick={onPrint}>Print</Button>
//      </RenderIfNotHubFlag>
//     // <div
//     //   id="adobe-dc-view"
//     //   ref={adobeDivRef}
//     //   style={{
//     //     width: "90%",
//     //     flex: 1,
//     //     paddingBottom: "80px",
//     //   }}
//     // ></div>
//     </>
//   );
// };

import WebViewer from "@pdftron/pdfjs-express-viewer";

import { HubInteractionStore } from "@pillar/v3/interactions/hub/client/interaction.store";
import { ResourcePrintedEventKey } from "@pillar/v3/interactions/interaction-types";

export const MobilePDFRenderer = (props: {
  href: string;
  className: string;
}) => {
  // This is necessary to solve the default translation not loading
  // see solution from https://pdfjs.community/t/using-instance-loaddocument-inside-useeffect-to-open-a-file/318
  const [instance, setInstance] = useState<any>(null);
  const viewer = useRef(null);

  const [printLoading, setPrintLoading] = useState(true);
  const [documentLoading, setDocumentLoading] = useState(true);

  const flags = useHubFlags();
  const forcePrint = (newInstance) => {
    HubInteractionStore.recordInteraction({
      type: ResourcePrintedEventKey,
      printed: true,
      sessions: HubInteractionStore.selectSessions(
        (sessions) => sessions.session.sessionType === "resource"
      ),
    });
    setPrintLoading(true);
    newInstance.UI.printInBackground({
      includeComments: true,
      includeAnnotations: true,
      maintainPageOrientation: true,
      onProgress: function (pageNumber, htmlElement) {
        // alert("Printing page " + pageNumber);
      },
    });

    return;
    // if (isPrinting) {
    //   setIsPrinting(false);
    //   instance.UI.cancelPrint();
    //   return;
    // }
    // if (instance) {
    //   setIsPrinting(true);
    //   window.addEventListener(
    //     "beforeprint",
    //     () => {
    //       setIsPrinting(false);
    //     },
    //     { once: true }
    //   );
    //   instance.UI.print();
    //   HubInteractionStore.recordInteraction({
    //     type: ResourcePrintedEventKey,
    //     printed: true,
    //     sessions: HubInteractionStore.selectSessions(
    //       (sessions) => sessions.session.sessionType === "resource"
    //     ),
    //   });
    // }
    // const iframeDoc = instance?.UI.iframeWindow.document;

    // if (!iframeDoc) return;
    // const buttons = iframeDoc.querySelectorAll("button.button");

    // // the existing print button triggers a print dialog we don't want
    // const printButton =
    //   (Array.from(buttons).find((btn: HTMLButtonElement) =>
    //     btn.innerHTML.includes("Print")
    //   ) as HTMLButtonElement | undefined) ?? null;
    // console.info("print automation", printButton);

    // if (printButton) {
    //   console.info("click");
    //   printButton.click();
    // }
    // // if (button) {
    // //   button.click();
    // // }
  };

  const smartResize = (instance) => {
    const width = window.document.body.clientWidth;
    if (width < 768) {
      instance.UI.setFitMode("FitWidth");
    } else {
      instance.UI.setZoomLevel(1.11);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      smartResize(instance);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    WebViewer(
      {
        path: "/pdf",
        initialDoc: props.href,
        licenseKey: viewerPdfKey,
        css: "/pdf/webviewer.css",
      },
      viewer.current
    ).then((newInstance) => {
      setInstance(newInstance);
      const { documentViewer, annotationManager, Annotations } =
        newInstance.Core;
      newInstance.setPrintQuality(2);

      documentViewer.on("documentLoaded", () => {
        smartResize(newInstance);

        if (flags["disable-hub-print"]) return;

        // Disable the original button in the overflow menu
        newInstance.UI.disableElements(["printButton"]);

        newInstance.UI.updateElement("printButton", {
          onClick: () => forcePrint(newInstance),
        });
        // Create our own button
        newInstance.UI.setHeaderItems((header) => {
          const items = header.get("default").getItems();

          const newPrintButton = {
            "data-element": "printButton",
            img: "icon-header-print-line",
            title: "Print",
            index: -1,
            type: "actionButton",
            element: "printButton",
            onClick: () => forcePrint(newInstance),
          };

          const printButtonIndex = items?.findIndex(
            (i) => i.element === "printButton"
          );

          // only splice array when item is found
          if (printButtonIndex !== undefined && printButtonIndex > -1) {
            // if there was a previous button, it needs to be replaced
            // this is necessary for translations, otherwise many print buttons are added
            items?.splice(printButtonIndex, 1, newPrintButton);
          } else {
            items?.push(newPrintButton);
          }

          header.update(items);
        });
      });
    });
  }, [props.href]);

  useEffect(() => {
    if (instance) {
      instance.loadDocument(props.href);
      setDocumentLoading(false);
    }
  }, [props.href, instance]);

  return (
    <>
      <Flex
        // style={{ visibility: loading ? "hidden" : "visible" }}
        className={props.className}
        direction={"column"}
      >
        {documentLoading && (
          <LoadingOverlay visible={documentLoading} zIndex={10} />
        )}
        <div className="webviewer w-full flex-1" ref={viewer} />
      </Flex>
    </>
  );
};
