import {
  Box,
  Card,
  Container,
  Flex,
  Group,
  Portal,
  Stack,
  Title,
} from "@mantine/core";
import { FC, PropsWithChildren, ReactNode } from "react";
import type {
  $BaseResourcePresentation,
  $BaseResourceTaxonomy,
} from "../../resources.types";
import { ResourceMetadata } from "./ResourceMetadataItems";

export const FloatingBottomContainer: FC<PropsWithChildren> = (p) => {
  return (
    <Flex className="absolute bottom-0 w-full pb-6">
      <Container size="lg" flex={1}>
        {p.children}
      </Container>
    </Flex>
  );
};

export const ResourceMetadataCard: FC<{
  resource: $BaseResourcePresentation & $BaseResourceTaxonomy;
  actions?: ReactNode;
}> = (p) => {
  return (
    <Card radius={"md"} flex="none" shadow={"md"} mx={"lg"} mb="lg" p="lg">
      <Group
        justify="space-between"
        preventGrowOverflow={false}
        w="100%"
        gap={"xl"}
        align="stretch"
      >
        <Stack gap="xs" flex={1}>
          {p.resource.name && p.resource.name !== "" && (
            <Title order={3}>{p.resource.name}</Title>
          )}
          <ResourceMetadata
            resource={{ ...p.resource, types: [p.resource.type] }} // TODO fix this after uploader has been merged
            compact
          />
        </Stack>
        <Flex align="start" h="100%" mt={4}>
          {p.actions}
        </Flex>
      </Group>
    </Card>
  );
};
