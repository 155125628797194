// export const fiftyPercentChanceToSucced = async <T>(
//   fn: () => Promise<T>
// ): Promise<T> => {
//   const chance = Math.random();

//   console.info(chance);
//   if (chance > 0.5) {
//     return fn();
//   }
//   throw new Error("Failed");
// };
export const exponentialBackoffRetry = async <T>({
  fn,
  retries = 5,
  delay = 1000,
}: {
  fn: () => Promise<T>;
  retries?: number;
  delay?: number;
}): Promise<T> => {
  try {
    return await fn();
  } catch (error) {
    if (retries > 0) {
      await new Promise((resolve) => setTimeout(resolve, delay));
      return exponentialBackoffRetry({
        fn,
        retries: retries - 1,
        delay: delay * 2,
      });
    }
    throw error;
  }
};
