import { useHubConfigStore } from "@pillar/config/hub/client/HubConfig/index.store";

import {
  AlternateMobileOrganizationLogo,
  AlternateOrganizationLogo,
  MobileOrganizationLogo,
  OrganizationLogo,
} from "@pillar/organizations/hub/client/OrganizationLogo";

import {
  DesktopAppHeader,
  MobileAppPageHeader,
} from "@pillar/ui/obsidian/layout";
import { useHubURLParamsStore } from "@pillar/v3/client/urls/params.hub.store";

import { FC, PropsWithChildren } from "react";

export const DesktopExplorerPageHeader: FC = () => {
  const config = useHubConfigStore().derived.config;
  const theme = config.theme;
  const isPrimary = config.topBar?.mode === "primary";
  const bgColor = isPrimary ? theme["--primary"] ?? "white" : "white";
  const logo = isPrimary ? <AlternateOrganizationLogo /> : <OrganizationLogo />;

  return (
    <DesktopAppHeader
      bg={`hsl(${bgColor})`}
      p={theme["--explorer-top-bar-padding"]}
      withBorder={false}
      logo={logo}
    />
  );
};

export const MobileExplorerPageHeader: FC<
  PropsWithChildren<{ goBack?: () => void }>
> = (p) => {
  const config = useHubConfigStore().derived.config;
  const theme = config.theme;
  const isPrimary = config.topBar?.mode === "primary";
  const bgColor = isPrimary ? theme["--primary"] ?? "white" : "white";
  const logo = isPrimary ? (
    <AlternateMobileOrganizationLogo />
  ) : (
    <MobileOrganizationLogo />
  );

  const {
    derived: { isEmbed },
  } = useHubURLParamsStore();

  return (
    <MobileAppPageHeader
      bg={`hsl(${bgColor})`}
      p={theme["--explorer-top-bar-padding"]}
      logo={p.goBack || isEmbed ? undefined : logo}
      goBack={p.goBack}
    >
      {p.children}
    </MobileAppPageHeader>
  );
};
