import { generateColorsMap } from "@mantine/colors-generator";
import { MantineColorShade } from "@mantine/core";

export interface HSLColor {
  h: number;
  s: number;
  l: number;
}

interface RGBColor {
  r: number;
  g: number;
  b: number;
}

type MantineColor = [
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
];

function hexToRgb(hex: string): RGBColor {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return { r, g, b };
}

export function hslToHex(h: number, s: number, l: number) {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0"); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}
export function hslToString(hslColor: HSLColor): string {
  // This is the Mantine supported hsl format
  return `hsl(${hslColor.h}, ${hslColor.s}%, ${hslColor.l}%)`;
}

export function stringToHSL(color: string): HSLColor | null {
  const hslRegex =
    /^\s*(?:hsl\s*\(\s*)?(\d+(\.\d+)?)\s*[,|\s]+\s*(\d+(\.\d+)?)\s*%\s*[,|\s]+\s*(\d+(\.\d+)?)\s*%\s*(?:\))?/i;
  const hslMatch = color?.match?.(hslRegex);

  if (hslMatch) {
    const hue = parseFloat(hslMatch[1]);
    const saturation = parseFloat(hslMatch[3]);
    const lightness = parseFloat(hslMatch[5]);

    if (!isNaN(hue) && !isNaN(saturation) && !isNaN(lightness)) {
      // Ensure that the parsed values are valid numbers
      return { h: hue, s: saturation, l: lightness };
    }
  }

  return null;
}

export function verifyHslFormat(value: string): string {
  const hslRegex =
    /^\s*(?:hsl\s*\(\s*)?(\d+(\.\d+)?)\s*[,|\s]+\s*(\d+(\.\d+)?)\s*%\s*[,|\s]+\s*(\d+(\.\d+)?)\s*%\s*(?:\))?/i;
  const hslMatch = value.match(hslRegex);

  if (hslMatch) {
    const hue = parseFloat(hslMatch[1]);
    const saturation = parseFloat(hslMatch[3]);
    const lightness = parseFloat(hslMatch[5]);

    if (!isNaN(hue) && !isNaN(saturation) && !isNaN(lightness)) {
      // Ensure that the parsed values are valid numbers
      return hslToString({ h: hue, s: saturation, l: lightness });
    }
  }

  return value;
}
function formatColorToHex(value: string): string | null {
  // Check if the value is in hex format
  const hexRegex = /^#?([a-fA-F0-9]{3}|[a-fA-F0-9]{6})$/;
  if (hexRegex.test(value)) {
    // If it's already in hex format, return it as is
    return value.toLowerCase();
  }

  // Check if the value is in HSL format (accepts comma or space separated values)
  const hslRegex = /^\s*(\d+)\s*[,|\s]+\s*(\d+)\s*%[,\s]+\s*(\d+)\s*%\s*$/i;
  const hslMatch = value.match(hslRegex);
  if (hslMatch) {
    const hue = parseInt(hslMatch[1]);
    const saturation = parseInt(hslMatch[2]);
    const lightness = parseInt(hslMatch[3]);

    // Convert HSL to RGB
    return hslToHex(hue, saturation, lightness);
  }

  // If the value is not in hex or HSL format, return null
  return null;
}

function rgbToHsl(r: number, g: number, b: number): HSLColor {
  // Normalize RGB values to be in the range [0, 1]
  const normalizedR = r / 255;
  const normalizedG = g / 255;
  const normalizedB = b / 255;

  const max = Math.max(normalizedR, normalizedG, normalizedB);
  const min = Math.min(normalizedR, normalizedG, normalizedB);

  let h = 0;
  let s = 0;
  const l = (max + min) / 2;

  if (max !== min) {
    // Calculate saturation
    s = l > 0.5 ? (max - min) / (2 - max - min) : (max - min) / (max + min);

    // Calculate hue
    if (max === normalizedR) {
      h = ((normalizedG - normalizedB) / (max - min) + 6) % 6;
    } else if (max === normalizedG) {
      h = (normalizedB - normalizedR) / (max - min) + 2;
    } else if (max === normalizedB) {
      h = (normalizedR - normalizedG) / (max - min) + 4;
    }

    h *= 60;
  }

  return { h, s: s * 100, l: l * 100 };
}

function formatColorToHsl(value: string): HSLColor | null {
  // Check if the value is in hex format
  const hexRegex = /^#?([a-fA-F0-9]{3}|[a-fA-F0-9]{6})$/;
  if (hexRegex.test(value)) {
    // If it's in hex format, convert it to RGB and then to HSL
    const rgbColor = hexToRgb(value);
    const hslColor = rgbToHsl(rgbColor.r, rgbColor.g, rgbColor.b);
    return hslColor;
  }

  // Check if the value is in HSL format (accepts comma or space separated values)
  const hslRegex =
    /^\s*(?:hsl\s*\(\s*)?(\d+(\.\d+)?)\s*[,|\s]+\s*(\d+(\.\d+)?)\s*%\s*[,|\s]+\s*(\d+(\.\d+)?)\s*%\s*(?:\))?/i;
  const hslMatch = value.match(hslRegex);
  if (hslMatch) {
    const hue = parseFloat(hslMatch[1]);
    const saturation = parseFloat(hslMatch[3]);
    const lightness = parseFloat(hslMatch[5]);

    if (!isNaN(hue) && !isNaN(saturation) && !isNaN(lightness)) {
      // Ensure that the parsed values are valid numbers
      return { h: hue, s: saturation, l: lightness };
    }
  }

  // If the value is not in hex or HSL format, return null
  return null;
}

/**
 * Creates a gradient of color based on the input color. The input color will be put in the middle of the gradient
 * @param color : The base color for the gradient (HSL or Hex). It will be placed in the 7th position.
 * @returns Gradient of 10 HSL colors (a Mantine Color)
 */
export function colorGradientGenerator(color: string): {
  baseColorIndex: MantineColorShade;
  colors: MantineColor;
} {
  const steps = 10;
  let colorSteps: HSLColor[] = [];

  const inputColor = formatColorToHsl(color);
  if (inputColor === null) {
    // Default to a grayscale gradient if the input color is invalid
    for (let i = steps; i > 0; i--) {
      colorSteps.push({ h: 0, s: 0, l: (i / (steps - 1)) * 100 });
    }
  } else {
    const initialLightness = inputColor.l;
    const gradientIndex = 6; // Position to place the input value
    if (initialLightness > 30) return mantineColorGradientGenerator(color);

    const lightColors: HSLColor[] = [];
    const darkColors: HSLColor[] = [];
    let currentLightness = initialLightness;

    // Create (left) light color range
    for (let i = gradientIndex - 1; i >= 0; i--) {
      currentLightness = initialLightness - (gradientIndex - i) * 10;
      if (currentLightness < 0) break;
      const currentColor: HSLColor = {
        h: Math.round(inputColor.h),
        s: Math.round(inputColor.s),
        l: Math.round(currentLightness),
      };
      lightColors.push(currentColor);
    }

    // Create (right) dark color range
    for (let i = gradientIndex; i < steps; i++) {
      currentLightness = initialLightness + (i - gradientIndex) * 10;
      if (currentLightness > 100) break;
      const currentColor: HSLColor = {
        h: Math.round(inputColor.h),
        s: Math.round(inputColor.s),
        l: Math.round(currentLightness),
      };
      darkColors.push(currentColor);
    }

    // Pad with initial color for light range
    while (lightColors.length < gradientIndex) {
      lightColors.push({
        h: Math.round(inputColor.h),
        s: Math.round(inputColor.s),
        l: Math.round(initialLightness),
      });
    }

    // Pad with initial color for dark range
    while (darkColors.length < steps - gradientIndex) {
      darkColors.unshift({
        h: Math.round(inputColor.h),
        s: Math.round(inputColor.s),
        l: Math.round(initialLightness),
      });
    }

    colorSteps = [...lightColors, ...darkColors];
  }

  const hslStrings = colorSteps.map(hslToString).reverse();

  return { colors: hslStrings as MantineColor, baseColorIndex: 6 };
}

/**
 * TODO use this for lighter color
 *
 * Uses native Mantine generator, which behaves badly for dark color
 * Creates a gradient of color based on the input color. The input color will be put in the middle of the gradient
 * @param color : The base color for the gradient (HSL or Hex). It will be placed in the 7th position.
 * @returns Gradient of 10 HSL colors (a Mantine Color)
 */
export function mantineColorGradientGenerator(color: string): {
  baseColorIndex: MantineColorShade;
  colors: MantineColor;
} {
  const { baseColorIndex, colors } = generateColorsMap(color);
  return {
    baseColorIndex: baseColorIndex as MantineColorShade,
    colors: colors.map((c) => c.hex()) as MantineColor,
  };
}
