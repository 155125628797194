import { Stack, Skeleton } from "@mantine/core";

export const FormLoader = () => (
  <Stack gap="2.5em" p="xl">
    <Stack>
      <Skeleton height={110} />
      <Skeleton height={50} />
    </Stack>
    <Stack gap="xl">
      <Skeleton height={150} />
      <Skeleton height={150} />
      <Skeleton height={150} />
      <Skeleton height={150} />
    </Stack>
  </Stack>
);
