import {
  ClientInteractionsStore,
  HubInteractionStore,
} from "@pillar/v3/interactions/hub/client/interaction.store";
import { useSnapshot } from "valtio";

export class ServiceStore {
  constructor(protected interactionStore: ClientInteractionsStore) {}
  recordClickEvent() {
    return this.interactionStore.recordInteractionWithCurrentSession({
      type: "com.withpillar.resource_visited",
    });
  }
}

export const serviceStore = new ServiceStore(HubInteractionStore);

export const useServiceStore = () => useSnapshot(serviceStore);
