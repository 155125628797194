import { WithOrganization } from "@pillar/architect/types";
import { ResourceStateEnum } from "@pillar/v3/data/models/transactional.sql";
import { ResourceAggregate } from "@pillar/resources/types";
import { normalizeUrl } from "@pillar/std/strings";
import { z } from "zod";
import { Uploader } from "@pillar/v3/files/client/Uploader";

export const defaultCtaText: Record<ServiceType, string> = {
  enrollment: "Enroll",
  "landing-page": "View",
};

export const defaultEnrollmentSuccess = {
  modalTitle: "Thanks!",
  modalContent: "You'll hear from a care coordinator soon.",
  badgeText: "You are enrolled",
};

export const APIGetServiceByResourceId = z.object({
  id: z.string(),
  repositoryId: z.string(),
});

export type APIGetServiceByResourceId = z.infer<
  typeof APIGetServiceByResourceId
>;

export type GetServiceByResourceId =
  WithOrganization<APIGetServiceByResourceId>;

export const APIGetService = z.object({
  id: z.string(),
});

export type APIGetService = z.infer<typeof APIGetService>;

export type ServiceAggregate = ResourceAggregate & {
  serviceId: string;
  resourceId: string;
  meta: ApiServiceMetadata;
};

export type ServiceAggregateWithInteractions = ServiceAggregate & {
  interactions: {
    saved: {
      id: string;
      ts: Date;
    } | null;
    clicked: {
      id: string;
      ts: Date;
    } | null;
    liked: {
      id: string;
      ts: Date;
    } | null;
    rated: {
      id: string;
      rating: number;
      ts: Date;
    } | null;
  };
};

type ServiceStats = {
  cohortSize: number;
  viewed: number;
  rated: number;
  completed: number;
};

export type ServiceCompactAggregate = {
  id: string;
  name: string;
  thumbnail: string;
  logoUrl: string;
  description: string;
  state: ResourceStateEnum;
  stats: ServiceStats;
} & (
  | {
      state: ResourceStateEnum.Published;
      pid: string;
      qualifiedLink: string;
    }
  | {
      state: ResourceStateEnum.Private;
    }
);

export const ServiceTypeValue = {
  "landing-page": "landing-page",
  enrollment: "enrollment",
};

export type ServiceType = keyof typeof ServiceTypeValue;

const ServiceMetadataBase = z.object({
  ctaText: z.string().min(1, "Button text is required"),
});

export const ApiLandingPageServiceMetadata = ServiceMetadataBase.extend({
  type: z.literal("landing-page"),
  url: z.string().min(1, "Url is required").transform(normalizeUrl),
});
export const ApiEnrollmentServiceMetadata = ServiceMetadataBase.extend({
  type: z.literal("enrollment"),
  success: z.object({
    modalTitle: z.string().min(1, "Success modal title is required"),
    modalContent: z.string().min(1, "Success modal content is required"),
    badgeText: z.string().min(1, "Success badge text is required"),
  }),
});

export const ApiServiceMetadata = z.union([
  ApiLandingPageServiceMetadata,
  ApiEnrollmentServiceMetadata,
]);

export type ApiServiceMetadata = z.infer<typeof ApiServiceMetadata>;
export type ApiEnrollmentServiceMetadata = z.infer<
  typeof ApiEnrollmentServiceMetadata
>;
export type ApiLandingPageServiceMetadata = z.infer<
  typeof ApiLandingPageServiceMetadata
>;

const APIBaseService = z.object({
  repositoryId: z.string(),
  name: z.string().min(1, "Title is required"),
  description: z.string().nullable(),
  thumbnail: z.string().nullable(),
  logoUrl: z.string().nullable(),
  tags: z.array(z.string()),
  meta: ApiServiceMetadata,
});

type APIBaseService = z.infer<typeof APIBaseService>;

export const APICreateService = APIBaseService;

export type APICreateService = z.infer<typeof APICreateService>;

export type APIMutateServiceOutput = {
  id: string;
  resourceId: string;
};

export const APIUpdateService = APIBaseService.omit({
  logoUrl: true,
  thumbnail: true,
}).extend({
  id: z.string(),
  thumbnail: z.string().nullable().optional(),
  logoUrl: z.string().nullable().optional(),
});

export type APIUpdateService = z.infer<typeof APIUpdateService>;

export const ClientCreateService = APIBaseService.omit({
  repositoryId: true,
  thumbnail: true,
  logoUrl: true,
}).extend({
  logoUploader: z.instanceof(Uploader),
  thumbnailUploader: z.instanceof(Uploader),
  tags: z.array(z.object({ label: z.string(), value: z.string() })),
});

export type ClientCreateService = z.infer<typeof ClientCreateService>;
