import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { Button, Center, Flex, Input, rem, Text, Title } from "@mantine/core";

import { zodResolver } from "@hookform/resolvers/zod";
import { useHubAuthStore } from "@pillar/auth/hub/client/HubAuth.store";
import { useHubConfigStore } from "@pillar/config/hub/client/HubConfig/index.store";
import { MobileOrganizationLogo } from "@pillar/organizations/hub/client/OrganizationLogo";

import { MobileAppPage, MobileAppPageHeader } from "@pillar/ui/obsidian/layout";
import { FC } from "react";
import { useHubMiniSession } from "@pillar/v3/auth/hub/client/autologin.hooks";

const schema = z.object({
  email: z.string().toLowerCase().email("Please enter a valid email address"),
});

export const HubTrackLoginForm: FC<{ pid: string }> = (props) => {
  const hub = useHubConfigStore();
  const auth = useHubAuthStore();
  const miniSession = useHubMiniSession(hub.derived.config.session);

  const loading = auth.ops.trackLogin.derived.isLoading;

  const form = useForm({
    values: {
      email: "",
    },
    resolver: zodResolver(schema),
  });

  return (
    <Center h="100%">
      <FormProvider {...form}>
        <form
          // FIXME: we should use the actual tracking
          onSubmit={form.handleSubmit((values) => {
            auth.ops.trackLogin
              .run({
                pid: props.pid,
                email: values.email,
              })
              .then(() => {
                if (miniSession.config.shouldAutologing) {
                  miniSession.setMiniSession({
                    email: values.email,
                    at: new Date(),
                  });
                }
              });
          })}
        >
          <Flex
            h="100%"
            direction={"column"}
            justify={"center"}
            style={{ flexGrow: 1 }}
            gap={"sm"}
          >
            <Title order={2}>{hub.sagas.t("login_title")}</Title>
            <Text fw="300">{hub.sagas.t("login_sub")}</Text>

            <Input.Wrapper
              label={"Email"}
              error={form.formState?.errors?.email?.message ?? ""}
            >
              <Input
                size="lg"
                type="email"
                placeholder="example@gmail.com"
                {...form.register("email")}
              />
            </Input.Wrapper>

            <Button
              type="submit"
              size="lg"
              disabled={loading ?? undefined}
              w="100%"
              className="capitalize"
            >
              {hub.sagas.t("login_submit")}
            </Button>
          </Flex>
        </form>
      </FormProvider>
    </Center>
  );
};

/** The login form */
export const MobileHubTrackLoginRenderer: FC<{ pid: string }> = (props) => {
  const hub = useHubConfigStore();

  return (
    <MobileAppPage>
      <MobileAppPageHeader
        p={"md"}
        logo={<MobileOrganizationLogo />}
      ></MobileAppPageHeader>

      <MobileAppPage.Main>
        <HubTrackLoginForm pid={props.pid} />
      </MobileAppPage.Main>
      <MobileAppPage.Footer>
        <Flex h="100%" align={"center"}>
          <Text size="xs" p={rem(8)}>
            {hub.sagas.t("login_footer")}
          </Text>
        </Flex>
      </MobileAppPage.Footer>
    </MobileAppPage>
  );
};
