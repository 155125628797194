import { useEffect } from "react";

import { createContainer } from "@pillar/v3/client/client.container";
import { ClientCompactResource } from "@pillar/v3/resources/resources.types";
import { useCurrentRootPid } from "@pillar/v3/invite-links/hub/client/useCurrentLink";
import { $useAuth } from "@pillar/v3/auth/hub/client/auth.hub.store";
import { withHubToken } from "@pillar/v3/auth/hub/auth.hub.client";
import { v3HubApi } from "@pillar/v3/rpc/client.hub";

import { $SupportedOutputLangs } from "../../translation.types";
import {
  langEnumToReadableUser,
  langEnumToReadableUserAbbreviation,
} from "../../translation.utils";
import { useTranslationSelector } from "../../client/hooks";
import { useHubURLParamsStore } from "@pillar/v3/client/urls/params.hub.store";
import { useQuery } from "@tanstack/react-query";

export const useHubResourceTranslationsHook = (opts: {
  vernacular: ClientCompactResource;
}) => {
  const { state } = useHubURLParamsStore();

  // FIXME: make sure it supports links and pathways properly
  // #region to refactor
  const rootPid = useCurrentRootPid();

  const auth = $useAuth({
    pid: rootPid ?? "",
  });
  const request = withHubToken(auth.token ?? "", {
    pid: rootPid,
    resourceId: opts.vernacular.id,
  });
  // #endregion

  const operation = useQuery(["get-translations", opts.vernacular.id], () =>
    v3HubApi.v3.hub.accessibility.translation.getAllTranslations.query(request)
  );

  const {
    vernacular,
    vernacularAndTranslations,
    onSelectTranslation,
    onSetDefaultTranslation,
    currentTranslation,
  } = useTranslationSelector({
    resource: opts.vernacular,
    translations: operation.data ?? [],
  });

  // default to preferred language set in the URL
  useEffect(() => {
    if (operation.data && state.preferredLang) {
      onSetDefaultTranslation(state.preferredLang as $SupportedOutputLangs);
    }
  }, [operation.data, state.preferredLang]);

  return {
    state: {
      currentTranslation: {
        val: currentTranslation,
        set: onSelectTranslation,
      },
    },
    derived: {
      translations: vernacularAndTranslations,
      translationsAsOptions: vernacularAndTranslations.map((t) => ({
        value: t.id,
        label: langEnumToReadableUser(t.lang),
      })),
      translationsAsOptionsMobile: vernacularAndTranslations.map((t) => ({
        value: t.id,
        label: langEnumToReadableUserAbbreviation(t.lang),
      })),
      hasTranslations: vernacularAndTranslations.length > 1,
      isLoading: operation.isLoading,
    },
    operation,
  };
};

export const HubResourceTranslationsContainer = createContainer(
  useHubResourceTranslationsHook
);

export const HubResourceTranslationsProvider =
  HubResourceTranslationsContainer.Provider;

export const useHubResourceTranslations =
  HubResourceTranslationsContainer.useContainer;
