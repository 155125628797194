import { CompactResourceAggregateWithInteractions } from "@pillar/pathways/types";
import { ExplorerSectionName, useExplorerSearch } from "./ExplorerSearch.store";
import { FC } from "react";
import { ResultSectionComponentProps } from "./DesktopExplorerResultSection";
import { Button, Group, Stack, Title } from "@mantine/core";

import { ChevronRight } from "lucide-react";
import { ResourceListItem } from "@pillar/resources/hub/client/ResourceListItem";

export const MobileExplorerResultSection = (p: {
  title: ExplorerSectionName;
  getResults: (
    results: CompactResourceAggregateWithInteractions[]
  ) => CompactResourceAggregateWithInteractions[];

  onSelectResource: (
    resource: CompactResourceAggregateWithInteractions
  ) => Promise<void>;
}) => {
  const store = useExplorerSearch();
  const results = store.state.results;
  const actualResults = p.getResults(results);
  const isGrid = store.state.isGrid;
  const expanded = store.state.expandedSection === p.title;

  const selectedSection = store.state.expandedSection;
  const visible = selectedSection === p.title || selectedSection === null;

  if (!visible) return null;

  if (actualResults.length === 0) return null;
  return (
    <MobileExplorerResultSectionComponent
      title={p.title ?? "Any"}
      isGrid={isGrid}
      resources={actualResults}
      onSelectResource={p.onSelectResource}
      expanded={expanded}
      onExpandSection={() => {
        store.actions.setExpandedSection(p.title);
      }}
      onCloseSection={() => {
        store.actions.setExpandedSection(null);
      }}
    />
  );
};

export const MobileExplorerResultSectionComponent: FC<
  ResultSectionComponentProps
> = (p) => {
  const cutoff = 3;
  const toShow = p.expanded ? p.resources : p.resources.slice(0, cutoff);
  return (
    <Stack gap="xs">
      <Group justify={"space-between"}>
        <Title order={4}>{p.title}</Title>
        {p.resources.length > cutoff && (
          <Button
            onClick={p.expanded ? p.onCloseSection : p.onExpandSection}
            variant="transparent"
            size="sm"
            px={0}
            rightSection={<ChevronRight size="1rem" />}
          >
            {p.expanded ? "Close" : "View all"}
          </Button>
        )}
      </Group>
      <Stack gap="xs">
        {toShow.map((resource, index) => (
          <ResourceListItem
            key={`explorer-${index}-${resource.id}`}
            variant="explorer"
            compact
            resource={resource}
            openResource={async () => {
              await p.onSelectResource(resource);
            }}
          />
        ))}
      </Stack>
    </Stack>
  );
};
