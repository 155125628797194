/** A CSP-safe validator, hastily ported from https://github.com/rjsf-team/react-jsonschema-form/tree/main/packages/validator-ajv8/src */

import {
  CustomValidator,
  ErrorSchema,
  ErrorTransformer,
  FormContextType,
  ID_KEY,
  RJSFSchema,
  ROOT_SCHEMA_PREFIX,
  StrictRJSFSchema,
  toErrorList,
  UiSchema,
  ValidationData,
  ValidatorType,
  withIdRefPrefix,
  hashForSchema,
  createErrorHandler,
  getDefaultFormState,
  getUiOptions,
  PROPERTIES_KEY,
  RJSFValidationError,
  toErrorSchema,
  unwrapErrorHandler,
  validationDataMerge,
} from "@rjsf/utils";
import { CustomValidatorOptionsType, Localizer } from "@rjsf/validator-ajv8";

import Ajv, { ErrorObject, ValidateFunction } from "ajv";

import {
  Validator as AJVShimValidator,
  OutputUnit,
} from "@cfworker/json-schema";
import { get } from "../stdlib/objects";
import { Output } from "@pillar/auth/useCases/InitiateSigninUseCase";
import { onlyValid } from "../stdlib/arrays";

export type RawValidationErrorsType<Result = any> = {
  errors?: Result[];
  validationError?: Error;
};

/** Transforming the error output from ajv to format used by @rjsf/utils.
 * At some point, components should be updated to support ajv.
 *
 * @param errors - The list of AJV errors to convert to `RJSFValidationErrors`
 * @param [uiSchema] - An optional uiSchema that is passed to `transformErrors` and `customValidate`
 */
export function transformRJSFValidationErrors<
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>(errors: unknown[] = [], uiSchema?: UiSchema<T, S, F>): RJSFValidationError[] {
  return errors.map((e: ErrorObject) => {
    const { instancePath, keyword, params, schemaPath, parentSchema, ...rest } =
      e;
    let { message = "" } = rest;

    console.info("instance path", instancePath);
    let property = instancePath.replace(/\//g, ".");
    let stack = `${property} ${message}`.trim();

    if ("missingProperty" in params) {
      property = property
        ? `${property}.${params.missingProperty}`
        : params.missingProperty;
      const currentProperty: string = params.missingProperty;
      const uiSchemaTitle = getUiOptions(
        get(uiSchema, `${property.replace(/^\./, "")}`)
      ).title;

      if (uiSchemaTitle) {
        message = message.replace(currentProperty, uiSchemaTitle);
      } else {
        const parentSchemaTitle = get(parentSchema, [
          PROPERTIES_KEY,
          currentProperty,
          "title",
        ]);

        if (parentSchemaTitle) {
          message = message.replace(currentProperty, parentSchemaTitle);
        }
      }

      stack = message;
    } else {
      const uiSchemaTitle = getUiOptions<T, S, F>(
        get(uiSchema, `${property.replace(/^\./, "")}`)
      ).title;

      if (uiSchemaTitle) {
        stack = `'${uiSchemaTitle}' ${message}`.trim();
      } else {
        const parentSchemaTitle = parentSchema?.title;

        if (parentSchemaTitle) {
          stack = `'${parentSchemaTitle}' ${message}`.trim();
        }
      }
    }

    // put data in expected format
    return {
      name: keyword,
      property,
      message,
      params, // specific to ajv
      stack,
      schemaPath,
    };
  });
}

function cfWorkValidatorErrorToRJSFValidationError(error: OutputUnit[]) {
  return error
    .map((input) => {
      const baseError = input as OutputUnit;
      // console.info(baseError);
      const missingProperty = baseError.error?.match(
        /does not have required property "(.*)"/
      )?.[1];
      // console.info("baseError", missingProperty);

      console.info(
        baseError.error,
        baseError.instanceLocation.replace("#", "")
      );
      const compatError = {
        params: missingProperty
          ? {
              missingProperty,
            }
          : {},
        instancePath: baseError.instanceLocation.replace("#", ""),
        keyword: baseError.keyword,
        message: baseError.error,
        schemaPath: baseError.keywordLocation,
      } as ErrorObject;

      // console.warn("compatError", compatError);
      return compatError;
    })
    .map((err) => {
      console.debug(err);
      if (err.keyword === "properties") return null;
      return err;
    })
    .filter(onlyValid);
}

/** This function processes the `formData` with an optional user contributed `customValidate` function, which receives
 * the form data and a `errorHandler` function that will be used to add custom validation errors for each field. Also
 * supports a `transformErrors` function that will take the raw AJV validation errors, prior to custom validation and
 * transform them in what ever way it chooses.
 *
 * @param validator - The `ValidatorType` implementation used for the `getDefaultFormState()` call
 * @param rawErrors - The list of raw `ErrorObject`s to process
 * @param formData - The form data to validate
 * @param schema - The schema against which to validate the form data
 * @param [customValidate] - An optional function that is used to perform custom validation
 * @param [transformErrors] - An optional function that is used to transform errors after AJV validation
 * @param [uiSchema] - An optional uiSchema that is passed to `transformErrors` and `customValidate`
 */
export function processRawValidationErrors<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>(
  validator: ValidatorType<T, S, F>,
  rawErrors: RawValidationErrorsType<ErrorObject>,
  formData: T | undefined,
  schema: S,
  customValidate?: CustomValidator<T, S, F>,
  transformErrors?: ErrorTransformer<T, S, F>,
  uiSchema?: UiSchema<T, S, F>
) {
  const { validationError: invalidSchemaError } = rawErrors;
  let errors = transformRJSFValidationErrors<T, S, F>(
    rawErrors.errors,
    uiSchema
  );

  if (invalidSchemaError) {
    errors = [...errors, { stack: invalidSchemaError!.message }];
  }
  if (typeof transformErrors === "function") {
    errors = transformErrors(errors, uiSchema);
  }

  let errorSchema = toErrorSchema<T>(errors);

  if (invalidSchemaError) {
    errorSchema = {
      ...errorSchema,
      $schema: {
        __errors: [invalidSchemaError!.message],
      },
    };
  }

  if (typeof customValidate !== "function") {
    return { errors, errorSchema };
  }

  // Include form data with undefined values, which is required for custom validation.
  const newFormData = getDefaultFormState<T, S, F>(
    validator,
    schema,
    formData,
    schema,
    true
  ) as T;

  const errorHandler = customValidate(
    newFormData,
    createErrorHandler<T>(newFormData),
    uiSchema
  );
  const userErrorSchema = unwrapErrorHandler<T>(errorHandler);
  return validationDataMerge<T>({ errors, errorSchema }, userErrorSchema);
}

/** `ValidatorType` implementation that uses the AJV 8 validation mechanism.
 */
export class AJV8Validator<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
> implements ValidatorType<T, S, F>
{
  /** The Localizer function to use for localizing Ajv errors
   *
   * @private
   */
  readonly localizer?: Localizer;

  /** Constructs an `AJV8Validator` instance using the `options`
   *
   * @param options - The `CustomValidatorOptionsType` options that are used to create the AJV instance
   * @param [localizer] - If provided, is used to localize a list of Ajv `ErrorObject`s
   */
  constructor(options: CustomValidatorOptionsType, localizer?: Localizer) {
    const {
      additionalMetaSchemas,
      customFormats,
      ajvOptionsOverrides,
      ajvFormatOptions,
      AjvClass,
    } = options;

    this.localizer = localizer;
  }

  /** Converts an `errorSchema` into a list of `RJSFValidationErrors`
   *
   * @param errorSchema - The `ErrorSchema` instance to convert
   * @param [fieldPath=[]] - The current field path, defaults to [] if not specified
   * @deprecated - Use the `toErrorList()` function provided by `@rjsf/utils` instead. This function will be removed in
   *        the next major release.
   */
  toErrorList(errorSchema?: ErrorSchema<T>, fieldPath: string[] = []) {
    return toErrorList(errorSchema, fieldPath);
  }

  /** Runs the pure validation of the `schema` and `formData` without any of the RJSF functionality. Provided for use
   * by the playground. Returns the `errors` from the validation
   *
   * @param schema - The schema against which to validate the form data   * @param schema
   * @param formData - The form data to validate
   */
  rawValidation<Result = any>(
    schema: S,
    formData?: T
  ): RawValidationErrorsType<Result> {
    // Filter out properties with undefined values, which would make validation fail otherwise
    const filteredFormData = Object.fromEntries(
      Object.entries(formData ?? {}).filter(([_, value]) => value !== undefined)
    );

    const validator = new AJVShimValidator(schema);
    const result = validator.validate(filteredFormData);

    return {
      errors: result.errors as unknown as Result[],
      validationError: undefined,
    };
  }

  rawAjvValidation(
    schema: S,
    formData?: T
  ): RawValidationErrorsType<ErrorObject> {
    const ajv = new Ajv();

    let compilationError: Error | undefined = undefined;
    let compiledValidator: ValidateFunction | undefined;
    if (schema[ID_KEY]) {
      compiledValidator = ajv.getSchema(schema[ID_KEY]);
    }
    try {
      if (compiledValidator === undefined) {
        compiledValidator = ajv.compile(schema);
      }
      compiledValidator(formData);
    } catch (err) {
      compilationError = err as Error;
    }

    let errors;
    if (compiledValidator) {
      if (typeof this.localizer === "function") {
        this.localizer(compiledValidator.errors);
      }
      errors = compiledValidator.errors || undefined;

      // Clear errors to prevent persistent errors, see #1104
      compiledValidator.errors = null;
    }

    return {
      errors,
      validationError: compilationError,
    };
  }
  /** This function processes the `formData` with an optional user contributed `customValidate` function, which receives
   * the form data and a `errorHandler` function that will be used to add custom validation errors for each field. Also
   * supports a `transformErrors` function that will take the raw AJV validation errors, prior to custom validation and
   * transform them in what ever way it chooses.
   *
   * @param formData - The form data to validate
   * @param schema - The schema against which to validate the form data
   * @param [customValidate] - An optional function that is used to perform custom validation
   * @param [transformErrors] - An optional function that is used to transform errors after AJV validation
   * @param [uiSchema] - An optional uiSchema that is passed to `transformErrors` and `customValidate`
   */
  validateFormData(
    formData: T | undefined,
    schema: S,
    customValidate?: CustomValidator<T, S, F>,
    transformErrors?: ErrorTransformer<T, S, F>,
    uiSchema?: UiSchema<T, S, F>
  ): ValidationData<T> {
    const rawErrors = this.rawValidation<OutputUnit>(schema, formData);
    const ajvErrors = this.rawAjvValidation(schema, formData);

    // console.info(
    //   "transformed-ajv???",
    //   ajvErrors.errors,
    //   rawErrors.errors,
    //   processRawValidationErrors(
    //     this,
    //     ajvErrors,
    //     formData,
    //     schema,
    //     customValidate,
    //     transformErrors,
    //     uiSchema
    //   )
    // );

    const fromCfWork = cfWorkValidatorErrorToRJSFValidationError(
      rawErrors.errors ?? []
    );

    const tranformed = processRawValidationErrors(
      this,
      {
        ...rawErrors,
        errors: fromCfWork,
      },
      formData,
      schema,
      customValidate,
      transformErrors,
      uiSchema
    );

    console.info("transformed", rawErrors.errors, tranformed);

    return tranformed;
  }

  /** Validates data against a schema, returning true if the data is valid, or
   * false otherwise. If the schema is invalid, then this function will return
   * false.
   *
   * @param schema - The schema against which to validate the form data
   * @param formData - The form data to validate
   * @param rootSchema - The root schema used to provide $ref resolutions
   */
  isValid(schema: S, formData: T | undefined, rootSchema: S) {
    const rootSchemaId = rootSchema[ID_KEY] ?? ROOT_SCHEMA_PREFIX;
    try {
      // add the rootSchema ROOT_SCHEMA_PREFIX as id.
      // then rewrite the schema ref's to point to the rootSchema
      // this accounts for the case where schema have references to models
      // that lives in the rootSchema but not in the schema in question.
      // if (this.ajv.getSchema(rootSchemaId) === undefined) {
      // TODO restore the commented out `if` above when the TODO in the `finally` is completed
      //   this.ajv.addSchema(rootSchema, rootSchemaId);
      //   // }
      //   const schemaWithIdRefPrefix = withIdRefPrefix<S>(schema) as S;
      //   const schemaId =
      //     schemaWithIdRefPrefix[ID_KEY] ?? hashForSchema(schemaWithIdRefPrefix);
      //   let compiledValidator: ValidateFunction | undefined;
      //   compiledValidator = this.ajv.getSchema(schemaId);
      //   if (compiledValidator === undefined) {
      //     // Add schema by an explicit ID so it can be fetched later
      //     // Fall back to using compile if necessary
      //     // https://ajv.js.org/guide/managing-schemas.html#pre-adding-all-schemas-vs-adding-on-demand
      //     compiledValidator =
      //       this.ajv
      //         .addSchema(schemaWithIdRefPrefix, schemaId)
      //         .getSchema(schemaId) || this.ajv.compile(schemaWithIdRefPrefix);
      //   }

      if (typeof schema === "boolean") return false;
      const validator = new AJVShimValidator(schema);
      const result = validator.validate(formData);

      return result.valid;
    } catch (e) {
      //   console.warn("Error encountered compiling schema:", e);
      return false;
    } finally {
      // TODO: A function should be called if the root schema changes so we don't have to remove and recompile the schema every run.
      // make sure we remove the rootSchema from the global ajv instance
      //   this.ajv.removeSchema(rootSchemaId);
    }
  }
}

/** Creates and returns a customized implementation of the `ValidatorType` with the given customization `options` if
 * provided. If a `localizer` is provided, it is used to translate the messages generated by the underlying AJV
 * validation.
 *
 * @param [options={}] - The `CustomValidatorOptionsType` options that are used to create the `ValidatorType` instance
 * @param [localizer] - If provided, is used to localize a list of Ajv `ErrorObject`s
 * @returns - The custom validator implementation resulting from the set of parameters provided
 */
export function customizeValidator<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>(options: CustomValidatorOptionsType = {}, localizer?: Localizer) {
  return new AJV8Validator<T, S, F>(options, localizer);
}

export const validator = customizeValidator();
