import { FC } from "react";

import { Box, Image, Text } from "@chakra-ui/react";
import { ResourceThumbnailFallback } from "@pillar/resources/client/ResourceThumbnail";
import { ResourceTypeTag } from "@pillar/resources/types.legacy";

export const FilePreview: FC<
  { type: "file"; file: File } | { type: "url"; url: string }
> = (p) => {
  const extensionRef = p.type === "file" ? p.file.type : p.url;
  const isImageFile =
    extensionRef.includes("image") ||
    extensionRef.includes("png") ||
    extensionRef.includes("jpeg") ||
    extensionRef.includes("jpg");

  const isDocumentFile =
    extensionRef.includes(ResourceTypeTag.document) ||
    extensionRef.includes("pdf");

  const url = isImageFile
    ? p.type === "file"
      ? URL.createObjectURL(p.file)
      : p.url
    : null;
  return (
    <>
      {url ? (
        <Image
          borderRadius={"md"}
          src={url}
          // alt={p.file.name}
          style={{ maxHeight: "5em", maxWidth: "5em" }}
        />
      ) : (
        <ResourceThumbnailFallback
          type={
            isDocumentFile ? ResourceTypeTag.document : ResourceTypeTag.video
          }
        />
      )}
      {p.type === "file" && (
        <Box textAlign="start">
          <Text>{p.file?.name}</Text>
          <Text fontWeight={"bold"} fontSize="xs">
            {Number(p.file?.size / 1000 / 1000).toFixed(2)} mb
          </Text>
        </Box>
      )}
    </>
  );
};
