import { Accept } from 'react-dropzone';
import { BiInfoCircle } from 'react-icons/bi';
import { RiCheckboxCircleFill } from 'react-icons/ri';

import {
    Box, FormControl, FormLabel, HStack, Popover, PopoverContent, PopoverTrigger, Progress, Spinner,
    Text
} from '@chakra-ui/react';

import { DropzoneField } from './Dropzone';
import { FileUploaderStore, UploadingFile } from './FileUploaderStore';

export enum ResourceFileType {
  Resource = "resource",
  Thumbnail = "thumbnail",
  Logo = "logo",
}

export type UploadedFile = {
  size: number;
  isStored: boolean;
  isImage: boolean;
  mimeType: string;
  cdnUrl: string;
  s3Url: string | null;
  originalFilename: string;
  imageInfo: any;
  videoInfo: any;
  contentInfo: {
    mime: { mime: string; type: string; subtype: string };
  };
  metadata: any;
  s3Bucket: string | null;
  uuid: string;
  cdnUrlModifiers: string | null;
};

export const UploadProgress = (p: {
  uploader: { state: UploadingFile };
  type: string;
}) => {
  const valueAsPercent = Math.ceil(p.uploader.state.progress * 100);

  return (
    <>
      {p.uploader.state.isUploading && p.uploader.state.progress !== -1 && (
        <HStack flex={1}>
          <Progress
            size="sm"
            colorScheme={"brand"}
            flex={1}
            value={valueAsPercent}
          />
          <HStack>
            <Text>{valueAsPercent}%</Text>
            {p.uploader.state.progress === -1 ? null : p.uploader.state
                .progress === 1 ? (
              <RiCheckboxCircleFill />
            ) : (
              <Spinner size="sm" />
            )}
          </HStack>
        </HStack>
      )}
    </>
  );
};

export const acceptFileDescription = (accept: Accept) => {
  return `We accept the following file types: 
  ${Object.values(accept)
    .map((extensions) => extensions.join(", "))
    .join(", ")}`;
};

export const UploadContentFile = (p: {
  label: string;
  name: string;
  uploader: FileUploaderStore;
  type: ResourceFileType;
  repoId?: string;
  accept: Accept;
}) => {
  return (
    <>
      <FormControl>
        <Popover trigger="hover" isLazy placement="top">
          <PopoverTrigger>
            <HStack spacing={2}>
              <FormLabel color="brand.700" fontWeight={"medium"} mr={0}>
                {p.label}
              </FormLabel>
              <BiInfoCircle size={18} />
            </HStack>
          </PopoverTrigger>
          <PopoverContent>
            <Text>{acceptFileDescription(p.accept)}</Text>
          </PopoverContent>
        </Popover>
        <DropzoneField
          name={p.name}
          accept={p.accept}
          uploading={p.uploader.state.progress !== -1}
          initialUrl={p.uploader.state.url ?? undefined}
        />
      </FormControl>
      <UploadProgress uploader={p.uploader} type={p.type} />
    </>
  );
};
