import type { ClientCompactResource } from "@pillar/v3/resources/resources.types";
import {
  $SupportedOutputLangs,
  type TranslationAggregate,
} from "../translation.types";

export const resourceAsTranslation = (resource: ClientCompactResource) => {
  return {
    lang: $SupportedOutputLangs.en,
    meta: {
      uri: resource.uri,
      language: $SupportedOutputLangs.en,
      status: "validated",
      translatedAt: new Date(),
      type: "translation",
    },
    organizationId: "irrelevant",
    resourceId: "irrelevant",
    url: resource.uri,
    id: resource.id,
  } as TranslationAggregate;
};
