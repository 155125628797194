import { useCallback, useState } from "react";
import { Accept, FileRejection, useDropzone } from "react-dropzone";
import {
  Center,
  useColorModeValue,
  Icon,
  Text,
  HStack,
  Button,
  Alert,
  Image,
} from "@chakra-ui/react";
import { BsFillFileTextFill } from "react-icons/bs";

import { AiFillFileAdd } from "react-icons/ai";
import { Controller } from "react-hook-form";
import { FilePreview } from "./FilePreview";

export const acceptIcon: Accept = {
  "image/x-icon": [".ico"],
  "image/gif": [".gif"],
};
export const acceptImage: Accept = {
  "image/jpeg": [".jpeg", ".jpg"],
  "image/png": [".png"],
};

export const acceptVideo: Accept = {
  "video/mp4": [".mp4"],
  "video/quicktime": [".mov"],
};

export const acceptPDF: Accept = {
  "application/pdf": [".pdf"],
};

export default function Dropzone({
  accept,
  initialUrl,
  initialFile,
  uploading,
  onFileAccepted,
  isInvalid = false,
  disableChangeFile = false,
}: {
  onFileAccepted: (file: File | null) => void;
  accept: Accept;
  uploading?: boolean;
  initialUrl?: string;
  initialFile?: File;
  isInvalid?: boolean;
  disableChangeFile?: boolean;
}) {
  console.info("initial URL", initialUrl);
  const [showDropzone, setShowDropzone] = useState(!initialUrl && !initialFile);
  const [showError, setShowError] = useState(false);
  const [selectFileError, setSelectFileError] = useState(false);

  const onDropAccepted = useCallback(
    (acceptedFiles: File[]) => {
      setSelectFileError(false);
      onFileAccepted(acceptedFiles[0]);
      setShowDropzone(false);
    },
    [onFileAccepted]
  );
  const onDropRejected = useCallback(
    (_rejectedFiles: FileRejection[]) => {
      setSelectFileError(true);
    },
    [onFileAccepted]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
    isDragReject,
    isDragAccept,
  } = useDropzone({
    onDropAccepted,
    onDropRejected,
    onError: (e) => {
      console.error(e);
      setShowError(true);
    },

    accept,
    maxFiles: 1,
    multiple: false,
  });

  const dropText = isDragReject
    ? "This file is not accepted"
    : isDragAccept
    ? "Drop the file here"
    : isDragActive
    ? "Drop the file here ..."
    : "Drag your file here, or click to select file";

  const activeBg = isDragReject
    ? "orange.200"
    : isDragActive
    ? useColorModeValue("green.100", "green.600")
    : "transparent";

  const borderColor = isDragReject
    ? "orange.300"
    : isDragActive
    ? useColorModeValue("teal.300", "teal.500")
    : isInvalid
    ? "red.500"
    : useColorModeValue("gray.300", "gray.500");

  const activeFile = acceptedFiles[0] ?? initialFile ?? null;

  const filePreview = activeFile
    ? {
        type: "file" as const,
        file: activeFile,
      }
    : initialUrl
    ? {
        type: "url" as const,
        url: initialUrl,
      }
    : null;
  return (
    <>
      {showDropzone ? (
        <Center
          p={10}
          cursor="pointer"
          bg={activeBg}
          _hover={{ bg: activeBg }}
          transition="background-color 0.2s ease"
          borderRadius={4}
          border="3px dashed"
          borderColor={borderColor}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <Icon as={AiFillFileAdd} mr={2} />
          <p>{dropText}</p>
        </Center>
      ) : (
        <HStack justifyContent={"space-between"} py={2}>
          <HStack>
            {/* {initialUrl && !Boolean(activeFile) && (
              <>
                Used
                <Image
                  borderRadius={"md"}
                  src={initialUrl}
                  fallback={<BsFillFileTextFill />}
                  alt={"Initial file"}
                  style={{ maxHeight: "5em", maxWidth: "5em" }}
                />
              </>
            )} */}
            {filePreview && <FilePreview {...filePreview} />}
          </HStack>

          {!uploading && !disableChangeFile && (
            <Button
              size="sm"
              variant="secondary"
              onClick={() => {
                onFileAccepted(null);
                setShowDropzone(true);
              }}
            >
              Change File
            </Button>
          )}
        </HStack>
      )}

      {selectFileError && (
        <Alert variant={"subtle"} colorScheme="orange">
          The chosen file is not valid. Please check the format and size and
          retry.
        </Alert>
      )}
      {showError && (
        <Alert variant={"subtle"} colorScheme="orange">
          Impossible to upload this file. Please check the format and size and
          try again.
        </Alert>
      )}
    </>
  );
}

export function MultiDropzone({
  accept,
  uploading,
  onFilesAccepted,
  isInvalid = false,
  maxFiles = 50,
}: {
  onFilesAccepted: (files: File[] | null) => void;
  accept: Accept;
  uploading?: boolean;
  isInvalid?: boolean;
  maxFiles?: number;
}) {
  // const [showDropzone, setShowDropzone] = useState(true);
  const [showError, setShowError] = useState(false);
  const [selectFileError, setSelectFileError] = useState(false);

  const onDropAccepted = useCallback(
    (acceptedFiles: File[]) => {
      setSelectFileError(false);
      onFilesAccepted(acceptedFiles);
      // setShowDropzone(false);
    },
    [onFilesAccepted]
  );
  const onDropRejected = useCallback(
    (_rejectedFiles: FileRejection[]) => {
      setSelectFileError(true);
    },
    [onFilesAccepted]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    // acceptedFiles,
    isDragReject,
    isDragAccept,
  } = useDropzone({
    onDropAccepted,
    onDropRejected,
    onError: (e) => {
      console.error(e);
      setShowError(true);
    },

    accept,
    maxFiles: maxFiles,
    multiple: true,
  });

  const dropText = isDragReject
    ? "These files are not accepted"
    : isDragAccept
    ? "Drop the files here"
    : isDragActive
    ? "Drop the files here ..."
    : "Drag your files here, or click to select files";

  const activeBg = isDragReject
    ? "orange.200"
    : isDragActive
    ? useColorModeValue("green.100", "green.600")
    : "transparent";

  const borderColor = isDragReject
    ? "orange.300"
    : isDragActive
    ? useColorModeValue("teal.300", "teal.500")
    : isInvalid
    ? "red.500"
    : useColorModeValue("gray.300", "gray.500");

  return (
    <>
      <Center
        p={10}
        cursor="pointer"
        bg={activeBg}
        _hover={{ bg: activeBg }}
        transition="background-color 0.2s ease"
        borderRadius={4}
        border="3px dashed"
        borderColor={borderColor}
        {...getRootProps()}
      >
        <input {...getInputProps()} disabled={uploading} />
        <Icon as={AiFillFileAdd} mr={2} />
        <p>{dropText}</p>
      </Center>

      {selectFileError && (
        <Alert variant={"subtle"} colorScheme="orange">
          The chosen files are not valid. Please check the format and size and
          retry.
        </Alert>
      )}
      {showError && (
        <Alert variant={"subtle"} colorScheme="orange">
          Impossible to upload this file. Please check the format and size and
          try again.
        </Alert>
      )}
    </>
  );
}

export const DropzoneField = (p: {
  name: string;
  accept: Accept;
  uploading?: boolean;
  initialUrl?: string;
  initialFile?: File;
}) => {
  return (
    <Controller
      defaultValue={p.initialFile}
      name={p.name}
      render={({ field, fieldState }) => {
        return (
          <Dropzone
            accept={p.accept}
            onFileAccepted={(bundle) => {
              field.onChange(bundle);
            }}
            uploading={p.uploading}
            initialUrl={p.initialUrl}
            initialFile={p.initialFile}
            isInvalid={Boolean(fieldState.error?.message)}
          />
        );
      }}
    />
  );
};
