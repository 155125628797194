import React, { FC, PropsWithChildren } from "react";
import {
  Box,
  Center,
  Flex,
  Group,
  Overlay,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { Lock } from "lucide-react";

import { addTime, humanizeLeftTime, timeLeft } from "@pillar/std/time";
import {
  CompactResourceAggregateWithInteractions,
  PathwayMilestoneUnlockAfter,
  ResolvedPathwayModule,
  ResolvedPathwayStep,
} from "@pillar/pathways/types";
import { ResourceListItem } from "@pillar/resources/hub/client/ResourceListItem";
import {
  FOOTER_HEIGHT,
  HEADER_HEIGHT,
  MobileAppPage,
} from "@pillar/ui/obsidian/layout";

import { usePathwayStore } from "./PathwayStore";
import { MobilePathwayFeedback } from "../../../v3/pathways/hub/client/PathwayFeedback";
import { ResourceMetadata } from "@pillar/v3/resources/hub/client/ResourceMetadataItems";
import { Legacy_ResourceType } from "@pillar/v3/resources/resources.types";

export const LockedModuleOverlay = (
  p: PropsWithChildren<{ showIcon?: boolean }>
) => {
  return (
    <Overlay
      backgroundOpacity={0.9}
      color="#DEE2E6" // FIXME: use theme colors
      blur={4}
      h="100%"
      radius="sm"
      zIndex={1}
    >
      <Center h="100%">
        <Flex align="center" direction={"column"} px={8}>
          {p.showIcon && <Lock />}
          <Text ta="center" pt={4}>
            {p.children}
          </Text>
        </Flex>
      </Center>
    </Overlay>
  );
};

export type ModuleRenderProps = {
  step: ResolvedPathwayModule;
  steps: ResolvedPathwayStep[];
  humanIndex: number;
  onSelectResource: (res: CompactResourceAggregateWithInteractions) => void;
  unlocked: boolean;
  lockMessage: string | null;
  currentResourceId: string | null;
};

export const MobilePathwayModule: FC<ModuleRenderProps> = ({
  step,
  steps,
  humanIndex,
  onSelectResource,
  unlocked,
  lockMessage,
  currentResourceId,
}) => {
  const shouldDisplay = steps.length > 1;
  return (
    <Box>
      {shouldDisplay && (
        <Title order={4} mb={4}>
          {/* @ts-ignore FIXME type should have name and description*/}
          {step?.name ?? `Section ${humanIndex}`}
        </Title>
      )}
      <Stack pos={"relative"}>
        {step.resources.map((resource) => {
          return (
            <ResourceListItem
              key={`list-${resource.id}`}
              compact
              variant="pathway"
              resource={resource}
              openResource={async () => {
                await onSelectResource(resource);
              }}
              isSelected={currentResourceId === resource.id}
            />
          );
        })}
        {!unlocked && <LockedModuleOverlay>{lockMessage}</LockedModuleOverlay>}
      </Stack>
    </Box>
  );
};

export const PathwayModuleSection: FC<{
  ModuleRender: FC<ModuleRenderProps>;
}> = (props) => {
  const store = usePathwayStore();

  return (
    <Stack style={{ overflowY: "auto" }}>
      {store.state.modules.map((step, index) => {
        return (
          <props.ModuleRender
            key={`module-${index}`}
            onSelectResource={async (
              resource: CompactResourceAggregateWithInteractions
            ) => {
              await store.actions.setCurrentResourceAndModule(resource, step);
            }}
            step={step}
            steps={store.state.modules}
            humanIndex={index + 1}
            unlocked={step.unlocked ?? false}
            lockMessage={step.lockMessage ?? null}
            currentResourceId={store.state.currentResource?.id ?? null}
          />
        );
      })}
    </Stack>
  );
};

export const MobilePathwayHomeScreen: FC<{ goBack?: () => void }> = (props) => {
  const store = usePathwayStore();

  return (
    <>
      <MobileAppPage.Header goBack={props.goBack}>
        {store.derived.pathway?.name}
      </MobileAppPage.Header>
      <MobileAppPage.Main
        px={8}
        pt={HEADER_HEIGHT + 16}
        pb={FOOTER_HEIGHT + 16}
      >
        <Stack gap={"sm"}>
          {store.derived.pathway && (
            <ResourceMetadata
              resource={{
                name: store.derived.pathway.name,
                description: store.derived.pathway.description,
                tags: store.derived.pathway.tags,
                types: store.derived.pathway
                  .types as unknown as Legacy_ResourceType[],
              }}
            />
          )}
          <PathwayModuleSection ModuleRender={MobilePathwayModule} />
        </Stack>
      </MobileAppPage.Main>
      <MobileAppPage.Footer>
        <Group h="100%" justify={"center"} align={"center"}>
          <MobilePathwayFeedback buttonText="Rate Pathway" />
        </Group>
      </MobileAppPage.Footer>
    </>
  );
};
