import { FC, ReactNode, useState } from "react";
import { RJSFSchema, RegistryWidgetsType, UiSchema } from "@rjsf/utils";

import { Form as MantineForm } from "../rjsf-mantine";
import { useHubFormSubmission } from "./hooks";
import { Container, Image, Skeleton, Stack, Title } from "@mantine/core";
import { RatingWidget } from "./RatingWidget";
import {
  APIFormSubmission,
  APIFormSubmissions,
  FormAggregate,
} from "../../forms.types";
import { transformErrors } from "./errors";
import { FormSubmissionCard } from "./FormSubmissionAlert";
import { validator } from "../../forms-validator.utils";
import { useIsMobile } from "@pillar/ui/obsidian/layout/hooks";
import { ResourceMetadata } from "@pillar/v3/resources/hub/client/ResourceMetadataItems";
import { Legacy_ResourceType } from "@pillar/v3/resources/resources.types";
import { FormErrorCard } from "./FormSubmissionError";

type FormBodyProps = {
  sectionId: string;
  onSubmit: (sectionId: string, formData: APIFormSubmission) => Promise<void>;
  title: string;
  tags: string[];
  thumbnail?: string | null;
  description?: string;
  schema: RJSFSchema;
  uiSchema: UiSchema;
  successActions?: ReactNode;
};

const widgets: RegistryWidgetsType = {
  RatingWidget: RatingWidget,
};

export const FormBody: FC<FormBodyProps> = (p) => {
  const {
    onSubmit,
    errorModal: { isErrorModalOpen, onCloseErrorModal },
    successModal: { isSuccessModalOpen, onCloseSuccessModal },
    liveValidation,
    isSubmitting,
  } = useHubFormSubmission({
    sectionId: p.sectionId,
    submitMutation: p.onSubmit,
  });

  // When we toggle `liveValidate` after the first submission, it seems to clear the form values. (todo: file bug with rjsf)
  // The workaround is to save the formData ourselves in this component state onChange
  const [formData, setFormData] = useState<object>({});
  const onFormDataChanged = (formState) => {
    setFormData(formState.formData);
  };

  return (
    <Container fluid p={0}>
      {p.thumbnail && (
        <Image
          fit="cover"
          h="10em"
          bgp={"center center"}
          src={
            p.thumbnail ??
            "https://res.cloudinary.com/with-pillar-inc/image/upload/v1708450198/hub-banner-min_scg7vc.jpg"
          }
          alt="Form Banner"
        />
      )}
      <Container py="lg" pt="xl">
        <Stack gap="lg">
          <Stack gap="0.5rem" pb={"lg"}>
            <Title order={3}>{p.title}</Title>
            <ResourceMetadata
              resource={{
                name: p.title,
                tags: p.tags,
                description: p.description,
                types: [Legacy_ResourceType.form],
              }}
            />
          </Stack>
          {isErrorModalOpen ? (
            <FormErrorCard />
          ) : isSuccessModalOpen ? (
            <FormSubmissionCard
              onClose={onCloseSuccessModal}
              actions={p.successActions}
            />
          ) : (
            <MantineForm
              formData={formData}
              onChange={onFormDataChanged}
              liveValidate={liveValidation.show}
              onError={liveValidation.activate}
              onSubmit={async (_, event) => {
                await onSubmit(
                  { formData: formData as APIFormSubmission },
                  event
                );
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                });
              }}
              liveOmit // This makes sure we don't have any undefined field, which would cause submission to fail
              noHtml5Validate
              schema={p.schema}
              uiSchema={{
                ...p.uiSchema,
                "ui:submitButtonOptions": { props: { disabled: isSubmitting } },
              }}
              validator={validator}
              widgets={widgets}
              transformErrors={transformErrors}
            />
          )}
        </Stack>
      </Container>
    </Container>
  );
};

export type FormProps = {
  form: FormAggregate;
  onSubmit: (payload: APIFormSubmissions) => Promise<{ id: string }>;
  successActions?: ReactNode;
};

export const Form: FC<FormProps> = (p) => {
  const { loading, isMobile } = useIsMobile();

  if (loading) return null;

  return isMobile ? (
    <FormBody
      sectionId={p.form.sections[0].id}
      onSubmit={async (sectionId: string, formData: APIFormSubmission) => {
        await p.onSubmit({ [sectionId]: { ...formData } });
      }}
      title={p.form.name}
      thumbnail={p.form.thumbnail}
      tags={p.form.tags}
      description={p.form.description}
      schema={p.form.sections[0].schema}
      uiSchema={p.form.sections[0].uiSchema}
      successActions={p.successActions}
    />
  ) : (
    <Container
      fluid
      px={0}
      pt={p.form.thumbnail ? 0 : 24}
      w="100%"
      flex={1}
      className="form-desktop-container"
    >
      <FormBody
        sectionId={p.form.sections[0].id}
        onSubmit={async (sectionId: string, formData: APIFormSubmission) => {
          await p.onSubmit({ [sectionId]: { ...formData } });
        }}
        title={p.form.name}
        thumbnail={p.form.thumbnail}
        tags={p.form.tags}
        description={p.form.description}
        schema={p.form.sections[0].schema}
        uiSchema={p.form.sections[0].uiSchema}
        successActions={p.successActions}
      />
    </Container>
  );
};
