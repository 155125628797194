import { FC, ReactNode, PropsWithChildren } from "react";
import { StaticResourceAggregate } from "@pillar/assets/hub/types";
import { AppPage, AppPageMain } from "@pillar/ui/obsidian/layout";
import { Container, Image } from "@mantine/core";
import { OrganizationLogo } from "@pillar/organizations/hub/client/OrganizationLogo";
import { ResourceTypeTag } from "@pillar/resources/types.legacy";
import { validString } from "@pillar/std/strings";
import { useHubURLParamsStore } from "@pillar/v3/client/urls/params.hub.store";

export const PublicResourceLayout: FC<
  PropsWithChildren<{
    resource: StaticResourceAggregate;
    actions?: ReactNode;
    goBack?: () => void;
  }>
> = (p) => {
  const {
    derived: { isEmbed },
  } = useHubURLParamsStore();

  return (
    <AppPage>
      <AppPage.Header
        logo={
          validString(p.resource.logoUrl) ? (
            <Image
              src={p.resource.logoUrl}
              pl={"lg"}
              alt="Public Resource Logo"
              mah="54px"
            />
          ) : isEmbed ? undefined : (
            <OrganizationLogo />
          )
        }
        actions={p.actions}
        goBack={p.goBack}
      />
      <AppPageMain
        bg={
          p.resource.types?.includes(ResourceTypeTag.service)
            ? undefined
            : "gray.2"
        }
        px={0}
        pb={0}
        pt={"60px"}
        display={"flex"}
        styles={{
          main: {
            flexDirection: "column",
          },
        }}
        flex={1}
      >
        {p.children}
      </AppPageMain>
    </AppPage>
  );
};
