import { ErrorTransformer, RJSFValidationError, UiSchema } from "@rjsf/utils";

export const transformErrors: ErrorTransformer = (
  errors: RJSFValidationError[],
  uiSchema: UiSchema
) => {
  return errors.map((error) => {
    if (error.name === "pattern") {
      error.message = "Only digits are allowed";
    }
    if (error.name === "required") {
      error.message = "This field is required";
    }

    console.log("error name", error);
    if (error.name === "minItems") {
      error.message = "At least one option must be selected";
    }

    return error;
  });
};
