import { z } from "zod";

export const DateRangeNamed = z.union([
  z.literal("last-24-hours"),
  z.literal("last-7-days"),
  z.literal("last-30-days"),
  z.literal("last-365-days"),
  z.literal("all"),
]);

export type DateRangeNamed = z.infer<typeof DateRangeNamed>;
export const APIDateRangeFilter = z.union([
  z.object({
    type: z.literal("named-range"),
    range: DateRangeNamed,
  }),
  z.object({
    type: z.literal("custom-range"),
    from: z.date(),
    to: z.date(),
  }),
]);

export type APIDateRangeFilter = z.infer<typeof APIDateRangeFilter>;
