import { FC } from 'react';

import { Image, useMantineTheme } from '@mantine/core';
import { useHubConfigStore } from '@pillar/config/hub/client/HubConfig/index.store';

export const MobileOrganizationLogo: FC = () => {
  const theme = useMantineTheme();
  const store = useHubConfigStore();

  const width = store.derived.config.theme["--mobile-logo-width"] ?? "100px";
  const scale = store.derived.config.theme["--mobile-logo-size-scale"] ?? "1.7";

  return (
    <Image
      src={store.derived.config.organization.logoUrl ?? ""}
      w={`calc(${width} /${scale})`}
      height="auto"
      alt={"Company logo"}
    />
  );
};

export const AlternateMobileOrganizationLogo: FC = () => {
  const theme = useMantineTheme();
  const store = useHubConfigStore();

  const width = store.derived.config.theme["--mobile-logo-width"] ?? "100px";
  const scale = store.derived.config.theme["--mobile-logo-size-scale"] ?? "1.7";

  return (
    <Image
      className="mobile-alternate-logo"
      src={
        store.derived.config.topBar?.alternateLogo ??
        store.derived.config.organization.logoUrl
      }
      w={`calc(${width} / ${scale})`}
      height="auto"
      alt={"Company logo"}
    />
  );
};

// TODO: apply scaling heuristics to logo size

export const OrganizationLogo: FC = () => {
  const store = useHubConfigStore();

  const width = store.derived.config.theme["--logo-size-width"] ?? "175px";
  const scale = store.derived.config.theme["--logo-size-scale"] ?? "1.3";

  return store.derived.config.organization.logoUrl ? (
    <Image
      className="logo"
      src={store.derived.config.organization.logoUrl}
      w={`calc(${width} / ${scale})`}
      height="auto"
      alt={"Company logo"}
    />
  ) : (
    <></>
  );
};

export const AlternateOrganizationLogo: FC = () => {
  const store = useHubConfigStore();

  const width = store.derived.config.theme["--logo-size-width"] ?? "175px";
  const scale = store.derived.config.theme["--logo-size-scale"] ?? "1.3";

  console.info(width, scale);

  return (
    <Image
      className="alternate-logo"
      src={
        store.derived.config.topBar?.alternateLogo ??
        store.derived.config.organization.logoUrl
      }
      w={`calc(${width} / ${scale})`}
      height="auto"
      alt={"Company logo"}
    />
  );
};
