import { Container, Flex, rem, Text } from "@mantine/core";
import { useHubConfigStore } from "@pillar/config/hub/client/HubConfig/index.store";
import { OrganizationLogo } from "@pillar/organizations/hub/client/OrganizationLogo";

import { AppPage } from "@pillar/ui/obsidian/layout";
import { useIsMobile } from "@pillar/ui/obsidian/layout/hooks";
import { FC } from "react";
import {
  HubTrackLoginForm,
  MobileHubTrackLoginRenderer,
} from "./MobileTrackLogin";

export const HubTrackLoginRenderer: FC<{ pid: string }> = (props) => {
  const hub = useHubConfigStore();

  return (
    <AppPage>
      <AppPage.Header logo={<OrganizationLogo />} />
      <AppPage.Main>
        <Container h="90%" size={"30rem"} styles={{ root: { width: "30rem" } }}>
          <HubTrackLoginForm pid={props.pid} />
        </Container>
      </AppPage.Main>
      <AppPage.Footer>
        <Flex h="60" p={6} align={"center"}>
          <Text size="xs" p={rem(8)}>
            {hub.sagas.t("login_footer")}
          </Text>
        </Flex>
      </AppPage.Footer>
    </AppPage>
  );
};

/** The login form */
export const HubTrackLogin: FC<{ pid: string }> = (props) => {
  const { isMobile } = useIsMobile();

  return isMobile ? (
    <MobileHubTrackLoginRenderer pid={props.pid} />
  ) : (
    <HubTrackLoginRenderer pid={props.pid} />
  );
};
