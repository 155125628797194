export const env = {
  public: {
    // @ts-expect-error
    NEXT_PUBLIC_HUB_URL: import.meta.env.NEXT_PUBLIC_HUB_URL ?? "",
    // @ts-expect-error
    VITE_PUBLIC_HUB_URL: import.meta.env.VITE_PUBLIC_HUB_URL ?? "",
    // @ts-expect-error
    NEXT_PUBLIC_HUB_API: import.meta.env.NEXT_PUBLIC_HUB_API ?? "",
    // @ts-expect-error
    VITE_PUBLIC_HUB_API: import.meta.env.VITE_PUBLIC_HUB_API ?? "",
    NEXT_PUBLIC_SAAS_API_LEGACY:
      // @ts-expect-error
      import.meta.env.VITE_PUBLIC_SAAS_API_LEGACY ?? "",

    // @ts-expect-error
    INGESTION_CLUSTER_URL: import.meta.env.INGESTION_CLUSTER_URL ?? "",
  },
} as const;

export type EnvVariables = typeof env;
