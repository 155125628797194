import { PublicResourceRendererMaster } from "@pillar/assets/hub/client/PublicResourceRendererMaster";
import {
  $DiscriminatedResourceUnion,
  $ResourceKind,
  $ResourceMimeType,
} from "../../resources.types";
import { ResourceAggregateWithInteractions } from "@pillar/resources/types";

const convert_resourceUnionToLegacyAggregate = (
  opts: $DiscriminatedResourceUnion
): ResourceAggregateWithInteractions | null => {
  if (opts.kind === $ResourceKind.repo) return null;

  console.log("✅✅✅✅✅✅", opts);
  const res = {
    id: opts.id,
    repositoryId: opts.repoId,
    authorName: "",
    description: opts.description,
    name: opts.name,
    pid: opts.pid ?? "",
    authors: [],
    tags: opts.tags ?? [],
    organizationId: opts.organizationId,
    meta: "meta" in opts ? (opts.meta as any) : null,
    thumbnail: "thumbnail" in opts ? opts.thumbnail ?? "" : "",
    logoUrl: "logoUrl" in opts ? opts.logoUrl ?? "" : "",
    url: "url" in opts ? opts.url ?? "" : "",
    types: (opts.kind === $ResourceKind.form
      ? ["form"]
      : opts.kind === $ResourceKind.pathway
      ? ["pathway"]
      : opts.mimeType === $ResourceMimeType.pdf
      ? ["document"]
      : opts.mimeType === $ResourceMimeType.landingPage
      ? ["service"]
      : opts.mimeType === $ResourceMimeType.enrollmentPage
      ? ["service"]
      : opts.mimeType === $ResourceMimeType.mp4 ||
        opts.mimeType === $ResourceMimeType.mov
      ? ["video"]
      : []) as any[],

    stats: {} as any,
    state: "published",
    uploadedById: null,
  } as const;

  console.info(res);

  return res;
};
export const HubResourceRenderer_AntiCorruption = (props: {
  resource: $DiscriminatedResourceUnion;
  explorerPid?: string;
  isMobile: boolean;
}) => {
  const converted = convert_resourceUnionToLegacyAggregate(props.resource);

  console.log(converted);
  if (!converted) return null;
  return (
    <PublicResourceRendererMaster
      data={converted}
      isMobile={props.isMobile}
      explorerPid={props.explorerPid}
    />
  );
};
