import type { FC } from "react";
import { Input, Flex, Button, Card, Stack } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Filter, Search } from "lucide-react";

import { resourceTypeTags as resourceTypes } from "@pillar/resources/fixtures";
import { BottomDrawer } from "@pillar/ui/obsidian/layout/drawer";

import { ActiveFilters } from "./ActiveFilters";
import { FilterSelector, type FilterSelectorProps } from "./HubExplorerFilters";
import { type FilterCategory, useExplorerSearch } from "./ExplorerSearch.store";

export const MobileFiltersButton: FC<FilterSelectorProps> = (p) => {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Button
        size="lg"
        radius={0}
        leftSection={<Filter size="1rem" />}
        onClick={open}
      >
        Filter
      </Button>
      <BottomDrawer title="Filters" opened={opened} onClose={close}>
        <FilterSelector {...p} />
        <Button size="lg" onClick={close} mt={16} w="100%">
          Apply Filters
        </Button>
      </BottomDrawer>
    </>
  );
};

export const MobileSearchBarAndFilterButton: FC = (p) => {
  const store = useExplorerSearch();
  const tags = store.ops.fetchFilters.derived.dataOrNull ?? [];
  const filtersSpec = [
    { name: "Tags", key: "tags", options: tags, repositoryId: null },
    resourceTypes,
  ];
  const filters = store.state.query;

  return (
    <Flex gap={0}>
      <Input
        placeholder="Search..."
        size="lg"
        leftSection={<Search />}
        radius={0}
        onChange={(e) => {
          store.sagas.onQueryChange({
            type: "search",
            value: e.target.value,
          });
        }}
        style={{ flexGrow: 1 }}
      />

      <MobileFiltersButton
        currentFilters={[
          ...(filters.tags ?? []),
          ...(filters.types ?? []),
          ...(filters.rating ?? []),
        ]}
        filterCategories={filtersSpec}
        setFilter={(
          category: FilterCategory,
          value: string,
          isChecked: boolean
        ) => {
          return store.sagas.onQueryChange({
            type: "filter",
            category,
            value,
            isChecked,
          });
        }}
        // onRemove={(category: FilterCategory, filter: string) => {
        //   return store.sagas.onQueryChange({
        //     type: "filter",
        //     category,
        //     isChecked: false,
        //     value: filter,
        //   });
        // }}
      />
    </Flex>
  );
};

export const MobileHubExplorerSearchAndFilters: FC = () => {
  const store = useExplorerSearch();

  return (
    <Card
      p={0}
      pb={4}
      radius={0}
      style={{ position: "sticky", top: -18, zIndex: 1 }}
    >
      <Stack gap="xs">
        <MobileSearchBarAndFilterButton />
        {Boolean(store.derived.hasActiveFilters) && (
          <ActiveFilters
            size="lg"
            tags={store.state.query.tags ?? []}
            types={store.state.query.types ?? []}
            ratings={store.state.query.rating ?? []}
            onRemove={(category: FilterCategory, filter: string) => {
              store.sagas.onQueryChange({
                type: "filter",
                category,
                value: filter,
                isChecked: false,
              });
            }}
          />
        )}
      </Stack>
    </Card>
  );
};
