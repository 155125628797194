import { useHubAuth as legacy_useAuthAuth } from "@pillar/auth/hub/client/HubAuth";
import { useMemo } from "react";
import type { $HubAuthSession } from "../auth.hub.types";
import { HubAuthStore } from "@pillar/auth/hub/client/HubAuth.store";
import { withHubToken } from "../auth.hub.client";

// const example = {"interactiveSessionId":"us-lvtmf8h7swt30kjs3z3k5t26","enrollmentId":"enr-ze4ui1oij3l1t6b6gmgvwh0b","type":"web","csrfToken":"","issuedAt":1712011889148,"user":{"email":"f@gmail.com","id":"m-i1m1bum3bitycb27his4cxkr","organizationId":"o-SANDBOX-s3rs92ybg5nuh4l","name":"","organizationName":"","permissions":[],"roles":["member"]},"sso":false}

export const getV3Token = (token: string, pid: string) => {
  const parsed = JSON.parse(token ?? "");
  return JSON.stringify({
    membershipId: parsed.user.id,
    organizationId: parsed.user.organizationId,
    principal: pid,
    sessionId: parsed.interactiveSessionId,
    enrollmentId: parsed.enrollmentId,
  } as $HubAuthSession);
};
export const $useAuth = (opts: { pid: string }) => {
  const legacy_auth = legacy_useAuthAuth(opts.pid);

  // HACK: the token will be soon opaque, so we should retrive the token from an updated tracking endpoint

  const token = useMemo(
    () => getV3Token(legacy_auth.state.token ?? "", opts.pid),
    [legacy_auth.state.token, opts.pid]
  );

  return {
    token,
    rootSessionId: legacy_auth.state.rootUserSessionId,
    fromInput: <T>(input: T) => withHubToken<T>(token ?? "", input),
  };
};
export const getImperativeHubV3Auth = (opts: { pid: string }) => {
  const legacy_auth = HubAuthStore;

  // HACK: the token will be soon opaque, so we should retrive the token from an updated tracking endpoint
  const token = getV3Token(HubAuthStore.state.token ?? "", opts.pid);

  return {
    token,
    rootSessionId: legacy_auth.state.rootUserSessionId,
  };
};
