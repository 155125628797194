export const bannerFixture = {
  title: "Welcome to your Hub",
  line1:
    "Get started with filters and search to find the right resources for you.",
  line2:
    "Check out pathways for courses and toolkits on how to maximize the value of your benefits.",
  image:
    "https://res.cloudinary.com/with-pillar-inc/image/upload/v1708450198/hub-banner-min_scg7vc.jpg",
};

export const titleFixture = "{{hub.organization.name}} Health Hub";
export const faviconFixture =
  "https://platform.withpillar.com/assets/favicon.ico";
